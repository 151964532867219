import { IconWrapper, IDerivedIconProps } from '@components/icons/IconWrapper'

export const OfflineIcon = (props: IDerivedIconProps) => (
    <IconWrapper {...props} viewBox="0 0 40 40">
        <path
            d="M10.8333 32.5C8.50217 32.5 6.53049 31.6939 4.91829 30.0817C3.30612 28.4696 2.50004 26.4979 2.50004 24.1667C2.50004 22.0812 3.17046 20.2693 4.51129 18.7308C5.8521 17.1924 7.50006 16.2779 9.45517 15.9873C9.50644 15.6689 9.60581 15.304 9.75325 14.8927C9.90069 14.4813 10.0642 14.1026 10.2437 13.7565L4.42954 7.94233C4.19879 7.71155 4.08074 7.4215 4.07537 7.07217C4.07004 6.72281 4.1881 6.4274 4.42954 6.18596C4.67099 5.94451 4.96372 5.82379 5.30775 5.82379C5.65175 5.82379 5.94447 5.94451 6.18592 6.18596L34.4231 34.4231C34.6539 34.6539 34.7762 34.9397 34.7901 35.2805C34.804 35.6213 34.6805 35.9221 34.4196 36.183C34.1805 36.4221 33.8932 36.5459 33.5578 36.5545C33.2223 36.5631 32.9253 36.4466 32.6667 36.2051L28.968 32.5L10.8333 32.5ZM10.8333 30H26.436L12.1539 15.7179C12.0022 16.0983 11.883 16.5128 11.7965 16.9615C11.7099 17.4103 11.6667 17.8675 11.6667 18.3334H10.8333C9.22222 18.3334 7.84722 18.9028 6.70833 20.0417C5.56944 21.1806 5 22.5556 5 24.1667C5 25.7778 5.56944 27.1528 6.70833 28.2917C7.84722 29.4306 9.22222 30 10.8333 30ZM35.423 30.6731L33.6154 28.9167C34.0662 28.5171 34.4097 28.062 34.6458 27.5515C34.8819 27.0409 35 26.4682 35 25.8334C35 24.6667 34.5972 23.6806 33.7917 22.875C32.9861 22.0695 32 21.6667 30.8333 21.6667H28.3333V18.3334C28.3333 16.0278 27.5208 14.0625 25.8958 12.4375C24.2708 10.8125 22.3056 10 20 10C19.25 10 18.5278 10.0903 17.8333 10.2709C17.1389 10.4514 16.4722 10.7362 15.8333 11.125L14.0257 9.30137C14.9979 8.67743 15.9632 8.22123 16.9215 7.93279C17.8798 7.64432 18.906 7.50008 20 7.50008C23.0178 7.50008 25.5778 8.55118 27.68 10.6534C29.7822 12.7555 30.8333 15.3155 30.8333 18.3334V19.1667H31.3461C33.1025 19.3035 34.5673 20.0097 35.7403 21.2853C36.9134 22.561 37.5 24.077 37.5 25.8334C37.5 26.7564 37.3264 27.6298 36.9791 28.4535C36.6319 29.2772 36.1132 30.0171 35.423 30.6731Z"
            fill="#000000"
        />
    </IconWrapper>
)
