import ENV from '@constants/env'

/**
 * Interface for Image from CDN
 */
export interface IImageCDN {
    /**
     * @param path the path of the image on Pimcore (https://api.cloud.image-line.com or a relative path)
     * or path of the image in PCL(https://cdn.labels.cloud.image-line.com).
     * @type string
     */
    path: string
    /**
     * @param format the format of the image, can be png, jpg webp, default is webp, and it is strongly recommended to use webp
     * @type string
     */
    format?: string
    /**
     * @param width the width of the image in pixels, if none or 0 is provided image will be returned in original width size
     * @type number
     */
    width?: number
    /**
     * @param height the height of the image in pixels, if none or 0 is provided image will be returned in original height size
     * @type number
     */
    height?: number
    /**
     * @param quality the quality of the image, default is 50, the higher the number the better the quality
     * @type number
     */
    quality?: number
}

/**
 * Function for getting an image from CDN.
 * @param params
 */
export const ImageCDN = (params: IImageCDN) => {
    if (!params.path || typeof params.path !== 'string') {
        return ''
    }

    if (!ENV.VITE_CDN_ENCODER_API_URL) {
        console.error('CDN URL is not defined')
        return ''
    }

    const imagePath = encodeURIComponent(params.path)

    const baseUrl = new URL(`${ENV.VITE_CDN_ENCODER_API_URL}/image/${imagePath}`)

    baseUrl.searchParams.append('f', String(params.format ? params.format : 'webp'))
    baseUrl.searchParams.append('w', String(params.width ? params.width : '0'))
    baseUrl.searchParams.append('h', String(params.height ? params.height : '0'))
    baseUrl.searchParams.append('q', String(params.quality ? params.quality : '50'))

    return baseUrl.href
}
