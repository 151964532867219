import { ChangeEvent, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'

import { CrossIcon } from '@components/icons/CrossIcon'
import { MagnifyingGlassIcon } from '@components/icons/MagnifyingGlassIcon'
import { COLORS } from '@styles/Colors'
import { AppStoreContext } from '@store/RootStore'

export const Search = () => {
    const appStore = useContext(AppStoreContext)
    const { feed: feedStore, scale } = appStore
    const [search, setSearch] = useState('')
    const searchDebounceRef = useRef<ReturnType<typeof setTimeout> | null>(null)

    const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value)
    }, [])

    const onClickClear = useCallback(() => {
        setSearch('')
    }, [])

    useEffect(() => {
        if (searchDebounceRef.current) {
            clearTimeout(searchDebounceRef.current)
        }

        searchDebounceRef.current = setTimeout(() => {
            feedStore.setSearchTerm(search)
        }, 150)
    }, [search])

    useEffect(() => {
        if (search === feedStore.searchTerm) return
        setSearch(feedStore.searchTerm)
    }, [feedStore.searchTerm])

    useEffect(() => {
        return () => {
            if (searchDebounceRef.current) {
                clearTimeout(searchDebounceRef.current)
            }
        }
    }, [])

    return (
        <div className="w-full rounded-4px bg-black flex items-center">
            <div>
                <MagnifyingGlassIcon
                    className="flp-icon w-11px h-11px mx-3"
                    style={{
                        '--icon-fill-color': COLORS['flp-grey'],
                        '--icon-fill-hover-color': COLORS['flp-grey'] as string,
                    }}
                />
            </div>
            <input type="text" id="search-bar" name="search-bar" className="flp-search" onChange={onChange} value={search} placeholder="Search" />
            {search.length > 0 ? (
                <div className="mx-2 cursor-pointer" onClick={onClickClear}>
                    <CrossIcon
                        className="flp-icon rotate-45"
                        style={{
                            '--icon-stroke-color': COLORS['flp-grey'],
                            '--icon-stroke-hover-color': COLORS['flp-grey'] as string,
                            width: `${14 * scale}px`,
                            height: `${14 * scale}px`,
                        }}
                    />
                </div>
            ) : (
                ''
            )}
        </div>
    )
}

export default observer(Search)
