import { Config } from '@constants/api'
import ENV from '@constants/env'
import { ApiClass } from '@src/classes/ApiClass'
import { AUTH_SERVER_URL } from '@store/AuthStore'

class ApiClient extends ApiClass {
    public content = {
        getAllCategories: async () => {
            const url = `${ENV.VITE_API_URL}${Config.endpoints.categories}`

            return this.get(url)
        },
        getAllTiers: async () => {
            const url = `${ENV.VITE_API_URL}${Config.endpoints.tiers}`
            return this.get(url)
        },
        getTags: async () => {
            const url = `${ENV.VITE_API_URL}${Config.endpoints.tags}`
            return this.get(url)
        },
        getCategoryTags: async () => {
            const url = `${ENV.VITE_API_URL}${Config.endpoints.categoryTags}`
            return this.get(url)
        },
        searchPlugins: async (body = {}) => {
            const url = `${ENV.VITE_API_URL}${Config.endpoints.search}`

            return this.get(url, true, body)
        },
        getLicenceCodeForPlugin: async (pluginId: string, labelId: string) => {
            const url = `${ENV.VITE_CREDIT_SERVER_URL}/api/v1${Config.endpoints.pluginLicenceCode}?productId=${pluginId}&labelId=${labelId}`

            return this.get(url, false, undefined, { credentials: 'include' })
        },
    }

    public licencing = {
        getLicenses: async () => {
            const url = `${ENV.VITE_CREDIT_SERVER_URL}/api/v1/plugin/machines`

            const options: Partial<RequestInit> = {
                credentials: 'include',
            }

            return this.get(url, false, {}, options)
        },
        removeLicense: async (licenseId: number) => {
            const url = `${ENV.VITE_CREDIT_SERVER_URL}/api/v1/plugin/license/${licenseId}`

            const options: Partial<RequestInit> = {
                credentials: 'include',
            }

            return this.fetch(url, {
                method: 'DELETE',
                ...options,
            })
        },
    }

    public session = {
        getJWT: async (machineID: string) => {
            const url = `${ENV.VITE_CREDIT_SERVER_URL}/api/v1/plugin/token`

            const options: Partial<RequestInit> = {
                credentials: 'include',
                headers: { 'x-machine-id': machineID },
            }

            return this.get(url, false, {}, options)
        },
        /**
         * Get account info
         * @param avoidCookieCheck -- see AuthStore.check() for more info
         */
        getAccountInfo: async (avoidCookieCheck: boolean = false) => {
            const url = `${ENV.VITE_CREDIT_SERVER_URL}/api/account/info`
            return this.get(url, false, undefined, { credentials: 'include' }, avoidCookieCheck)
        },
        revalidateSession: async () => {
            return this.get(`${AUTH_SERVER_URL}/auth/validate`, false, undefined, { credentials: 'include' })
        },
        refreshToken: async () => {
            return this.get(`${AUTH_SERVER_URL}/auth/refresh`, false, undefined, { credentials: 'include' })
        },
        logout: async () => {
            return this.post(`${ENV.VITE_AUTH_SERVER_URL}/auth/logout`, undefined, { credentials: 'include' })
        },
        getLabelConsents: async () => {
            return this.get(`${ENV.VITE_CREDIT_SERVER_URL}/api/v1/plugin/label-consent`, false, undefined, { credentials: 'include' })
        },
        setLabelConsents: async (labelId: string, isConsentAccepted = false) => {
            return this.post(`${ENV.VITE_CREDIT_SERVER_URL}/api/v1/plugin/label-consent`, { labelId, isConsentAccepted }, { credentials: 'include' })
        },
    }

    async getLatestVersionInfo() {
        const isSafari = navigator.userAgent.toLowerCase().indexOf('macintosh') > -1
        const url = `${ENV.VITE_CDN_APP_URL}${ENV.VITE_CDN_APP_DIR}${ENV.VITE_LATEST_VERSION_JSON}`

        return this.fetch(url, { ...(!isSafari ? { cache: 'no-store' } : {}), credentials: 'omit', mode: 'cors', referrerPolicy: 'strict-origin' })
    }
}

const ApiInstance = new ApiClient()
export default ApiInstance
