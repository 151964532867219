import { IconWrapper, IDerivedIconProps } from '@components/icons/IconWrapper'

export const UpdateIcon = (props: IDerivedIconProps) => (
    <IconWrapper {...props} viewBox="0 0 20 22">
        <path
            d="M15 20L18 17L17.3 16.3L15.5 18.1V14H14.5V18.1L12.7 16.3L12 17L15 20ZM9 2L3.075 5.425L9 8.85L14.925 5.425L9 2ZM0 13.975V6.025C0 5.65833 0.0875 5.325 0.2625 5.025C0.4375 4.725 0.683333 4.48333 1 4.3L8 0.275C8.16667 0.191667 8.32917 0.125 8.4875 0.075C8.64583 0.025 8.81667 0 9 0C9.18333 0 9.35833 0.025 9.525 0.075C9.69167 0.125 9.85 0.191667 10 0.275L17 4.3C17.3167 4.48333 17.5625 4.725 17.7375 5.025C17.9125 5.325 18 5.65833 18 6.025V10H16V7.1L8.975 11.15L2 7.1V13.95L8 17.425V19.725L1 15.7C0.683333 15.5167 0.4375 15.275 0.2625 14.975C0.0875 14.675 0 14.3417 0 13.975ZM15 22C13.6167 22 12.4375 21.5125 11.4625 20.5375C10.4875 19.5625 10 18.3833 10 17C10 15.6167 10.4875 14.4375 11.4625 13.4625C12.4375 12.4875 13.6167 12 15 12C16.3833 12 17.5625 12.4875 18.5375 13.4625C19.5125 14.4375 20 15.6167 20 17C20 18.3833 19.5125 19.5625 18.5375 20.5375C17.5625 21.5125 16.3833 22 15 22Z"
            fill="#000000"
        />
    </IconWrapper>
)
