import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import useKeys, { Keys } from './useKeys'
import { AppStoreContext } from '@store/RootStore'

type UseDropdownHookReturn = {
    ref: (node: null | HTMLElement) => void
    refElement: null | HTMLElement
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    toggleOpen: () => void
}

export default function useDropdown(): UseDropdownHookReturn {
    const appStore = useContext(AppStoreContext)
    const { modal: modalStore } = appStore
    const [isOpen, setIsOpen] = useState(false)
    const [refElement, setRefElement] = useState<null | HTMLElement>(null)
    const modalPreventsDismissal = useMemo(() => {
        return (modalStore.activeModal && !modalStore.allowDismiss) ?? false
    }, [modalStore.activeModal, modalStore.allowDismiss])

    const ref = useCallback((node: HTMLElement | null) => {
        if (!node) return
        setRefElement(node)
    }, [])

    useEffect(() => {
        const handleMouseDown = (event: any) => {
            if (!refElement || refElement?.contains(event.target)) return

            closeDropdown()
        }

        document.addEventListener('mousedown', handleMouseDown)
        return () => {
            return document.removeEventListener('mousedown', handleMouseDown)
        }
    }, [refElement, modalPreventsDismissal])

    const toggleOpen = useCallback(() => {
        const newOpenStatus = !isOpen

        setIsOpen(newOpenStatus)
    }, [isOpen])

    const closeDropdown = useCallback(() => {
        if (modalPreventsDismissal) return

        setIsOpen(false)
    }, [modalPreventsDismissal])

    useKeys(Keys.ESC, refElement, isOpen, closeDropdown)

    return {
        ref,
        refElement,
        isOpen,
        setIsOpen,
        toggleOpen,
    }
}
