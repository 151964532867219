import { useCallback, useMemo } from 'react'

import { observer } from 'mobx-react'
import { useAppStoreContext } from '@store/RootStore'
import ModalWrapper from '@components/modal/ModalWrapper'
import { IFLPlugin } from '@store/models/FLPlugin'
import { closeApp } from '@src/interop'

import ENV from '@constants/env'
import { getPlatform } from '@utils/misc'
import { formatGTMEvent, GTMEvent, IPluginPackageEvent } from '@utils/gtm-event'
import TagManager from 'react-gtm-module'

export const ModalConfirmClose = () => {
    const { modal: modalStore, feed: feedStore, gtmInitialized } = useAppStoreContext()
    const { pluginsInstalling } = feedStore

    const confirmClick = useCallback(() => {
        // Capture all canceled installs via gtm event
        pluginsInstalling.forEach((plugin: IFLPlugin) => {
            if (ENV.VITE_GOOGLE_TAG_MANAGER && gtmInitialized) {
                const gtmPluginInstallCanceledArgs = formatGTMEvent(GTMEvent.PLUGIN_INSTALL_CANCELED, {
                    pluginAppId: plugin.appId,
                    pluginName: plugin.name,
                    pluginVersion: plugin.platformPackage?.version,
                    pluginSize: plugin.platformPackage?.size,
                    platform: getPlatform(),
                } as Partial<IPluginPackageEvent>)

                TagManager.dataLayer({ dataLayer: gtmPluginInstallCanceledArgs.dataLayer })
            }
        })

        // Send interop to force close the app
        closeApp()
        modalStore.closeModal()
    }, [pluginsInstalling.length, gtmInitialized])

    const cancelClick = useCallback(() => {
        modalStore.closeModal()
    }, [])

    const installingList = useMemo(() => {
        return (
            <div className="text-center text-flp-text text-11px">
                The following products are currently installing:
                <ul id="dependents-list" className="list-disc pt-2">
                    {pluginsInstalling.map(({ name, appId }: IFLPlugin) => {
                        return (
                            <li key={appId} className="text-white">
                                {name}
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }, [pluginsInstalling])

    return (
        <ModalWrapper>
            <div className="text-center text-flp-text">
                <h1 className="flp-header text-flp-text">Close</h1>
                <div className="flp-title text-flp-text py-4">Are you sure you wish to close the FL Cloud Plugins App?</div>
                <div className="py-4">There are some plugin installs currently in progress</div>
            </div>
            {installingList}
            <div className="flex gap-10 pt-8 justify-center">
                <div
                    id="button-cancel-close"
                    className="flp-button group bg-flp-button-grey-background text-flp-button-grey-text border-flp-button-grey-background"
                    onClick={cancelClick}>
                    Cancel
                </div>
                <div
                    id="button-confirm-close"
                    className="flp-button group bg-flp-button-yellow-background border-flp-button-yellow-background-highlight text-flp-button-yellow-text hover:bg-flp-button-yellow-background-highlight hover:border-transparent"
                    onClick={confirmClick}>
                    Confirm &amp; close
                </div>
            </div>
        </ModalWrapper>
    )
}

export default observer(ModalConfirmClose)
