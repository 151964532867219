import { IconWrapper, IDerivedIconProps } from '@components/icons/IconWrapper'

export const AccountIcon = (props: IDerivedIconProps) => (
  <IconWrapper {...props} viewBox="0 0 13 14">
    <path
      d="M8.85859 7.70324C9.92476 8.17732 10.8336 8.94507 11.4785 9.91651C12.1234 10.888 12.4778 12.0228 12.5 13.1882H11.309C11.298 12.482 11.1331 11.7867 10.8258 11.1507C10.5184 10.5146 10.076 9.95304 9.52925 9.50508C8.6022 8.75135 7.42887 8.36651 6.23487 8.42456C5.04088 8.48261 3.91051 8.97944 3.06115 9.81954C2.16043 10.7164 1.64704 11.9298 1.63087 13.2H0.500507C0.485157 12.0303 0.818567 10.8824 1.45831 9.90245C2.09805 8.92251 3.01522 8.1548 4.09312 7.69695C3.48541 7.27691 2.99813 6.70556 2.6796 6.03953C2.36107 5.3735 2.22233 4.6359 2.27713 3.89983C2.30782 3.35019 2.45034 2.81256 2.69597 2.31976C2.94161 1.82695 3.28522 1.38931 3.70583 1.03353C4.52729 0.320595 5.59274 -0.0478848 6.67974 0.00499671C7.76675 0.0578782 8.79129 0.528027 9.53948 1.31733C9.95251 1.7641 10.2652 2.29387 10.4565 2.87113C10.6479 3.44839 10.7135 4.05983 10.6491 4.66448C10.5846 5.26914 10.3915 5.85309 10.0827 6.37716C9.77392 6.90123 9.35655 7.35334 8.85859 7.70324ZM6.4971 1.18525C5.89877 1.17968 5.31223 1.35145 4.81165 1.67883C4.31107 2.00621 3.91895 2.47449 3.68489 3.02446C3.45082 3.57443 3.38532 4.18139 3.49667 4.76854C3.60802 5.35569 3.89122 5.89667 4.31045 6.32306C4.72968 6.74945 5.26611 7.04209 5.85189 7.16398C6.43767 7.28587 7.04648 7.23151 7.60133 7.0078C8.15618 6.78409 8.63214 6.40108 8.969 5.90719C9.30587 5.4133 9.48851 4.83073 9.49383 4.23317C9.49538 3.43383 9.18189 2.666 8.62113 2.09565C8.06036 1.52531 7.29736 1.19826 6.4971 1.18525Z"
      fill="#000000"
    />
  </IconWrapper>
)
