import { useCallback, useContext } from 'react'
import { observer } from 'mobx-react'
import { AppStoreContext } from '@store/RootStore'
import { ITag } from '@store/models/Tag'

export const TagsFilter = () => {
    const appStore = useContext(AppStoreContext)
    const { feed } = appStore
    const { setActiveTag, availableTags, isTagSelected } = feed

    const onClick = useCallback((tagId: string) => {
        return () => {
            setActiveTag(isTagSelected(tagId) ? null : tagId)
        }
    }, [])

    return (
        <div className="flex flex-wrap pt-20px pb-0 gap-2 w-full">
            {availableTags.map(({ id, name }: ITag) => {
                return (
                    <div
                        key={id}
                        className={`flp-tag
                            ${isTagSelected(id) ? 'flp-tag-active hover:!flp-tag-active' : ''}
                        `}
                        onClick={onClick(id)}>
                        {name}
                    </div>
                )
            })}
        </div>
    )
}

export default observer(TagsFilter)
