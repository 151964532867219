import { observer } from 'mobx-react'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'

import { InstallFilter, Sort, SortingFilter } from '@constants/filters'
import { AppStoreContext } from '@store/RootStore'
import { TriangleIcon } from './icons/TriangleIcon'
import { COLORS } from '@styles/Colors'
import useKeys, { Keys } from '@src/hooks/useKeys'
import TagManager from 'react-gtm-module'
import { formatGTMEvent, GTMEvent, ISortingEvent } from '@utils/gtm-event'

import ENV from '@constants/env'

const SortingDropdown = () => {
    const appStore = useContext(AppStoreContext)
    const { feed: feedStore, gtmInitialized, scale } = appStore
    const { sortBy, setSortAndDirection } = feedStore
    const [isOpen, setOpen] = useState(false)
    const dropdownRef = useRef<null | HTMLDivElement>(null)
    const activeSorting = useMemo(() => {
        return SortingFilter.find(({ value }) => {
            return value === sortBy
        })
    }, [sortBy])

    const toggleDropdown = useCallback(() => {
        setOpen(!isOpen)
    }, [isOpen])

    const onClickSorting = useCallback(
        (sort: Sort) => {
            return () => {
                setSortAndDirection(sort)

                if (ENV.VITE_GOOGLE_TAG_MANAGER && gtmInitialized) {
                    const sorting = SortingFilter.find(({ value }) => {
                        return value === sort
                    })
                    const gtmOrderPluginsArgs = formatGTMEvent(GTMEvent.ORDER_PLUGINS_BY, {
                        sortBy: sorting?.name ?? sort,
                    } as Partial<ISortingEvent>)
                    TagManager.dataLayer({ dataLayer: gtmOrderPluginsArgs.dataLayer })
                }

                closeSortingDropdown()
            }
        },
        [isOpen],
    )

    const sortingFilters = useMemo(() => {
        if (feedStore.selectedInstallStatus === InstallFilter.AVAILABLE) {
            return SortingFilter.filter(filter => {
                return filter.value !== Sort.INSTALLED
            })
        } else {
            return SortingFilter
        }
    }, [feedStore.selectedInstallStatus])

    useEffect(() => {
        const handleMouseDown = (event: any) => {
            if (!dropdownRef || dropdownRef.current?.contains(event.target)) return

            setOpen(false)
        }

        document.addEventListener('mousedown', handleMouseDown)
        return () => {
            return document.removeEventListener('mousedown', handleMouseDown)
        }
    }, [dropdownRef])

    const closeSortingDropdown = useCallback(() => {
        setOpen(false)
    }, [])

    useKeys(Keys.ESC, dropdownRef.current, isOpen, closeSortingDropdown)

    return (
        <div ref={dropdownRef} className="absolute left-0 top-[50%] inline-block text-left bg-flp-primary-black w-full">
            <div className={`flex gap-10px rounded-tl-30px rounded-tr-3px leading-none ${isOpen ? 'bg-flp-primary-grey-medium/25' : ''}`}>
                <button
                    type="button"
                    className={`flex flex-1 items-center justify-between rounded-14px text-11px w-full bg-flp-button-grey-background text-flp-button-grey-text tracking-wider py-2 pr-3 pl-5 select-none outline-none duration-200 ${
                        isOpen ? 'rounded-b-none' : ''
                    }`}
                    onClick={toggleDropdown}>
                    {activeSorting?.name}
                    <div className="w-8px">
                        <TriangleIcon
                            className={`flp-icon duration-100 ${isOpen ? 'rotate-180 -mt-2px' : ''}`}
                            style={{
                                '--icon-fill-color': COLORS['flp-grey'],
                                '--icon-fill-hover-color': COLORS['flp-white'] as string,
                                width: `${8 * scale}px`,
                                height: `${8 * scale}px`,
                            }}
                        />
                    </div>
                </button>
            </div>

            {isOpen && (
                <div className="absolute z-10 py-2 origin-top-left rounded-[3px] left-0 bg-flp-dropdown-bg w-full rounded-b-[15px]">
                    {sortingFilters.map(({ name, value }) => {
                        return (
                            <div
                                key={value}
                                className={`flex text-11px w-full py-0.5 pr-3 pl-5 text-flp-grey tracking-wider cursor-pointer select-none hover:text-white duration-200 ${
                                    sortBy === value ? 'text-white' : 'text-flp-grey'
                                }`}
                                onClick={onClickSorting(value)}>
                                {name}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default observer(SortingDropdown)
