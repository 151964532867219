import { IconWrapper, IDerivedIconProps } from '@components/icons/IconWrapper'

export const EditDirectoryOffIcon = (props: IDerivedIconProps) => (
    <IconWrapper {...props} viewBox="0 0 20 20">
        <g>
            <path
                fill="#000000"
                d="M10,20C4.5,20,0,15.5,0,10S4.5,0,10,0s10,4.5,10,10S15.5,20,10,20z M10,1c-5,0-9,4-9,9s4,9,9,9s9-4,9-9
			S15,1,10,1z"
            />
            <path
                fill="#000000"
                d="M14.9,6.3c0-0.1-0.1-0.2-0.2-0.3L14,5.3c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.2-0.1-0.3-0.1
		c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.2l-7.4,7.4c-0.1,0.1-0.2,0.2-0.2,0.3C5.1,13.1,5,13.3,5,13.4v1c0,0.1,0.1,0.3,0.2,0.4
		C5.3,14.9,5.4,15,5.6,15h1c0.1,0,0.3,0,0.4-0.1c0.1-0.1,0.2-0.1,0.3-0.2l7.4-7.4c0.1-0.1,0.2-0.2,0.2-0.3c0-0.1,0.1-0.2,0.1-0.3
		C15,6.5,14.9,6.4,14.9,6.3z M6.7,14.1H5.9v-0.7l6-6l0.7,0.7L6.7,14.1z"
            />
        </g>
    </IconWrapper>
)
