import { IconWrapper, IDerivedIconProps } from '@components/icons/IconWrapper'

export const ChevronIcon = (props: IDerivedIconProps) => (
    <IconWrapper {...props} viewBox="0 0 10 20">
        <path
            d="M2.16914 9.99999L9.70184 17.5327C9.90056 17.7314 9.99736 17.9679 9.99224 18.2423C9.98711 18.5167 9.88518 18.7532 9.68646 18.9519C9.48775 19.1506 9.25121 19.25 8.97686 19.25C8.70251 19.25 8.46598 19.1506 8.26726 18.9519L0.611488 11.2807C0.430721 11.1 0.296746 10.8974 0.209563 10.6731C0.122397 10.4487 0.0788137 10.2243 0.0788137 9.99999C0.0788137 9.77564 0.122397 9.55129 0.209563 9.32692C0.296746 9.10257 0.43072 8.90001 0.611487 8.71924L8.28264 1.04809C8.48135 0.849377 8.71533 0.752575 8.98456 0.757692C9.25378 0.762825 9.48775 0.864752 9.68646 1.06347C9.88518 1.26219 9.98454 1.49872 9.98454 1.77307C9.98454 2.04742 9.88518 2.28395 9.68646 2.48267L2.16914 9.99999Z"
            fill="#000000"
        />
    </IconWrapper>
)
