import { useCallback, useContext } from 'react'
import { observer } from 'mobx-react'

import { AppStoreContext } from '@store/RootStore'
import InfiniteScroller from '@components/feed/InfiniteScroller'
import NoResults from '@components/feed/NoResults'

export const Feed = () => {
    const appStore = useContext(AppStoreContext)
    const { feed: feedStore, scale } = appStore
    // TODO add types
    const { feed, isLoading, searchTerm, fetchPlugins, setSearchTerm } = feedStore as any

    const resetSearch = useCallback(() => {
        setSearchTerm('')
    }, [])

    // TODO: Tidy up the loading and no matching plugins screens
    if (isLoading) {
        return <div className="flex items-center justify-center grow">Loading</div>
    }

    if (feed.length === 0) {
        return <NoResults searchTerm={searchTerm} resetSearch={resetSearch} scale={scale} />
    }

    return <InfiniteScroller items={feed} isLoading={isLoading} fetchItems={fetchPlugins} scale={scale} />
}

export default observer(Feed)
