import { Platform } from '@constants/types'
import { ITier } from '@store/models/Tier'

/**
 * Return an enum value for the matching operating system based on the userAgent
 * @function
 */
export const getPlatform = (): Platform => {
    if (navigator.userAgent.includes('Windows')) {
        return Platform.WIN
    }
    if (navigator.userAgent.includes('Mac OS')) {
        return Platform.MAC
    }

    return Platform.UNKNOWN
}

export const sortTiersByOrder = (arrayToSort: ITier[], orderArray: number[]) => {
    const orderMap = new Map()

    orderArray.forEach((value, index) => {
        orderMap.set(value, index)
    })

    const customSort = (a: ITier, b: ITier) => {
        const aIndex = orderMap.get(a.tierId)
        const bIndex = orderMap.get(b.tierId)

        if (aIndex !== undefined && bIndex !== undefined) {
            return aIndex - bIndex
        } else if (aIndex !== undefined) {
            return -1
        } else if (bIndex !== undefined) {
            return 1
        } else {
            return 0
        }
    }

    return arrayToSort.slice().sort(customSort)
}

/**
 * Wait for a specified amount of milliseconds.
 *
 * @param ms  The amount of time to wait in milliseconds
 * @returns
 */
export const waitFor = async (ms: number): Promise<void> => {
    return new Promise(resolve => {
        return setTimeout(resolve, ms)
    })
}

export const meetsMinimumVersion = (minimumVersion: number, currentVersion: number) => {
    return minimumVersion ? currentVersion >= minimumVersion : false
}

export const splitSemanticVersion = (version: string) => {
    const split = version.split('.', 3).map(value => {
        return Number(value)
    })
    const padding = 3 - split.length

    if (padding > 0) {
        for (let i = 1; i <= padding; i++) {
            split.push(0)
        }
    }
    return split
}

export const isVersionOutdated = (version1: string, version2: string) => {
    const v1 = splitSemanticVersion(version1)
    const v2 = splitSemanticVersion(version2)

    for (let i = 0; i < 3; i++) {
        const version1Segment = v1[i] ?? 0
        const version2Segment = v2[i] ?? 0

        // if i > 0, then also check against the major number

        const segmentOutdated = version1Segment < version2Segment

        if (i === 0 && segmentOutdated) return true
        if (i === 1 && segmentOutdated && v1[0] === v2[0]) return true
        if (i === 2 && segmentOutdated && v1[0] === v2[0] && v1[1] === v2[1]) return true
    }

    return false
}

export const calculateAverageSpeed = (dataSizeInBytes: number, startDate: Date, endDate: Date) => {
    const dataSizeInMB = dataSizeInBytes / (1024 * 1024)
    const durationInSeconds = (endDate.getTime() - startDate.getTime()) / 1000
    const averageSpeedInMBps = dataSizeInMB / durationInSeconds
    return averageSpeedInMBps
}

export const copyToClipboard = (text: string, callback: () => void) => {
    const type = 'text/plain'
    const blob = new Blob([text], { type })
    const data = [new ClipboardItem({ [type]: blob })]
    navigator.clipboard.write(data).then(() => {
        callback()
    })
}

export const clamp = (value: number, min: number, max: number) => {
    return Math.max(min, Math.min(max, value))
}
