import { useCallback } from 'react'

import { observer } from 'mobx-react'
import { useAppStoreContext } from '@store/RootStore'
import ModalWrapper from '@components/modal/ModalWrapper'

import softwareLicences from '../../../software-licences.json'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { openExternalUrl } from '@src/interop'

type ISoftwareDependency = {
    name: string
    url: string
}

export const ModalCreditsAndAcknowledgements = () => {
    const { modal: modalStore } = useAppStoreContext()

    const closeClick = useCallback(() => {
        modalStore.closeModal()
    }, [])

    const openURLOnClick = useCallback((url: string) => {
        return () => {
            openExternalUrl(url, '')
        }
    }, [])

    return (
        <ModalWrapper>
            <div className="flex flex-col h-300px">
                <div className="text-center text-flp-text mb-2">
                    <h1 className="flp-header text-flp-text text-white">FL Cloud</h1>
                    <h4 className="mb-2 text-flp-text text-10px">Copyright Image Line NV {new Date().getFullYear()}</h4>
                    <div className="flp-title text-flp-text py-2">Credits and acknowledgements</div>
                </div>

                <OverlayScrollbarsComponent element="section" className="flp-custom-scrollbar text-10px grow flex-1" defer>
                    {(softwareLicences ?? []).map((licence: ISoftwareDependency) => {
                        return (
                            <div key={licence.name} className="hover:text-white cursor-pointer underline" onClick={openURLOnClick(licence.url)}>
                                {licence.name}
                            </div>
                        )
                    })}
                </OverlayScrollbarsComponent>
            </div>

            <div className="flex gap-10 pt-8 justify-center">
                <div
                    id="button-cancel-close"
                    className="flp-button group bg-flp-button-grey-background text-flp-button-grey-text border-flp-button-grey-background"
                    onClick={closeClick}>
                    Close
                </div>
            </div>
        </ModalWrapper>
    )
}

export default observer(ModalCreditsAndAcknowledgements)
