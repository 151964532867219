import { useCallback, useContext, useEffect, useMemo, useState, useRef } from 'react'
import { observer } from 'mobx-react'

import { COLORS } from '@styles/Colors'
import { AppStoreContext } from '@store/RootStore'
import { UpgradeIcon } from '@components/icons/UpgradeIcon'
import { TriangleIcon } from '@components/icons/TriangleIcon'
import { ITier, Tiers } from '@store/models/Tier'
import useKeys, { Keys } from '@src/hooks/useKeys'
import { openExternalUrl } from '@src/interop'
import { Config } from '@constants/url'

export const TiersButtons = () => {
    const appStore = useContext(AppStoreContext)
    const { feed: feedStore, auth: authStore, scale } = appStore
    const { selectedTier, setActiveTier, resetSelectedTagIfDoesNotExist } = feedStore
    const dropdownRef = useRef<null | HTMLDivElement>(null)
    const [isOpen, setOpen] = useState(false)
    const accountUpdateTimeoutRef = useRef<null | number>(null)

    const toggleDropdown = useCallback(() => {
        if (!accountUpdateTimeoutRef.current) {
            accountUpdateTimeoutRef.current = window.setTimeout(() => {
                accountUpdateTimeoutRef.current = null
            }, 60000)

            authStore.updateAccountInfo()
        }

        setOpen(!isOpen)
    }, [isOpen])

    const onClickTier = useCallback((tierId: string) => {
        return () => {
            setActiveTier(tierId)
            resetSelectedTagIfDoesNotExist()
            setOpen(false)
        }
    }, [])

    const tiers = useMemo(() => {
        return (
            authStore.user?.visibleTiersForState
                // Filter by tiers that have plugins assigned to them
                .filter((tier: ITier) => {
                    return tier.hasPlugins
                })
                .map((tier: ITier) => {
                    // Convoluted, but we need to know if this tier is the same as the users and currently there is no mapping between them
                    const isUsersTier =
                        (tier.tierId === Tiers.PRO && authStore.user?.isPro) ||
                        (tier.tierId === Tiers.PLUS && authStore.user?.isPlus) ||
                        (tier.tierId === Tiers.FREE && authStore.user?.isFree)
                    const isSelected = selectedTier === tier.id

                    return {
                        className: `flp-tier-button 
                        ${tier.isFree && 'bg-flp-button-free-bg text-flp-button-free-text'}
                        ${tier.isPlus && 'bg-flp-button-premium-bg text-flp-button-premium-text'}
                        ${tier.isPro && 'bg-flp-button-pro-bg text-flp-button-pro-text'}
                        
                        ${isSelected && tier.isFree && 'bg-flp-button-free-bg-highlight before:bg-flp-button-free-text'}
                        ${isSelected && tier.isPlus && 'bg-flp-button-premium-bg-highlight before:bg-flp-button-premium-text'}
                        ${isSelected && tier.isPro && 'bg-flp-button-pro-bg-highlight before:bg-flp-button-pro-text'}

                        ${isSelected && tier.isFree && 'bg-flp-button-free-bg-highlight before:bg-flp-button-free-text'}
                        ${isSelected && tier.isPlus && 'bg-flp-button-premium-bg-highlight before:bg-flp-button-premium-text'}
                        ${isSelected && tier.isPro && 'bg-flp-button-pro-bg-highlight before:bg-flp-button-pro-text'}
                    `,
                        iconColor: `
                        ${tier.isPlus ? COLORS['flp-button-premium-text'] : ''}
                        ${tier.isPro ? COLORS['flp-button-pro-text'] : ''}
                    `,
                        isUsersTier,
                        ...tier,
                    }
                })
        )
    }, [authStore.user?.tierType, authStore.user?.state, authStore.user?.visibleTiersForState, selectedTier, feedStore.pluginsList.length])

    useEffect(() => {
        const handleMouseDown = (event: any) => {
            if (!dropdownRef || dropdownRef.current?.contains(event.target)) return

            setOpen(false)
        }

        document.addEventListener('mousedown', handleMouseDown)
        return () => {
            return document.removeEventListener('mousedown', handleMouseDown)
        }
    }, [dropdownRef])

    const closeSortingDropdown = useCallback(() => {
        setOpen(false)
    }, [])

    const onUpgradeClick = useCallback(async () => {
        await authStore.updateAccountInfo()

        if (authStore.user?.isPro) return

        openExternalUrl(
            Config.externalUrls.flCloudAccountUpgrade,
            authStore.user?.isFree || authStore.user?.isTrial ? 'Subscribe to FL Cloud' : 'Upgrade your account tier',
        )
    }, [authStore.user?.isFree, authStore.user?.isTrial])

    useKeys(Keys.ESC, dropdownRef.current, isOpen, closeSortingDropdown)

    if (!selectedTier) return

    const selectedTierObj = tiers.find(({ id }: any) => {
        return selectedTier === id
    })

    if (!selectedTierObj) return

    if (authStore.user?.isPro) {
        return (
            <div className="flex items-center rounded-14px text-11px leading-none w-full tracking-wider py-2 pr-3 pl-5 select-none outline-none bg-flp-button-pro-bg text-flp-button-pro-text">
                {selectedTierObj?.name} {authStore.user?.isTrial && <span className="text-10px pt-1px pl-4px">(Trial)</span>}
            </div>
        )
    }

    return (
        <>
            <div ref={dropdownRef} className="text-left bg-flp-primary-black w-full relative">
                <div className="relative z-20 flex gap-10px rounded-t-3px leading-none">
                    <button
                        type="button"
                        className={`flex items-center justify-between rounded-14px text-11px w-full tracking-wider py-2 pr-3 pl-5 select-none outline-none duration-200
                            ${isOpen ? 'rounded-b-none' : ''}
                            ${selectedTierObj.tierId === Tiers.PRO && 'bg-flp-button-pro-bg text-flp-button-pro-text'}
                            ${selectedTierObj.tierId === Tiers.PLUS && 'bg-flp-button-plus-bg text-flp-button-plus-text'}
                            ${selectedTierObj.tierId === Tiers.FREE && 'bg-flp-button-free-bg text-flp-button-free-text'}
                        `}
                        onClick={toggleDropdown}>
                        {selectedTierObj?.name}
                        {!selectedTierObj?.isUsersTier && (
                            <span className="inline-block text-8px tracking-wide mt-0.5 -ml-1">(Upgrade available)</span>
                        )}
                        <div className="w-8px">
                            <TriangleIcon
                                className={`flp-icon duration-100 ${isOpen ? 'rotate-180 -mt-2px' : ''}`}
                                style={{
                                    width: `${8 * scale}px`,
                                    height: `${8 * scale}px`,
                                    ...(selectedTierObj.tierId === Tiers.PRO
                                        ? {
                                              '--icon-fill-color': COLORS['flp-button-pro-text'],
                                          }
                                        : {}),
                                    ...(selectedTierObj.tierId === Tiers.PLUS
                                        ? {
                                              '--icon-fill-color': COLORS['flp-button-premium-text'],
                                          }
                                        : {}),
                                    ...(selectedTierObj.tierId === Tiers.FREE
                                        ? {
                                              '--icon-fill-color': COLORS['flp-button-free-text'],
                                          }
                                        : {}),
                                }}
                            />
                        </div>
                    </button>
                </div>

                {isOpen && (
                    <div className="absolute z-10 origin-top-left rounded-3px left-0 bg-flp-black w-full rounded-b-15px pb-1.5 pt-22px -mt-4">
                        {tiers.map(({ id, name, isUsersTier }: any) => {
                            return (
                                <button
                                    key={id}
                                    className="flex justify-between text-11px w-full py-0.5 pr-2 pl-5 text-flp-grey tracking-wider cursor-pointer select-none hover:text-white duration-200"
                                    onClick={onClickTier(id)}>
                                    {name} {isUsersTier && '(Your tier)'}
                                </button>
                            )
                        })}

                        <button
                            className="flex items-center gap-4 text-11px w-full pt-1.5 pr-2 pl-5 mt-0.5 text-flp-grey tracking-wider cursor-pointer select-none hover:text-white duration-200 border-t border-t-flp-grey/30"
                            onClick={onUpgradeClick}>
                            Upgrade
                            <UpgradeIcon
                                className="flp-icon -ml-6px"
                                style={{
                                    '--icon-fill-color': COLORS['flp-grey'] as string,
                                    '--icon-fill-hover-color': COLORS['flp-grey'] as string,
                                    width: `${10 * scale}px`,
                                    height: `${14 * scale}px`,
                                }}
                            />
                        </button>
                    </div>
                )}
            </div>
        </>
    )
}

export default observer(TiersButtons)
