import { IconWrapper, IDerivedIconProps } from '@components/icons/IconWrapper'

export const AlertIcon = (props: IDerivedIconProps) => (
    <IconWrapper {...props} viewBox="0 0 20 20">
        <path
            d="M0.865234 17.5L10.9998 6.10352e-05L21.1343 17.5H0.865234ZM3.44978 16H18.5498L10.9998 2.99999L3.44978 16ZM10.9998 14.8077C11.2286 14.8077 11.4205 14.7303 11.5753 14.5755C11.7301 14.4207 11.8075 14.2288 11.8075 14C11.8075 13.7711 11.7301 13.5793 11.5753 13.4245C11.4205 13.2697 11.2286 13.1923 10.9998 13.1923C10.7709 13.1923 10.5791 13.2697 10.4243 13.4245C10.2695 13.5793 10.1921 13.7711 10.1921 14C10.1921 14.2288 10.2695 14.4207 10.4243 14.5755C10.5791 14.7303 10.7709 14.8077 10.9998 14.8077ZM10.2498 12.1923H11.7498V7.19231H10.2498V12.1923Z"
            fill="#000000"
        />
    </IconWrapper>
)
