import { useCallback, useMemo } from 'react'

import { observer } from 'mobx-react'
import { useAppStoreContext } from '@store/RootStore'
import ModalWrapper from '@components/modal/ModalWrapper'

export const ModalConfirmUninstall = () => {
    const { modal: modalStore, feed: feedStore } = useAppStoreContext()
    const { data } = modalStore

    const plugin = useMemo(() => {
        return feedStore.plugins.get(data.pluginAppId)
    }, [data.pluginAppId])

    if (!plugin) return

    const confirmClick = useCallback(() => {
        if (!plugin) return

        plugin?.uninstallPlugin()
        modalStore.closeModal()
    }, [plugin])

    const cancelClick = useCallback(() => {
        modalStore.closeModal()
    }, [])

    const dependentsList = useMemo(() => {
        return plugin?.dependents.length > 0 ? (
            <div className="text-center text-flp-text text-11px">
                The following products are installed and dependent on this plugin:
                <ul id="dependents-list" className="list-disc pt-2">
                    {plugin?.dependents.map(({ name, appId }: any) => {
                        return (
                            <li key={appId} className="text-white">
                                {name}
                            </li>
                        )
                    })}
                </ul>
            </div>
        ) : (
            <></>
        )
    }, [plugin])

    return (
        <ModalWrapper>
            <div className="text-center text-flp-text">
                <h1 className="flp-header text-flp-text">Uninstall</h1>
                <div className="flp-title text-flp-text py-4">
                    Are you sure you want to uninstall <span className="text-white">{plugin?.name}</span>?
                </div>
                <div className="py-4">Uninstalling may cause problems with existing projects and dependent products may not work correctly</div>
            </div>
            {dependentsList}
            <div className="flex gap-10 pt-8 justify-center">
                <div
                    id="button-cancel-uninstall"
                    className="flp-button group bg-flp-button-grey-background text-flp-button-grey-text border-flp-button-grey-background"
                    onClick={cancelClick}>
                    Cancel
                </div>
                <div
                    id="button-confirm-uninstall"
                    className="flp-button group bg-flp-button-yellow-background border-flp-button-yellow-background-highlight text-flp-button-yellow-text hover:bg-flp-button-yellow-background-highlight hover:border-transparent"
                    onClick={confirmClick}>
                    Uninstall
                </div>
            </div>
        </ModalWrapper>
    )
}

export default observer(ModalConfirmUninstall)
