import { useEffect } from 'react'

export enum Keys {
    ESC = 'Escape',
}

type UseKeysHookReturn = {}

export default function useKeys(key: Keys, ref: HTMLElement | null, isEnabled = false, callback: () => void): UseKeysHookReturn {
    useEffect(() => {
        if (!isEnabled) return

        const handleKeyDown = (event: any) => {
            event.preventDefault()
            event.stopPropagation()

            if (event.key === key) {
                callback()
            }
        }

        document.addEventListener('keydown', handleKeyDown, false)

        return () => {
            return document.removeEventListener('keydown', handleKeyDown)
        }
    }, [ref, isEnabled])

    return {}
}
