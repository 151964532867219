import { flow, Instance, types } from 'mobx-state-tree'
import { createContext, useContext } from 'react'

import FeedStore from '@store/FeedStore'
import LicenseStore from '@store/LicenseStore'
import AuthStore, { LoginState } from '@store/AuthStore'
import NotificationsStore from '@store/NotificationsStore'
import { ModalType, NotificationType, Platform } from '@constants/types'
import { cancelInstall, getAppVersion, installUpdate, sendUIReadyMessage } from '@src/interop'
import { getPlatform, isVersionOutdated } from '@utils/misc'
import { IEngineResponse, responseHandler } from '@utils/engine'
import ModalStore from '@store/ModalStore'
import ApiInstance from '@utils/api'

import ENV from '@constants/env'
import { reaction } from 'mobx'
import { InstallState } from '@store/models/FLPlugin'
import { ITier } from '@store/models/Tier'
import { LS_APP_LAST_OPENED_DATE, LS_ZOOM_SETTING } from '@constants/storage'
import { BASE_FONT_SIZE } from '@constants/dimensions'

export type IRootStore = Instance<typeof RootStore>

export enum ConnectionStatus {
    ONLINE,
    OFFLINE,
}

export enum LoadingState {
    BOOT,
    LOADED,
    CHECKING_VERSION,
    CHECKING_AUTH,
    FETCHING_PLUGINS,
}

export const RootStore = types
    .model('RootStore', {
        feed: FeedStore,
        auth: AuthStore,
        notifications: NotificationsStore,
        license: LicenseStore,
        modal: ModalStore,
        version: types.maybeNull(types.string),
        latestVersion: types.maybeNull(types.string),
        macInstaller: types.maybeNull(types.string),
        winInstaller: types.maybeNull(types.string),
        macInstallerSize: types.maybeNull(types.number),
        winInstallerSize: types.maybeNull(types.number),
        installerDownloadedSize: types.optional(types.number, 0),
        isAppUpdating: types.optional(types.boolean, false),
        loadingState: LoadingState.BOOT,
        appError: types.maybeNull(types.string),
        connectionStatus: ConnectionStatus.ONLINE,
        gtmInitialized: types.optional(types.boolean, false),
        soundBanksDirectory: types.maybeNull(types.string),
        scale: types.number,
    })
    .views((self: any) => {
        return {
            get isWindows() {
                return getPlatform() === Platform.WIN
            },
            get isMac() {
                return getPlatform() === Platform.MAC
            },
            get isAppLoaded() {
                return self.loadingState === LoadingState.LOADED
            },
            get appDownloadLatestInstallerUrl() {
                const appInstallerPath = self.isMac ? self.macInstaller : self.winInstaller
                return `${ENV.VITE_CDN_APP_URL}${ENV.VITE_CDN_APP_DIR}${appInstallerPath}`
            },
        }
    })
    .volatile(() => {
        return {
            fetcher: null,
        }
    })
    .actions((self: any) => {
        return {
            setActiveTierForUser() {
                if (!self.auth.user || self.feed.allTiers.length === 0) return

                // Set the selected tier based on the user's tier
                const tier = self.feed.allTiers.find((tier: ITier) => {
                    if (self.auth.user.isPlus) return tier.isPlus
                    if (self.auth.user.isPro) return tier.isPro
                    if (self.auth.user.isFree) return tier.isFree
                })

                if (!tier) {
                    console.warn('There was an error setting the selected tier for the user')
                    return
                }

                self.feed.setActiveTier(tier.id)
            },
            setAppVersion: (version: string) => {
                self.version = version
            },
            setInstallerDownloadedSize: (progress: number) => {
                self.installerDownloadedSize = progress
            },
            setLatestVersion(version: string) {
                self.latestVersion = version
            },
            setMacInstaller(path: string) {
                self.macInstaller = path
            },
            setWinInstaller(path: string) {
                self.winInstaller = path
            },
            setMacInstallerSize(size: number) {
                self.macInstallerSize = size
            },
            setWinInstallerSize(size: number) {
                self.winInstallerSize = size
            },
            setIsAppUpdating(isUpdating: boolean) {
                self.isAppUpdating = isUpdating
            },
            setLoadingState(state: LoadingState) {
                self.loadingState = state
            },
            setAppError(message: string | null) {
                self.appError = message
            },
            setSoundBanksDirectory(path: string | null) {
                self.soundBanksDirectory = path
            },
            setScale: (value: number) => {
                self.scale = value
            },
            fetchLatestVersion: flow(function* () {
                try {
                    const fetchLatestVersionInfo = yield ApiInstance.getLatestVersionInfo()

                    if (!fetchLatestVersionInfo) {
                        console.error('Error retrieving latest version info.')
                    }

                    const data = fetchLatestVersionInfo

                    if (!data?.latest || !data.mac || !data.win) return

                    self.setLatestVersion(data.latest)
                    self.setMacInstaller(data.mac)
                    self.setMacInstallerSize(parseInt(data.macSize) ?? 0)
                    self.setWinInstaller(data.win)
                    self.setWinInstallerSize(parseInt(data.winSize) ?? 0)
                } catch (error: any) {
                    console.error('Error checking for app update:', error.message)
                    return
                }
            }),
            checkForUpdate: (silent = false) => {
                if (!self.version) return

                if (!isVersionOutdated(self.version, self.latestVersion)) {
                    if (silent) return
                    self.notifications.addNotification(NotificationType.SUCCESS, 'The app is up to date')
                    return
                }

                const forceUpdate = isVersionOutdated(self.version, ENV.VITE_MANDATORY_UPDATE_VERSION)

                // Show update prompt modal
                self.modal.showModal(ModalType.UPDATE_PROMPT, { forceUpdate }, false)
            },
            updateApp: flow(function* () {
                yield installUpdate('app-update', self.appDownloadLatestInstallerUrl)
            }),
            cancelUpdateApp: flow(function* () {
                yield cancelInstall('app-update')
            }),
            setGTMInitialized: (isInitialized: boolean) => {
                self.gtmInitialized = isInitialized
            },
            setConnectionStatus: (status: ConnectionStatus) => {
                self.connectionStatus = status
            },
            setFetcher: (fetcher: any) => {
                self.fetcher = fetcher

                ApiInstance.setFetcher(self.fetcher)
            },
            versionCheckStep: async () => {
                self.setLoadingState(LoadingState.CHECKING_VERSION)

                const appVersion = await getAppVersion()
                self.setAppVersion(appVersion)

                await self.fetchLatestVersion()
            },
            authenticateCheckStep: async () => {
                self.setLoadingState(LoadingState.CHECKING_AUTH)

                const authSuccess = await self.auth.check()

                if (!authSuccess) {
                    if (self.auth.state !== LoginState.errored) {
                        self.auth.login()
                    }
                } else {
                    await self.license.updateLicenses()
                    self.license.updateJWT()
                }
            },
            fetchPluginsStep: async () => {
                self.setLoadingState(LoadingState.FETCHING_PLUGINS)

                await self.feed.fetchPlugins()
            },
            loadApp: async () => {
                const performAuthCheck = ![LoginState.loggedIn, LoginState.checking].includes(self.auth.state as LoginState)

                await self.versionCheckStep()

                console.warn('Version check step complete')

                // We need categories and tiers for the app to function, so let's yield the requests
                await self.feed.getCategoriesTiersAndTags()

                console.warn('Fetch categories and tiers step complete')

                if (performAuthCheck) {
                    await self.authenticateCheckStep()
                }

                await self.fetchPluginsStep()

                console.warn('Fetch plugins step complete')

                self.setLoadingState(LoadingState.LOADED)

                await Promise.resolve()
            },
            updateBaseFontSizeStyle: (scale: number = 1) => {
                const r = document.querySelector(':root') as HTMLElement
                r?.style?.setProperty('--base-font-size', `${scale * BASE_FONT_SIZE}px`)
            },
            afterCreate: flow(function* () {
                yield sendUIReadyMessage()
                // this is here, because it has to have access to the feed store
                // @TODO: move this to a better place
                ;(globalThis as any).__engineCallback__ = function (task: string, data: IEngineResponse) {
                    responseHandler(task, data)
                }

                // Set the last opened date for the app
                localStorage.setItem(LS_APP_LAST_OPENED_DATE, `${new Date()}`)

                // Setup reaction for when the scale/zoom setting changes
                reaction(
                    () => {
                        return self.scale
                    },
                    (scale: number) => {
                        self.updateBaseFontSizeStyle(scale)
                    },
                )

                const localZoomSetting = localStorage.getItem(LS_ZOOM_SETTING)

                if (localZoomSetting) {
                    self.setScale(parseFloat(localZoomSetting))
                    self.updateBaseFontSizeStyle(localZoomSetting)
                }

                // Catch any time the user's state or tierType changes
                reaction(
                    () => {
                        return [self.auth.user?.state, self.auth.user?.tierType]
                    },
                    ([newState, newTierType], [oldState, oldTierType]) => {
                        // Set the active tier to match the user's tier
                        if (newState === oldState && newTierType === oldTierType) return

                        self.setActiveTierForUser()
                    },
                )
            }),
        }
    })

const rootStore = RootStore.create({
    // TODO: Look into this typing error
    // @ts-ignore
    feed: FeedStore.create({
        searchTerm: '',
    }),
    auth: AuthStore.create(),
    notifications: NotificationsStore.create({
        notifications: [],
    }),
    license: LicenseStore.create(),
    modal: ModalStore.create(),
    scale: 1,
})

// Always verify the status of installed plugins when the soundbank directory changes
reaction(
    () => {
        return rootStore.soundBanksDirectory
    },
    () => {
        const installedAppIds = Object.keys(rootStore.feed.installedPlugins) ?? []

        if (installedAppIds.length === 0) return

        // Check the install status for each installed plugins in case the soundbank directory broke something
        installedAppIds.forEach((pluginId: any) => {
            const plugin = rootStore.feed.plugins.get(pluginId)

            if (!plugin) {
                console.error(`Could not verify status of installed plugin '${pluginId}' when soundbank directory changed`)
                return
            }

            if (plugin.installStatus === InstallState.REMOVING) return

            plugin.checkInstallStatus()
        })
    },
)

export const mockRootStore = (properties = {}) => {
    return RootStore.create({
        feed: {
            searchTerm: '',
        },
        auth: {},
        notifications: {
            notifications: [],
        },
        license: {},
        modal: {
            activeModal: null,
        },
        scale: 1,
        ...properties,
    })
}

export const AppStoreContext = createContext(rootStore)
export const useAppStoreContext = () => {
    return useContext(AppStoreContext)
}

export function getStore() {
    return rootStore
}

export default rootStore
