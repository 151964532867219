export enum InstallFilter {
    AVAILABLE = 'available',
    INSTALLED = 'installed',
    UPDATES = 'updates',
    ACTIVE_QUEUE = 'active queue',
}

export enum Sort {
    // TODO: What is Most Popular needs to be defined
    // POPULAR = 'popular',
    DEVELOPER = 'developer',
    RECENT = 'recent',
    ALPHABETICAL = 'alphabetical',
    INSTALLED = 'installed',
}

export enum SortDirection {
    ASC = 'asc',
    DESC = 'desc',
}

export const SortingFilter = [
    // {
    //     name: 'Most popular',
    //     value: Sort.POPULAR,
    // },
    {
        name: 'Recently added',
        value: Sort.RECENT,
    },
    {
        name: 'Alphabetical',
        value: Sort.ALPHABETICAL,
    },
    {
        name: 'By Developer',
        value: Sort.DEVELOPER,
    },
    {
        name: 'Last installed',
        value: Sort.INSTALLED,
    },
]

export const InstallFilters = Object.values(InstallFilter)
