import { IconWrapper, IDerivedIconProps } from '@components/icons/IconWrapper'

export const ExternalLinkIcon = (props: IDerivedIconProps) => (
  <IconWrapper {...props} viewBox="0 0 14 15">
    <path
      d="M1.55556 14.5C1.12778 14.5 0.761574 14.3477 0.456944 14.0431C0.152315 13.7384 0 13.3722 0 12.9444V2.05556C0 1.62778 0.152315 1.26157 0.456944 0.956944C0.761574 0.652315 1.12778 0.5 1.55556 0.5H7V2.05556H1.55556V12.9444H12.4444V7.5H14V12.9444C14 13.3722 13.8477 13.7384 13.5431 14.0431C13.2384 14.3477 12.8722 14.5 12.4444 14.5H1.55556ZM5.21111 10.3778L4.12222 9.28889L11.3556 2.05556H8.55556V0.5H14V5.94444H12.4444V3.14444L5.21111 10.3778Z"
      fill="#000000"
    />
  </IconWrapper>
)
