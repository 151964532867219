import FLCloudFontLogo from '@components/ui-components/icons/fl-cloud-font-logo'
import FLCloudFruitLogo from '@components/ui-components/icons/fl-cloud-fruit-logo'
import { AppStoreContext } from '@store/RootStore'
import { useContext } from 'react'

export const LoginFailedMessage = () => {
    const appStore = useContext(AppStoreContext)
    const { auth: authStore } = appStore
    return (
        <div className="fixed top-0 left-0 w-screen h-screen bg-flp-primary-black">
            <div className="flp-flex-centered flex-col h-screen">
                <div className="relative overflow-hidden mb-3 h-16 left-0 w-260px">
                    <div className="absolute z-10 bg-flp-primary-black opacity-1">
                        <FLCloudFruitLogo className="mr-3.5" />
                    </div>
                    <div className="absolute z-0 overflow-hidden h-10 mt-4 right-0">
                        <FLCloudFontLogo />
                    </div>
                </div>
                <div>
                    <h1 className="text-25px font-gilroyLight tracking-wide">Instruments and Effects</h1>
                </div>
                <div>
                    <p className="font-gilroyMedium text-16px text-center tracking-wider text-white leading-[1.6] mt-3">Login failed</p>
                </div>
                <button
                    className="flp-button w-115px bg-flp-button-grey-background text-flp-button-premium-text-highlight border-flp-button-grey-background hover:bg-flp-button-premium-bg-highlight mt-6"
                    onClick={authStore.login}>
                    Retry
                </button>
            </div>
        </div>
    )
}
