import { ITierData } from '@store/models/Tier'

export const Config = {
    searchLimit: 50,
    urlLengthLimit: 2083,
    endpoints: {
        categories: '/plugin/all-categories',
        tiers: '/plugin/all-tiers',
        tags: '/tag?visibleOn=plugin',
        categoryTags: '/filter/plugin-category',
        collections: '/plugin/all-collections',
        search: '/plugin/search-plugins',
        pluginLicenceCode: '/plugin/product-key',
    },
}

export type IIdentifiable = {
    id: string
    name: string
    slug: string
    imageUrl: string
}

export type IPackageVersionAPI = {
    appId: string
    developer: string
    platform: 'mac' | 'win'
    size: number
    version: string
}

export type ILabelAPI = IIdentifiable

export type IPluginAPI = IIdentifiable & {
    imageWideUrl: string
    descriptionShort: string
    descriptionLong: string
    releaseDate: string
    appId: string
    label: ILabelAPI
    macVersion: IPackageVersionAPI
    winVersion: IPackageVersionAPI
    categories: Pick<IIdentifiable, 'id' | 'name'>[]
    tiers: ITierData[]
    shouldNotBeIncluded: boolean
    isHiddenFromCatalog: boolean
    assetUrl?: string
}

export type ISearchResponseAPI = {
    total: number
    categories: string[]
    tiers: string[]
    plugins: IPluginAPI[]
}

export type INameAndId = {
    name: string
    id: string
}

export type IPluginCategoryData = {
    name: string
    slug: string
    group: string
    category: INameAndId
    tags: INameAndId[]
}
