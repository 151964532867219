import { observer } from 'mobx-react'
import React, { useEffect } from 'react'

import useDropdown from '@src/hooks/useDropdown'

export type IOption = {
    onClick: () => void
}

export type IFloatingInfoProps = {
    toggleOpenCallback?: (isOpen: boolean) => void
    togglerOff: React.ReactNode
    togglerOn: React.ReactNode
    className?: string
    children?: React.ReactNode
}

const FloatingInfo = ({ toggleOpenCallback, togglerOff, togglerOn, className, children }: IFloatingInfoProps) => {
    const { isOpen, ref, toggleOpen } = useDropdown()

    useEffect(() => {
        toggleOpenCallback?.(isOpen)
    }, [isOpen, toggleOpenCallback])

    return (
        <>
            {isOpen ? (
                <div className="relative">
                    <button onClick={toggleOpen}>{togglerOn}</button>
                    <div ref={ref} className={`border border-flp-grey rounded-md py-7px px-8px absolute ${className}`}>
                        {children}
                    </div>
                </div>
            ) : (
                <button onClick={toggleOpen}>{togglerOff}</button>
            )}
        </>
    )
}

export default observer(FloatingInfo)
