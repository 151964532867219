import FLCloudFruitLogo from '@components/ui-components/icons/fl-cloud-fruit-logo'
import FLCloudFontLogo from '@components/ui-components/icons/fl-cloud-font-logo'
import { useState, useEffect, useCallback, useContext, useMemo } from 'react'
import { AppStoreContext } from '@store/RootStore'
import { LOGO_FRUIT_HEIGHT, LOGO_FRUIT_WIDTH, LOGO_TEXT_HEIGHT, LOGO_TEXT_WIDTH } from '@constants/dimensions'

type IIntroAnimationProps = {
    dismissCallback: () => void
}

export const IntroAnimation = ({ dismissCallback }: IIntroAnimationProps) => {
    const { scale } = useContext(AppStoreContext)
    const [firstAnimation, setFirstAnimation] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            setFirstAnimation(true)
        }, 200)
    }, [])

    const [secondAnimation, setSecondAnimation] = useState(false)
    const [thirdAnimation, setThirdAnimation] = useState(false)
    const handleSecondAnimation = useCallback(() => {
        setSecondAnimation(true)
        setTimeout(() => {
            setThirdAnimation(true)
        }, 500)
        setTimeout(() => {
            dismissCallback()
        }, 2000)
    }, [])

    const fruitLogoWidth = useMemo(() => {
        return LOGO_FRUIT_WIDTH * scale
    }, [scale])

    const fruitLogoHeight = useMemo(() => {
        return LOGO_FRUIT_HEIGHT * scale
    }, [scale])

    const textLogoWidth = useMemo(() => {
        return LOGO_TEXT_WIDTH * scale
    }, [scale])

    const textLogoHeight = useMemo(() => {
        return LOGO_TEXT_HEIGHT * scale
    }, [scale])

    return (
        <div className="relative flp-flex-centered flex-col h-screen z-10 justify-beteween overflow-hidden bg-black">
            <div className="relative z-10 flp-flex-centered flex-col -translate-y-20px">
                <div className={`duration-[400ms] ${firstAnimation || secondAnimation ? '' : 'translate-y-180px'}`}>
                    <div
                        className={`relative overflow-hidden mb-3 h-16 mx-auto duration-300
                            ${secondAnimation ? 'w-38px' : 'w-256px'}`}>
                        <div className="absolute z-10">
                            <FLCloudFruitLogo
                                className={`mr-3.5 duration-500 ${thirdAnimation ? 'opacity-0' : ''}`}
                                style={{ width: `${fruitLogoWidth}px`, height: `${fruitLogoHeight}px` }}
                            />
                        </div>
                        <div
                            className={`absolute z-0 overflow-hidden h-10 mt-4 
                                ${secondAnimation ? '' : 'left-50px'}`}>
                            <FLCloudFontLogo
                                className={`duration-500 ${secondAnimation ? 'opacity-0' : ''}`}
                                style={{ width: `${textLogoWidth}px`, height: `${textLogoHeight}px` }}
                            />
                        </div>
                    </div>
                    <h1
                        className={`text-white/80 text-25px tracking-wide text-center mt-3 duration-500 
                        ${secondAnimation ? 'opacity-0' : ''}`}>
                        Instruments and Effects
                    </h1>
                </div>

                <div className={`flex flex-col justify-between grow duration-700 ${secondAnimation ? 'opacity-0' : ''}`}>
                    <div className={`max-w-360px text-14px mx-auto mt-12 duration-700 ${firstAnimation ? 'opacity-100' : 'opacity-0'}`}>
                        <p className="font-gilroySemibold mb-3">Welcome to the FL Cloud plugins app.</p>
                        <p>
                            The plugins app can be used to browse, install and manage FL Cloud plugins so you always have the latest plugins,
                            automatically integrated straight into FL Studio.
                        </p>
                        <button
                            className="flp-button rounded-md border-0 text-14px leading-[0.8] w-175px bg-flp-button-orange-background text-flp-white hover:bg-flp-button-orange-background-highlight mx-auto mt-10"
                            onClick={handleSecondAnimation}>
                            Let's get started!
                        </button>
                    </div>
                </div>
            </div>
            <img
                src="/images/bg-intro.jpg"
                alt="FL-Cloud Plugins"
                className={`absolute z-0 top-0 w-full duration-1000 ${firstAnimation ? 'opacity-100' : 'opacity-0'}`}
            />
        </div>
    )
}
