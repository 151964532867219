import { IconWrapper, IDerivedIconProps } from '@components/icons/IconWrapper'

export const ErrorIcon = (props: IDerivedIconProps) => (
    <IconWrapper {...props} viewBox="0 0 20 20">
        <path
            d="M9.99998 14.7308C10.2288 14.7308 10.4207 14.6534 10.5755 14.4986C10.7303 14.3437 10.8077 14.1519 10.8077 13.9231C10.8077 13.6942 10.7303 13.5024 10.5755 13.3476C10.4207 13.1928 10.2288 13.1154 9.99998 13.1154C9.77113 13.1154 9.5793 13.1928 9.4245 13.3476C9.2697 13.5024 9.1923 13.6942 9.1923 13.9231C9.1923 14.1519 9.2697 14.3437 9.4245 14.4986C9.5793 14.6534 9.77113 14.7308 9.99998 14.7308ZM9.25 11.0769H10.75V5.07693H9.25V11.0769ZM10.0016 19.5C8.68772 19.5 7.45268 19.2506 6.29655 18.752C5.1404 18.2533 4.13472 17.5766 3.2795 16.7217C2.42427 15.8669 1.74721 14.8616 1.24833 13.706C0.749442 12.5504 0.5 11.3156 0.5 10.0017C0.5 8.68775 0.749334 7.45271 1.248 6.29658C1.74667 5.14043 2.42342 4.13475 3.27825 3.27953C4.1331 2.4243 5.13834 1.74724 6.29398 1.24836C7.44959 0.749473 8.68437 0.500031 9.9983 0.500031C11.3122 0.500031 12.5473 0.749365 13.7034 1.24803C14.8596 1.7467 15.8652 2.42345 16.7205 3.27828C17.5757 4.13313 18.2527 5.13837 18.7516 6.29401C19.2505 7.44962 19.5 8.6844 19.5 9.99833C19.5 11.3123 19.2506 12.5473 18.752 13.7034C18.2533 14.8596 17.5765 15.8653 16.7217 16.7205C15.8669 17.5757 14.8616 18.2528 13.706 18.7517C12.5504 19.2505 11.3156 19.5 10.0016 19.5ZM9.99998 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.87501 15.675 4.32501C14.125 2.77501 12.2333 2.00001 9.99998 2.00001C7.76664 2.00001 5.87498 2.77501 4.32498 4.32501C2.77498 5.87501 1.99998 7.76667 1.99998 10C1.99998 12.2333 2.77498 14.125 4.32498 15.675C5.87498 17.225 7.76664 18 9.99998 18Z"
            fill="#000000"
        />
    </IconWrapper>
)
