import { observer } from 'mobx-react'
import { useCallback, useEffect } from 'react'

import { COLORS } from '@styles/Colors'
import { ThreeDotIcon } from './icons/ThreeDotIcon'
import useDropdown from '@src/hooks/useDropdown'

export type IOption = {
    id: string
    title: string
    onClick: () => void
}

export type IThreeDotMenuProps = {
    options: IOption[]
    toggleOpenCallback?: (isOpen: boolean) => void
}

const ThreeDotMenu = (props: IThreeDotMenuProps) => {
    const { isOpen, ref, toggleOpen, setIsOpen } = useDropdown()
    const { options, toggleOpenCallback } = props

    useEffect(() => {
        toggleOpenCallback?.(isOpen)
    }, [isOpen])

    const onSelectOption = useCallback(
        (callback?: () => void) => {
            return () => {
                setIsOpen(false)
                callback?.()
            }
        },
        [setIsOpen],
    )

    return (
        <div ref={ref} className="absolute -right-15px z-20 text-left w-10px flex items-center">
            <button type="button" className="w-full select-none outline-none" onClick={toggleOpen}>
                <ThreeDotIcon
                    className="flp-icon h-20px w-10px"
                    style={{ '--icon-fill-color': COLORS['flp-grey'], '--icon-fill-hover-color': COLORS['flp-white'] as string }}
                />
            </button>

            {isOpen && (
                <div className="absolute z-10 my-1 origin-top-right rounded-3px top-20px right-0 w-100px bg-flp-primary-black border-1 border-flp-black shadow-md">
                    {options.map(({ title, id, onClick }) => {
                        return (
                            <div
                                key={`option-${id}`}
                                className="flex text-11px w-full py-0.5 pr-3 pl-5 text-flp-grey tracking-wider cursor-pointer select-none hover:text-white"
                                onClick={onSelectOption(onClick)}>
                                {title}
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default observer(ThreeDotMenu)
