import InstallStatusButton from '@components/InstallStatusButton'

import { useCallback, useMemo } from 'react'
import { observer } from 'mobx-react'

import { IFLPlugin, InstallState } from '@store/models/FLPlugin'
import { useAppStoreContext } from '@store/RootStore'
import { ModalType } from '@constants/types'

import { openExternalUrl } from '@src/interop'
import { Config } from '@constants/url'

type IPluginActionButtonProps = {
    plugin: IFLPlugin
    isHovered?: boolean
    uninstallCallback?: () => void
}

export const PluginActionButton = ({ plugin, isHovered = false, uninstallCallback = () => {} }: IPluginActionButtonProps) => {
    const { modal: modalStore, auth } = useAppStoreContext()

    const handleSubscribeClick = useCallback(async () => {
        await auth.updateAccountInfo()

        if (plugin.isAvailableForUsersTier) return

        openExternalUrl(
            Config.externalUrls.flCloudAccountUpgrade,
            auth.user?.isFree || auth.user?.isTrial ? 'Subscribe to FL Cloud' : 'Upgrade your account tier',
        )
    }, [auth.user, plugin])

    const handleUninstallClick = useCallback(() => {
        uninstallCallback()
        modalStore.showModal(ModalType.CONFIRM_UNINSTALL, { pluginAppId: plugin.appId })
    }, [plugin.appId, uninstallCallback])

    const canBeUninstalled = useMemo(() => {
        return [InstallState.INSTALLED].includes(plugin.installStatus as InstallState) && !plugin.canBeUpdated
    }, [plugin.installStatus, plugin.canBeUpdated])

    if (!plugin.isAvailableForUsersTier) {
        return (
            <div
                className="flp-button group bg-flp-button-bg text-flp-button-text border-flp-button-bg hover:bg-flp-button-bg-highlight hover:border-transparent"
                onClick={handleSubscribeClick}>
                Subscribe
            </div>
        )
    }

    if (!plugin.platformPackage && !plugin.isLicenseRequired) return

    return <InstallStatusButton plugin={plugin} canBeUninstalled={canBeUninstalled} uninstallPlugin={handleUninstallClick} isHovered={isHovered} />
}

export default observer(PluginActionButton)
