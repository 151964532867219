/**
 * Formats a given number of bytes into human readable string, to the closest "quantifier" e.g. X MB
 * @function
 * @param {number} bytes - The size in bytes
 * @param {number} decimals - The decimals to round to, default: 2
 */
export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  let i = 0

  while (bytes >= 1024 && i < sizes.length - 1) {
    bytes /= 1024
    i++
  }

  return `${parseFloat(bytes.toFixed(decimals))} ${sizes[i]}`
}
