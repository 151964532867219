/* eslint-disable prettier/prettier */
import { useCallback, useMemo } from 'react'
import { observer } from 'mobx-react'

import { ExternalLinkIcon } from '@components/icons/ExternalLinkIcon'
import { useAppStoreContext } from '@store/RootStore'
import { COLORS } from '@styles/Colors'
import SoundsBankSettings from '@components/SoundBankSettings'
import { openExternalUrl } from '@src/interop'
import { Config } from '@constants/url'
import { ITier } from '@store/models/Tier'

import TagManager from 'react-gtm-module'
import { formatGTMEvent, GTMEvent, IDeauthorizeMachineEvent } from '@utils/gtm-event'

import ENV from '@constants/env'
import { ModalType } from '@constants/types'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import ZoomControl from '@components/ZoomControl'

export const Settings = () => {
    const {
        auth: authStore,
        license: licenseStore,
        feed: feedStore,
        modal: modalStore,
        version,
        checkForUpdate,
        gtmInitialized,
        scale,
    } = useAppStoreContext()
    const { licenses } = licenseStore

    const deauthorize = useCallback(
        (license: any) => {
            return () => {
                licenseStore.removeLicense(license.id)

                if (ENV.VITE_GOOGLE_TAG_MANAGER && gtmInitialized) {
                    const gtmDeauthorizeMachineArgs = formatGTMEvent(GTMEvent.MACHINE_DEAUTHORIZED, {
                        machineName: license.machineName,
                    } as Partial<IDeauthorizeMachineEvent>)
                    TagManager.dataLayer({ dataLayer: gtmDeauthorizeMachineArgs.dataLayer })
                }
            }
        },
        [licenseStore],
    )

    const handleOpenAccountPage = useCallback(() => {
        openExternalUrl(Config.externalUrls.flCloudAccount, '')
    }, [])

    const handleUpgradeOptionsPage = useCallback(() => {
        openExternalUrl(
            Config.externalUrls.flCloudAccountUpgrade,
            authStore.user?.isFree || authStore.user?.isTrial ? 'Subscribe to FL Cloud' : 'Upgrade your account tier',
        )
    }, [authStore.user])

    const tier = useMemo(() => {
        return feedStore.allTiers.find((tier: ITier) => {
            if (authStore.user?.isPlus) return tier.isPlus
            if (authStore.user?.isPro) return tier.isPro
            if (authStore.user?.isFree) return tier.isFree
        })
    }, [authStore.user?.state, authStore.user?.tierType, feedStore.allTiers?.length])

    const tierText = useMemo(() => {
        if (!tier) return ''

        return `You are an FL Cloud ${tier.name}${authStore.user?.isTrial ? ' (Trial)' : ''} user`
    }, [tier])

    const showCreditsAndAcknowledgements = useCallback(() => {
        modalStore.showModal(ModalType.CREDITS_AND_ACKNOWLEDGEMENTS, {})
    }, [])

    const onClickVersionCheck = useCallback(() => {
        checkForUpdate()
    }, [])

    return (
        <OverlayScrollbarsComponent element="section" className="max-h-full h-full pr-14px -mr-10px relative">
            <div id="settings-screen" className="text-11px tracking-wide pb-20px pt-25px">
                <div className="flex justify-between font-gilroyMedium pr-4">
                    <p>{tierText}</p>
                    <p>{authStore?.user?.name}</p>
                </div>
                <h1 className="flp-title my-5">Settings</h1>

                <div className="flex flex-col gap-6">
                    <div>
                        <h2 className="flp-subtitle mb-3.5">My FL Cloud</h2>
                        <ul className="flp-settings-list">
                            <li>
                                <a className="flp-settings-list-link" onClick={handleOpenAccountPage}>
                                    Go to my account
                                    <ExternalLinkIcon
                                        className="flp-icon inline-block ml-3"
                                        style={{
                                            '--icon-fill-color': COLORS['flp-grey'] as string,
                                            '--icon-fill-hover-color': COLORS['flp-white'] as string,
                                            width: `${14 * scale}px`,
                                            height: `${14 * scale}px`,
                                        }}
                                    />
                                </a>
                            </li>
                            <li>
                                <a className="flp-settings-list-link" onClick={handleUpgradeOptionsPage}>
                                    Upgrade options
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <h2 className="flp-subtitle mb-3.5">Active machines*</h2>
                        {licenseStore.isPending ? (
                            <p>Loading...</p>
                        ) : licenseStore.failed ? (
                            <p onClick={licenseStore.updateLicenses}>Could not update licenses. Click to try again...</p>
                        ) : (
                            <ul className="flp-settings-list">
                                {licenses.map((license: any) => {
                                    return (
                                        <li key={license.id} className="flex items-center justify-between">
                                            <p>
                                                {license.machineName}
                                                {license.isActive ? ' (This machine)' : ''}
                                            </p>
                                            <button
                                                onClick={deauthorize(license)}
                                                className="flp-button w-115px h-24px px-3 bg-flp-button-bg text-flp-button-text border-bg-flp-button-bg hover:bg-flp-button-bg-highlight hover:border-transparent -my-0.5"
                                            >
                                                Deauthorize
                                            </button>
                                        </li>
                                    )
                                })}
                                <li>
                                    <p className="text-10px tracking-wider max-w-280px">
                                        *You may install FL Cloud subscription plugins on a maximum of three personal computers simultaneously
                                    </p>
                                </li>
                            </ul>
                        )}
                    </div>

                    <SoundsBankSettings />

                    {version && (
                        <div>
                            <h2 className="flp-subtitle mb-3.5">About</h2>
                            <p id="fl-cloud-plugins-version" className="mb-3">
                                FL Cloud Plugins v{version}
                            </p>
                            <p className="mb-3">
                                <span className="flp-settings-list-link underline" onClick={onClickVersionCheck}>
                                    Check for updates
                                </span>
                            </p>
                            <p>
                                <span className="flp-settings-list-link underline" onClick={showCreditsAndAcknowledgements}>
                                    Credits &amp; acknowledgements
                                </span>
                            </p>
                        </div>
                    )}
                </div>
            </div>
            <div className="fixed bottom-0 right-[24px] p-1 bg-flp-black">
                <ZoomControl />
            </div>
        </OverlayScrollbarsComponent>
    )
}

export default observer(Settings)
