import { useCallback } from 'react'

import { observer } from 'mobx-react'
import { useAppStoreContext } from '@store/RootStore'
import ModalWrapper from '@components/modal/ModalWrapper'
import { COLORS } from '@styles/Colors'
import { AccountIcon } from '@components/icons/AccountIcon'

export const ModalConfirmLogout = () => {
    const { modal: modalStore, auth: authStore, scale } = useAppStoreContext()

    const confirmClick = useCallback(() => {
        authStore.logout()
    }, [])

    const cancelClick = useCallback(() => {
        modalStore.closeModal()
    }, [])

    return (
        <ModalWrapper>
            <div className="w-246px mx-auto text-center text-flp-text select-none">
                <AccountIcon
                    className="flp-icon mx-auto"
                    style={{
                        '--icon-fill-color': COLORS['flp-text'],
                        '--icon-fill-hover-color': COLORS['flp-text'] as string,
                        width: `${20 * scale}px`,
                        height: `${20 * scale}px`,
                    }}
                />
                <h1 className="flp-header text-flp-text pt-4">Sign out</h1>
                <div className="text-16px text-flp-text py-4">Are you sure you want to sign out of FL Cloud?</div>
            </div>

            <div className="flex gap-10 pt-8 justify-center">
                <div
                    id="button-cancel-logout"
                    className="flp-button group bg-flp-button-grey-background text-flp-button-grey-text border-flp-button-grey-background"
                    onClick={cancelClick}>
                    Cancel
                </div>
                <div
                    id="button-confirm-logout"
                    className="flp-button group bg-flp-button-grey-background border-flp-button-grey-background text-flp-primary hover:bg-flp-button-bg-highlight hover:border-transparent"
                    onClick={confirmClick}>
                    Sign Out
                </div>
            </div>
        </ModalWrapper>
    )
}

export default observer(ModalConfirmLogout)
