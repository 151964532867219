import { NotificationType } from '@constants/types'
import { types } from 'mobx-state-tree'

export const Notification = types
    .model('Notification', {
        message: types.string,
        type: typeof NotificationType,
    })
    .views(self => ({
        get isNotice() {
            return self.type === NotificationType.SUCCESS
        },
        get isWarning() {
            return self.type === NotificationType.WARNING
        },
        get isError() {
            return self.type === NotificationType.ERROR
        },
    }))
