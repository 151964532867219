export function getCookie(name: string): string | undefined {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)

    if (parts.length === 2) return parts.pop()?.split(';').shift()
}

export const isTTLCookieExpired = (cookieValue: string): boolean => {
    const cookieParsed = parseInt(cookieValue, 10)

    return new Date().getTime() / 1000 > cookieParsed
}
