import { ConnectionStatus } from '@store/RootStore'
import ENV from '@constants/env'

export const checkInternetConnectivity = (setConnectionStatus: (status: ConnectionStatus) => void) => {
    if (navigator?.onLine === false) {
        setConnectionStatus(ConnectionStatus.OFFLINE)
        return
    }

    fetch(`${ENV.VITE_CDN_URL}/000/online.txt`, {
        method: 'HEAD',
        cache: 'no-cache',
        referrerPolicy: 'origin-when-cross-origin',
        signal: AbortSignal?.timeout?.(3000),
    })
        .then(() => {
            setConnectionStatus(ConnectionStatus.ONLINE)
        })
        .catch(() => {
            setConnectionStatus(ConnectionStatus.OFFLINE)
        })
}
