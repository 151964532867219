import { ZOOM_AMOUNT, ZOOM_LIMIT_MAX, ZOOM_LIMIT_MIN } from '@constants/dimensions'
import { LS_ZOOM_SETTING } from '@constants/storage'
import { AppStoreContext } from '@store/RootStore'
import { clamp } from '@utils/misc'
import { observer } from 'mobx-react'
import { useCallback, useContext, useMemo } from 'react'

enum ZoomDirection {
    INCREASE,
    DECREASE,
}

// Note: This component must use fixed px values as we don't want the scale to effect it
export const ZoomControl = () => {
    const appStore = useContext(AppStoreContext)
    const { scale, setScale } = appStore

    const onClickZoom = useCallback(
        (direction: ZoomDirection) => {
            return () => {
                const newScale = direction === ZoomDirection.DECREASE ? scale - ZOOM_AMOUNT : scale + ZOOM_AMOUNT
                const scaleClamped = clamp(newScale, ZOOM_LIMIT_MIN, ZOOM_LIMIT_MAX)
                setScale(scaleClamped)
                localStorage.setItem(LS_ZOOM_SETTING, `${scaleClamped}`)
            }
        },
        [scale],
    )

    const zoomPercent = useMemo(() => {
        return `${Math.round(scale * 100)}%`
    }, [scale])

    return (
        <div className="flex items-center justify-center h-[20px]">
            <div className="text-[11px] w-[60px] pr-[16px] text-right">Zoom</div>
            <div
                className="text-flp-grey hover:text-white cursor-pointer w-[20px] h-[20px] text-[22px] flex items-center justify-center"
                onClick={onClickZoom(ZoomDirection.DECREASE)}>
                -
            </div>
            <div className="w-[60px] text-[11px] text-center">{zoomPercent}</div>
            <div
                className="text-flp-grey hover:text-white cursor-pointer w-[20px] h-[20px] text-[22px] flex items-center justify-center"
                onClick={onClickZoom(ZoomDirection.INCREASE)}>
                +
            </div>
        </div>
    )
}

export default observer(ZoomControl)
