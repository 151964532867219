export enum Platform {
    WIN = 'win',
    MAC = 'mac',
    UNKNOWN = 'unknown',
}

export enum NotificationType {
    SUCCESS = 'success',
    WARNING = 'warning',
    ERROR = 'error',
}

export enum ModalType {
    UI_OVERLAY = 'uiOverlay',
    CONFIRM_UNINSTALL = 'confirmUninstall',
    UPDATE_PROMPT = 'updatePrompt',
    CONFIRM_LOGOUT = 'confirmLogout',
    CONFIRM_CLOSE_APP = 'confirmCloseApp',
    RETRIEVE_LICENCE_CODE = 'retrieveLicenceCode',
    THIRD_PARTY_OPT_IN = 'thirdPartyOptIn',
    CREDITS_AND_ACKNOWLEDGEMENTS = 'creditsAndAcknowledgements',
    REQUIRES_DEPENDENCIES_INSTALL = 'requiresDependenciesInstall',
}
