import { IconWrapper, IDerivedIconProps } from '@components/icons/IconWrapper'

export const UpgradeIcon = (props: IDerivedIconProps) => (
    <IconWrapper {...props} viewBox="0 0 10 14">
        <path
            d="M1.21051 13.5C1.00919 13.5 0.840447 13.4361 0.704277 13.3082C0.568092 13.1804 0.5 13.0219 0.5 12.833C0.5 12.6439 0.568092 12.4856 0.704277 12.358C0.840447 12.2303 1.00919 12.1665 1.21051 12.1665H8.78949C8.99081 12.1665 9.15956 12.2304 9.29575 12.3583C9.43192 12.4861 9.5 12.6446 9.5 12.8336C9.5 13.0225 9.43192 13.1809 9.29575 13.3085C9.15956 13.4362 8.99081 13.5 8.78949 13.5H1.21051ZM4.99969 10.2175C4.79828 10.2175 4.62956 10.1536 4.49353 10.0258C4.35751 9.89804 4.28949 9.73969 4.28949 9.55078V2.70194L2.07041 4.77064C1.93925 4.89373 1.77832 4.95613 1.58763 4.95785C1.39694 4.95955 1.23063 4.8943 1.0887 4.76209C0.958576 4.6409 0.893515 4.48666 0.893515 4.29936C0.893515 4.11206 0.959105 3.95687 1.09028 3.83378L4.40062 0.727387C4.48928 0.64419 4.5832 0.585493 4.68239 0.551295C4.78158 0.517098 4.88785 0.5 5.00121 0.5C5.11458 0.5 5.22045 0.517098 5.31881 0.551295C5.4172 0.585493 5.51072 0.64419 5.59938 0.727387L8.91883 3.84234C9.05001 3.96542 9.11803 4.11644 9.12287 4.29538C9.12774 4.47431 9.05972 4.63037 8.91883 4.76358C8.78969 4.88568 8.62532 4.94673 8.42573 4.94673C8.22613 4.94673 8.06075 4.88518 7.92959 4.76209L5.71051 2.70194V9.55078C5.71051 9.73969 5.64239 9.89804 5.50616 10.0258C5.36991 10.1536 5.20109 10.2175 4.99969 10.2175Z"
            fill="#000000"
        />
    </IconWrapper>
)
