/* eslint-disable prettier/prettier */
import { CrossIcon } from '@components/icons/CrossIcon'
import { TickIcon } from '@components/icons/TickIcon'
import { COLORS } from '@styles/Colors'
import { Notification as NotificationModel } from '@store/models/Notification'
import { AlertIcon } from '@components/icons/AlertIcon'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ErrorIcon } from '@components/icons/ErrorIcon'
import { Instance } from 'mobx-state-tree'

type INotificationProps = {
    notification: Instance<typeof NotificationModel>
    dismiss: () => void
    forceDismiss: boolean
    scale: number
}

const DISMISS_TIMEOUT = 5000
const ANIMATION_DURATION = 500

export const Notification = ({ notification, dismiss, forceDismiss = false, scale = 1 }: INotificationProps) => {
    const [animationClass, setAnimationClass] = useState(`animate-[slideDown_${ANIMATION_DURATION}ms_ease-in-out]`)
    const [isVisible, setIsVisible] = useState(!!notification)
    const dismissTimerRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined)

    const dismissNotification = useCallback(() => {
        setIsVisible(false)
        dismiss()
        clearTimeout(dismissTimerRef.current)
    }, [dismiss])

    useEffect(() => {
        if (notification) {
            setAnimationClass('animate-[slideDown_500ms_ease-in-out]')
            setIsVisible(true)

            dismissTimerRef.current = setTimeout(() => {
                setAnimationClass('animate-[slideUp_500ms_ease-in-out]')
                setTimeout(() => {
                    dismissNotification()
                }, ANIMATION_DURATION - 20)
            }, DISMISS_TIMEOUT)
            return
        }

        setIsVisible(false)

        return () => {
            clearTimeout(dismissTimerRef.current)
        }
    }, [notification, dismissTimerRef])

    useEffect(() => {
        if (forceDismiss) dismiss()
    }, [forceDismiss])

    const color = useMemo(() => {
        if (notification?.isError) return COLORS['flp-red'] as string
        if (notification?.isWarning) return COLORS['flp-yellow'] as string

        return COLORS['flp-primary'] as string
    }, [notification])
    const icon = useMemo(() => {
        const style = { '--icon-fill-color': color, '--icon-fill-hover-color': color, width: `${17 * scale}px`, height: `${17 * scale}px` }
        if (notification?.isError) return <ErrorIcon className="flp-icon" style={style} />
        if (notification?.isWarning) return <AlertIcon className="flp-icon" style={style} />

        return <TickIcon className="flp-icon" style={style} />
    }, [notification, color])

    const className = useMemo(() => {
        if (notification?.isError) return 'error'
        if (notification?.isWarning) return 'warning'

        return 'notice'
    }, [notification])

    if (!isVisible) return

    return (
        <div className={`flp-notification ${className} ${animationClass}`}>
            {icon}
            <p>{notification?.message}</p>
            {dismissNotification && (
                <div onClick={dismissNotification}>
                    <CrossIcon
                        className="flp-icon rotate-45 cursor-pointer"
                        style={{
                            '--icon-stroke-color': color,
                            '--icon-stroke-hover-color': COLORS['flp-white'] as string,
                            width: `${13 * scale}px`,
                            height: `${13 * scale}px`,
                        }}
                    />
                </div>
            )}
        </div>
    )
}

export default Notification
