import { useCallback, useMemo } from 'react'

import { observer } from 'mobx-react'
import { useAppStoreContext } from '@store/RootStore'
import ModalWrapper from '@components/modal/ModalWrapper'

export const ModalInstallDependencies = () => {
    const { modal: modalStore, feed: feedStore } = useAppStoreContext()
    const { data } = modalStore

    const plugin = useMemo(() => {
        return feedStore.plugins.get(data.pluginAppId)
    }, [data.pluginAppId])

    const confirmClick = useCallback(() => {
        if (!plugin) return

        plugin?.installPlugin()
        modalStore.closeModal()
    }, [plugin])

    const cancelClick = useCallback(() => {
        modalStore.closeModal()
    }, [])

    const hasSingleDependency = useMemo(() => {
        return plugin?.dependencies.length === 1
    }, [plugin])

    const dependencyDescription = useMemo(() => {
        return hasSingleDependency ? <span className="text-white">{plugin?.dependencies[0].name}</span> : 'some dependencies'
    }, [plugin, hasSingleDependency])

    const pluginDependenciesList = useMemo(() => {
        return plugin?.dependencies.length > 0 ? (
            <div className="text-center text-flp-text text-11px">
                <ul id="dependents-list" className="list-disc pt-2">
                    {plugin?.dependencies.map(({ name, appId }: any) => {
                        return (
                            <li key={appId} className="text-white">
                                {name}
                            </li>
                        )
                    })}
                </ul>
            </div>
        ) : (
            <></>
        )
    }, [plugin])

    if (!plugin) return

    return (
        <ModalWrapper>
            <div className="text-center text-flp-text">
                <h1 className="flp-header text-flp-text">
                    <span className="text-white">{plugin.name}</span> requires {dependencyDescription}
                </h1>
                <div className="py-4 text-flp-white">
                    {plugin?.name} requires {dependencyDescription} to run. Click Ok to download and install {plugin?.name} and the following
                    dependencies on your system:
                </div>
                {pluginDependenciesList}
            </div>
            <div className="flex gap-10 pt-8 justify-center">
                <div
                    id="button-cancel-install"
                    className="flp-button group bg-flp-button-grey-background text-flp-button-grey-text border-flp-button-grey-background"
                    onClick={cancelClick}>
                    Cancel
                </div>
                <div
                    id="button-confirm-install"
                    className="flp-button group bg-flp-button-yellow-background border-flp-button-yellow-background-highlight text-flp-button-yellow-text hover:bg-flp-button-yellow-background-highlight hover:border-transparent"
                    onClick={confirmClick}>
                    Ok
                </div>
            </div>
        </ModalWrapper>
    )
}

export default observer(ModalInstallDependencies)
