// Used to do user sizing
export const BASE_FONT_SIZE = 16

export const PLUGIN_ROW_HEIGHT = 85

export const FOOTER_HEIGHT = 24

export const ZOOM_AMOUNT = 0.1

export const ZOOM_LIMIT_MIN = 0.5

export const ZOOM_LIMIT_MAX = 1.5

export const LOGO_FRUIT_WIDTH = 35

export const LOGO_FRUIT_HEIGHT = 55

export const LOGO_TEXT_WIDTH = 210

export const LOGO_TEXT_HEIGHT = 33

export const LOADER_SIZE = 80
