import { MagnifyingGlassIcon } from '@components/icons/MagnifyingGlassIcon'
import { COLORS } from '@styles/Colors'

type INoResultsProps = {
    searchTerm?: string | null
    resetSearch: () => void
    scale: number
}

export const NoResults = ({ searchTerm, resetSearch, scale = 1 }: INoResultsProps) => {
    return (
        <div className="flex flex-col gap-4 items-center justify-center grow text-13px text-center h-full">
            {(!searchTerm || searchTerm === '') && <div>No plugins found</div>}
            {searchTerm && searchTerm !== '' && (
                <>
                    <div className="flex items-center">
                        <MagnifyingGlassIcon
                            className="flp-icon mr-3"
                            style={{
                                '--icon-fill-color': COLORS['flp-grey'],
                                '--icon-fill-hover-color': COLORS['flp-grey'] as string,
                                width: `${15 * scale}px`,
                                height: `${15 * scale}px`,
                            }}
                        />
                        <div>Your search for</div>
                    </div>
                    <span className="font-gilroySemibold text-16px">'{searchTerm}'</span>
                    <span>did not deliver any results.</span>
                    <span>Try using different search terms or looking in a higher tier</span>
                    <button
                        className="flp-button w-115px h-37px px-6 bg-flp-button-bg text-flp-button-text border-bg-flp-button-bg hover:bg-flp-button-bg-highlight hover:border-transparent mt-6"
                        onClick={resetSearch}>
                        Clear Search
                    </button>
                </>
            )}
        </div>
    )
}

export default NoResults
