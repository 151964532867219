import { IconWrapper, IDerivedIconProps } from '@components/icons/IconWrapper'

export const EditDirectoryOnIcon = (props: IDerivedIconProps) => (
    <IconWrapper {...props} viewBox="0 0 20 20">
        <g>
            <path
                fill="#000000"
                stroke="none"
                d="M10,0C4.49,0,0,4.49,0,10s4.49,10,10,10,10-4.49,10-10S15.51,0,10,0ZM14.9,6.98c-.04.11-.1.2-.19.29l-7.38,7.38c-.1.1-.21.17-.34.23-.13.05-.26.08-.41.08h-1.01c-.15,0-.27-.05-.38-.15-.1-.1-.15-.23-.15-.38v-1.01c0-.14.03-.28.08-.41.05-.13.13-.24.23-.34l7.39-7.38c.09-.08.19-.14.29-.19.11-.04.22-.07.34-.07s.23.02.34.06c.11.04.21.11.29.2l.71.72c.09.08.16.18.19.29.04.11.06.22.06.33,0,.12-.02.23-.06.34Z"
            />
            <polygon fill="#b3b3b3" stroke="none" points="5.92 13.35 5.92 14.08 6.65 14.08 12.62 8.11 11.89 7.38 5.92 13.35" />
        </g>
    </IconWrapper>
)
