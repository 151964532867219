type IProgressBarProps = {
    label?: string
    progressAmount?: number
    progressOffset?: number
    isDownload?: boolean
    isAnimated?: boolean
    className?: string
}

export const ProgressBar = ({
    label,
    progressAmount = 0,
    progressOffset = 0,
    isDownload = false,
    isAnimated = false,
    className = '',
}: IProgressBarProps) => {
    return (
        <div className={`h-20px select-none text-11px tracking-wider whitespace-nowrap ${className}`}>
            <div className="h-full relative flp-flex-centered">
                <p className="flex items-center gap-1 w-full leading-none pl-2.5 pt-1px z-10">
                    <span className="inline-block whitespace-pre w-40">{label ?? ''}</span>
                </p>
                <div className="h-full flp-absolute-centered w-full z-0 flex flex-start">
                    <div className="absolute h-full bg-flp-progress-bar" style={{ width: `${progressOffset}%` }}></div>
                    <div
                        className={`flp-progress-bar ${
                            isDownload ? 'bg-flp-progress-bar absolute h-full' : 'bg-flp-progress-bar-background relative w-full'
                        }
                        ${progressAmount <= 90 ? 'rounded-r-none' : ''}
                        `}
                        style={
                            //@ts-ignore - this is okay for passing CSS variables
                            { '--progress-amount': `${progressAmount > 90 ? 100 : progressAmount}%` }
                        }>
                        {/* Animate div with width 10px to move right and left in this div */}
                        {isAnimated && <div className="bg-flp-progress-bar animation-slide absolute w-5px h-full" />}
                    </div>
                    <div className="absolute h-full w-full bg-flp-progress-bar-background rounded-3px" />
                </div>
            </div>
        </div>
    )
}
