import { ReactElement, useCallback, useEffect, useRef } from 'react'

import { observer } from 'mobx-react'
import { useAppStoreContext } from '@store/RootStore'
import useKeys, { Keys } from '@src/hooks/useKeys'
import { ClosePanelIcon } from '@components/icons/ClosePanelIcon'
import { COLORS } from '@styles/Colors'

type IModalWrapperProps = {
    children?: ReactElement | ReactElement[]
    onClose?: () => void
}

export const ModalWrapper = ({ children, onClose }: IModalWrapperProps) => {
    const modalRef = useRef<HTMLDivElement>(null)
    const { modal: modalStore, scale } = useAppStoreContext()
    const { activeModal, closeModal, allowDismiss } = modalStore

    const closeModalCallback = useCallback(() => {
        if (!allowDismiss) return
        closeModal()
        onClose?.()
    }, [allowDismiss])

    const closeModalOnOutsideClick = useCallback(
        (event: any) => {
            if (!modalRef?.current) return

            if (!modalRef.current!.contains(event.target)) {
                closeModalCallback()
            }
        },
        [modalRef, allowDismiss],
    )

    useEffect(() => {
        document.addEventListener('mousedown', closeModalOnOutsideClick)
        return () => {
            document.removeEventListener('mousedown', closeModalOnOutsideClick)
        }
    }, [])

    useKeys(Keys.ESC, modalRef.current, !!activeModal, closeModalCallback)

    return (
        <div className={`fixed flex justify-center items-center z-40 top-0 left-0 w-screen h-screen ${activeModal ? '' : 'opacity-0 hidden'}`}>
            <div className="z-40 bg-black/30 duration-200 fixed top-0 left-0 w-screen h-screen pointer-events-none" />

            {children && (
                <div ref={modalRef} className="relative z-50 top-0 w-510px min-h-200px p-8 bg-flp-primary-grey rounded-md group duration-300">
                    <div className="flp-close-button--wrapper right-10px top-10px" onClick={closeModalCallback}>
                        <ClosePanelIcon
                            className="flp-close-button"
                            style={{
                                '--icon-fill-color': COLORS['flp-text'],
                                '--icon-fill-hover-color': COLORS['flp-white'] as string,
                                width: `${19 * scale}px`,
                                height: `${19 * scale}px`,
                            }}
                        />
                    </div>
                    {children}
                </div>
            )}
        </div>
    )
}

export default observer(ModalWrapper)
