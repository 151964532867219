import { IconWrapper, IDerivedIconProps } from '@components/icons/IconWrapper'
import { FC } from 'react'

const FLCloudFontLogo: FC<IDerivedIconProps> = (props: IDerivedIconProps) => {
    return (
        <IconWrapper {...props} viewBox="0 0 210 33">
            <path fill="#FFFFFF" d="M0,0.7h15.3v3H3v9.6h12.3v3H3v15H0V0.7z" />
            <path fill="#FFFFFF" d="M21.5,0.7h3.1v27.7h11.8v3H21.5V0.7z" />
            <path
                fill="#FFFFFF"
                d="M83.3,6.4l-2.4,1.9c-1.3-1.7-2.9-3-4.8-3.9S72.2,3,70,3c-2.4,0-4.7,0.6-6.8,1.8c-2.1,1.2-3.7,2.7-4.9,4.7
	c-1.1,2-1.7,4.2-1.7,6.7c0,3.7,1.3,6.9,3.8,9.4c2.6,2.5,5.8,3.7,9.7,3.7c4.3,0,7.9-1.7,10.8-5l2.4,1.8c-1.5,1.9-3.4,3.5-5.7,4.5
	c-2.3,1.1-4.8,1.6-7.6,1.6c-5.4,0-9.6-1.8-12.7-5.4c-2.6-3-3.9-6.7-3.9-10.9c0-4.5,1.6-8.3,4.7-11.3C61.3,1.5,65.2,0,69.9,0
	c2.9,0,5.4,0.6,7.7,1.7C80,2.8,81.9,4.4,83.3,6.4z"
            />
            <path fill="#FFFFFF" d="M90.3,0.7h3.1v27.7h11.8v3H90.3V0.7z" />
            <path
                fill="#FFFFFF"
                d="M125.5,0c4.6,0,8.5,1.5,11.6,4.6s4.7,6.9,4.7,11.4s-1.6,8.3-4.7,11.4c-3.1,3.1-6.9,4.7-11.4,4.7
	s-8.4-1.6-11.5-4.7c-3.1-3.1-4.7-6.9-4.7-11.3c0-2.9,0.7-5.7,2.1-8.2c1.4-2.5,3.4-4.5,5.8-5.9C120,0.7,122.6,0,125.5,0z M125.7,3
	c-2.3,0-4.4,0.6-6.4,1.8c-2,1.2-3.6,2.8-4.7,4.8c-1.1,2-1.7,4.2-1.7,6.7c0,3.6,1.3,6.7,3.8,9.2s5.6,3.7,9.1,3.7
	c2.4,0,4.6-0.6,6.6-1.7c2-1.2,3.6-2.7,4.7-4.7c1.1-2,1.7-4.2,1.7-6.7c0-2.4-0.6-4.6-1.7-6.6c-1.1-2-2.7-3.5-4.8-4.7
	C130.1,3.6,128,3,125.7,3z"
            />
            <path
                fill="#FFFFFF"
                d="M148.9,0.7h3.1v18.5c0,2.2,0,3.6,0.1,4.1c0.2,1.2,0.5,2.2,1.1,3s1.4,1.5,2.6,2c1.2,0.5,2.3,0.8,3.5,0.8
	c1,0,2-0.2,2.9-0.6c0.9-0.4,1.7-1,2.3-1.8s1.1-1.7,1.4-2.8c0.2-0.8,0.3-2.4,0.3-4.8V0.7h3.1v18.5c0,2.7-0.3,5-0.8,6.6
	c-0.5,1.7-1.6,3.1-3.2,4.4c-1.6,1.2-3.5,1.9-5.8,1.9c-2.5,0-4.6-0.6-6.4-1.8c-1.8-1.2-2.9-2.7-3.5-4.7c-0.4-1.2-0.6-3.3-0.6-6.5V0.7
	H148.9z"
            />
            <path
                fill="#FFFFFF"
                d="M177.6,31.4V0.7h6.4c4.6,0,7.9,0.4,10,1.1c3,1,5.3,2.8,7,5.4s2.5,5.6,2.5,9.2c0,3.1-0.7,5.8-2,8.1
	s-3,4.1-5.2,5.2c-2.1,1.1-5.1,1.7-8.9,1.7H177.6z M180.6,28.5h3.5c4.2,0,7.2-0.3,8.8-0.8c2.3-0.7,4.1-2.1,5.4-4c1.3-2,2-4.3,2-7.2
	c0-3-0.7-5.5-2.1-7.6c-1.4-2.1-3.4-3.5-6-4.3c-1.9-0.6-5.1-0.9-9.5-0.9h-2.2v24.8H180.6z"
            />
        </IconWrapper>
    )
}

export default FLCloudFontLogo
