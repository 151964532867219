import { IconWrapper, IDerivedIconProps } from '@components/icons/IconWrapper'

export const ClosePanelIcon = (props: IDerivedIconProps) => (
    <IconWrapper {...props} viewBox="0 0 20 20">
        <path
            d="M9.99998 11.0538L13.0731 14.1269C13.2115 14.2654 13.3856 14.3362 13.5952 14.3394C13.8048 14.3426 13.982 14.2718 14.1269 14.1269C14.2718 13.982 14.3442 13.8064 14.3442 13.6C14.3442 13.3936 14.2718 13.218 14.1269 13.0731L11.0538 10L14.1269 6.92691C14.2653 6.78846 14.3362 6.61442 14.3394 6.40481C14.3426 6.19521 14.2718 6.01797 14.1269 5.87311C13.982 5.72822 13.8064 5.65578 13.6 5.65578C13.3936 5.65578 13.2179 5.72822 13.0731 5.87311L9.99998 8.94618L6.92688 5.87311C6.78842 5.73464 6.61439 5.66381 6.40478 5.66061C6.19518 5.65739 6.01794 5.72822 5.87308 5.87311C5.72819 6.01797 5.65575 6.19361 5.65575 6.40001C5.65575 6.60641 5.72819 6.78204 5.87308 6.92691L8.94615 10L5.87308 13.0731C5.73461 13.2116 5.66378 13.3856 5.66058 13.5952C5.65736 13.8048 5.72819 13.982 5.87308 14.1269C6.01794 14.2718 6.19358 14.3442 6.39998 14.3442C6.60638 14.3442 6.78201 14.2718 6.92688 14.1269L9.99998 11.0538ZM10.0016 19.5C8.68772 19.5 7.45268 19.2506 6.29655 18.752C5.1404 18.2533 4.13472 17.5766 3.2795 16.7217C2.42427 15.8669 1.74721 14.8616 1.24833 13.706C0.749442 12.5504 0.5 11.3156 0.5 10.0017C0.5 8.68775 0.749334 7.45271 1.248 6.29658C1.74667 5.14043 2.42342 4.13475 3.27825 3.27953C4.1331 2.4243 5.13834 1.74724 6.29398 1.24836C7.44959 0.749473 8.68437 0.500031 9.9983 0.500031C11.3122 0.500031 12.5473 0.749365 13.7034 1.24803C14.8596 1.7467 15.8652 2.42345 16.7205 3.27828C17.5757 4.13313 18.2527 5.13837 18.7516 6.29401C19.2505 7.44962 19.5 8.6844 19.5 9.99833C19.5 11.3123 19.2506 12.5473 18.752 13.7034C18.2533 14.8596 17.5765 15.8653 16.7217 16.7205C15.8669 17.5757 14.8616 18.2528 13.706 18.7517C12.5504 19.2505 11.3156 19.5 10.0016 19.5Z"
            fill="#000000"
        />
    </IconWrapper>
)
