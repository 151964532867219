import { useCallback, useContext, useMemo, JSX, useState, MouseEvent, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'

import { AccountIcon } from '@components/icons/AccountIcon'
import CategoryTabs from '@components/CategoryTabs'
import Feed from '@components/feed/Feed'
import Settings from '@components/pages/Settings'
import Search from '@components/Search'
import { SettingsIcon } from '@components/icons/SettingsIcon'
import { ExternalLinkIcon } from '@components/icons/ExternalLinkIcon'

import { COLORS } from '@styles/Colors'

import FLCloudLogo from '@components/ui-components/icons/fl-cloud-logo'
import { default as InstallFilters } from '@components/sidebar/Filters'

import { AppStoreContext } from '@store/RootStore'
import Sorting from '@components/Sorting'
import { Notification } from '@components/ui-components/Notification'
import { IDerivedIconProps } from '@components/icons/IconWrapper'
import TiersButtons from '@components/feed/TiersButtons'
import PluginSidePanel from '@components/feed/PluginSideBar'
import { ModalType } from '@constants/types'
import ModalConfirmUninstall from '@components/modal/ModalConfirmUninstall'
import ModalUpdateAvailable from '@components/modal/ModalUpdateAvailable'
import ModalConfirmLogout from '@components/modal/ModalConfirmLogout'
import ModalOverlay from '@components/modal/ModalOverlay'
import ModalRetrieveLicenceCode from '@components/modal/ModalRetrieveLicenceCode'
import ModalConfirmClose from '@components/modal/ModalConfirmClose'

import { openExternalUrl } from '@src/interop'
import { Config } from '@constants/url'

import { FOOTER_HEIGHT } from '@constants/dimensions'
import ModalOptIn from '@components/modal/ModalOptIn'
import ModalCreditsAndAcknowledgements from '@components/modal/ModalCreditsAndAcknowledgements'
import TagsFilter from '@components/feed/TagsFilter'
import { InstallFilter } from '@constants/filters'
import ModalInstallDependencies from '@components/modal/ModalInstallDependencies'

type IAccountMenuItem = {
    id: string
    title: string
    isActive: boolean
    Icon: (props: IDerivedIconProps) => JSX.Element
    onClick: () => void
}

export enum Screens {
    FEED,
    SETTINGS,
}

export const Home = () => {
    const appStore = useContext(AppStoreContext)
    const { notifications, modal: modalStore, feed: feedStore, license: licenseStore, auth: authStore, version, checkForUpdate, scale } = appStore
    const { count: notificationCount, notification, dismissCurrentNotification } = notifications
    const { activeModal } = modalStore
    const {
        setActiveInstallStatus,
        setActiveCategory,
        availableTags,
        isViewingCategoryAll,
        selectedInstallStatus,
        updateAllPlugins,
        areUpdatesAvailable,
    } = feedStore
    const [screen, setScreen] = useState(Screens.FEED)
    const [headerHeight, setHeaderHeight] = useState<number>(0)
    const [searchHeight, setSearchHeight] = useState<number>(0)
    const headerRef = useRef(null)

    const updateLicensesAndAccountInfo = useCallback(() => {
        licenseStore.updateLicenses()
        authStore.updateAccountInfo()
    }, [])

    const onSetScreen = useCallback((newScreen = Screens.FEED) => {
        return () => {
            if (newScreen === Screens.SETTINGS && screen !== Screens.SETTINGS) {
                updateLicensesAndAccountInfo()
            }

            setScreen(newScreen)
        }
    }, [])

    // TODO: onClick handling for account menu
    const accountMenuItems: IAccountMenuItem[] = useMemo(() => {
        return [
            {
                id: 'settings',
                title: 'Settings',
                Icon: SettingsIcon,
                onClick: onSetScreen(screen === Screens.SETTINGS ? Screens.FEED : Screens.SETTINGS),
                isActive: screen === Screens.SETTINGS,
            },
            {
                id: 'account',
                title: 'Account',
                isActive: false,
                Icon: ExternalLinkIcon,
                onClick: () => {
                    return openExternalUrl(Config.externalUrls.flCloudAccount, '')
                },
            },
            {
                id: 'sign-out',
                title: 'Sign out',
                isActive: false,
                Icon: AccountIcon,
                onClick: () => {
                    return modalStore.showModal(ModalType.CONFIRM_LOGOUT)
                },
            },
        ]
    }, [screen])

    const accountMenu = accountMenuItems.map(({ id, title, isActive, Icon, onClick }) => {
        return (
            <div
                id={id}
                key={id}
                className={`flp-side-menu text-11px hover:flp-side-menu-hover gap-10px ${isActive ? 'text-flp-white' : ''}`}
                onClick={onClick}>
                <div>
                    <Icon
                        className="flp-icon h-14px w-14px"
                        style={{
                            '--icon-fill-color': isActive ? COLORS['flp-white'] : (COLORS['flp-grey'] as string),
                            '--icon-fill-hover-color': isActive ? COLORS['flp-white'] : (COLORS['flp-white'] as string),
                        }}
                    />
                </div>
                <div>{title}</div>
            </div>
        )
    })

    const onLogoClick = useCallback((event: MouseEvent<HTMLElement>) => {
        event.preventDefault()
        setActiveInstallStatus(null)
        setActiveCategory(null)
        setScreen(Screens.FEED)
    }, [])

    useEffect(() => {
        // Resize observer to detect when the header changes height
        // This is because of the fixed header with a dynamic height due to the tiers buttons
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                if (!headerRef.current) return
                setHeaderHeight(entry.target.getBoundingClientRect().height)

                const searchElement = document.getElementById('feed-search')
                if (!searchElement) return
                setSearchHeight(searchElement.getBoundingClientRect().height)
            }
        })

        if (!headerRef.current) return

        resizeObserver.observe(headerRef.current!)

        return () => {
            if (headerRef.current) {
                resizeObserver.unobserve(headerRef.current)
            }
            resizeObserver.disconnect()
        }
    }, [])

    const offsetHeight = useMemo(() => {
        return headerHeight + FOOTER_HEIGHT
    }, [headerHeight])

    const onClickVersionCheck = useCallback(() => {
        checkForUpdate()
    }, [])

    const handleUpdateAllClick = useCallback(() => {
        updateAllPlugins()
    }, [])

    return (
        <div className="w-full h-full relative">
            {activeModal === ModalType.UI_OVERLAY && <ModalOverlay />}
            {activeModal === ModalType.CONFIRM_UNINSTALL && <ModalConfirmUninstall />}
            {activeModal === ModalType.UPDATE_PROMPT && <ModalUpdateAvailable />}
            {activeModal === ModalType.CONFIRM_LOGOUT && <ModalConfirmLogout />}
            {activeModal === ModalType.CONFIRM_CLOSE_APP && <ModalConfirmClose />}
            {activeModal === ModalType.RETRIEVE_LICENCE_CODE && <ModalRetrieveLicenceCode />}
            {activeModal === ModalType.THIRD_PARTY_OPT_IN && <ModalOptIn />}
            {activeModal === ModalType.CREDITS_AND_ACKNOWLEDGEMENTS && <ModalCreditsAndAcknowledgements />}
            {activeModal === ModalType.REQUIRES_DEPENDENCIES_INSTALL && <ModalInstallDependencies />}

            <div className="flp-main-layout">
                <Notification notification={notification} dismiss={dismissCurrentNotification} forceDismiss={notificationCount > 1} scale={scale} />
                {/* Sidebar */}
                <section className="flp-sidebar">
                    <div className="pt-15px pb-10px">
                        <div
                            id="fl-cloud-logo"
                            className="flex flex-col justify-center"
                            onClick={onLogoClick}
                            style={{
                                // Keep logo and search bar in sync
                                ...(searchHeight ? { height: `${searchHeight}px` } : {}),
                            }}>
                            <FLCloudLogo className="w-120px h-26px select-none cursor-pointer" />
                        </div>
                        <InstallFilters isFiltering={screen === Screens.FEED} onChangeFilter={onSetScreen(Screens.FEED)} />
                    </div>
                    {/* Footer */}
                    <div className="flex flex-col gap-[20px]">{accountMenu}</div>
                </section>

                {/* Body */}
                <section className="flp-body grow">
                    {screen === Screens.SETTINGS && <div />}
                    {screen === Screens.FEED && (
                        <div id="feed-header" className="pt-15px pb-24px" ref={headerRef}>
                            <div id="feed-search" className="flex">
                                <div className="grow flex flex-col gap-10px justify-center">
                                    <Search />
                                </div>
                                <div className="flex flex-col justify-center gap-2 w-175px px-15px">
                                    <TiersButtons />
                                </div>
                            </div>
                            <div className="flex pt-16px">
                                <CategoryTabs />
                                <div className="relative w-145px text-center justify-center mr-15px">
                                    <Sorting />
                                </div>
                                {selectedInstallStatus === InstallFilter.UPDATES && areUpdatesAvailable && (
                                    <div className="relative w-145px text-center justify-center mr-15px">
                                        <div
                                            className="flp-button group !h-27px bg-flp-button-yellow-background border-flp-button-yellow-background-highlight text-flp-button-yellow-text hover:bg-flp-button-yellow-background-highlight hover:border-transparent absolute left-0 top-[50%] py-2 px-3 tracking-wider w-full text-11px"
                                            onClick={handleUpdateAllClick}>
                                            Update all
                                        </div>
                                    </div>
                                )}
                            </div>
                            {availableTags.length > 0 && !isViewingCategoryAll && <TagsFilter />}
                        </div>
                    )}

                    {/* Content */}
                    <div className="flex flex-col grow flex-1 justify-between">
                        {screen === Screens.FEED && (
                            <>
                                <div id="feed" style={{ height: `calc(100vh - ${offsetHeight}px)` }}>
                                    <Feed />
                                    <PluginSidePanel />
                                </div>
                                <div className="flex flex-col justify-center text-right pb-0.5 pr-4">
                                    <span className="text-8px hover:underline cursor-pointer" onClick={onClickVersionCheck}>
                                        v {version}
                                    </span>
                                </div>
                            </>
                        )}
                        {screen === Screens.SETTINGS && (
                            <div id="feed" className="h-screen">
                                <Settings />
                            </div>
                        )}
                    </div>

                    <div />
                </section>
            </div>
        </div>
    )
}

export default observer(Home)
