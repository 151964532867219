import { OfflineIcon } from '@components/icons/OfflineIcon'
import FLCloudLogo from '@components/ui-components/icons/fl-cloud-logo'
import { useContext, useEffect } from 'react'
import { AppStoreContext, ConnectionStatus } from '@store/RootStore'
import { checkInternetConnectivity } from '@utils/checkConnectivity'

export const Offline = () => {
    const appStore = useContext(AppStoreContext)
    const { setConnectionStatus, connectionStatus } = appStore

    useEffect(() => {
        let whenOffLineCheckInterval: ReturnType<typeof setInterval> | null = null

        if (connectionStatus === ConnectionStatus.OFFLINE) {
            whenOffLineCheckInterval = setInterval(() => {
                checkInternetConnectivity(setConnectionStatus)
            }, 1000)
        }

        return () => {
            if (whenOffLineCheckInterval) {
                clearTimeout(whenOffLineCheckInterval)
            }
        }
    }, [connectionStatus, stop])

    return (
        <div className="flp-main-layout flp-flex-centered flex-col absolute top-0 left-0 w-full h-full z-50 bg-gradient-to-t from-black from-0 to-flp-primary-black to-40% ">
            <FLCloudLogo className="w-235px opacity-50 mb-10px" />
            <h1 className="text-25px font-gilroyLight tracking-wide">Instruments and Effects</h1>
            <OfflineIcon className="flp-icon w-40px h-40px mt-12px mb-5px fill-white" />
            <p className="font-gilroyMedium text-13px text-center tracking-wider text-white leading-[1.6]">
                We can’t detect an internet connection.
                <br />
                You need to be online to use this application.
            </p>
        </div>
    )
}
