import { useCallback, useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { useAppStoreContext } from '@store/RootStore'
import ModalWrapper from '@components/modal/ModalWrapper'
import { openExternalUrl } from '@src/interop'
import { copyToClipboard } from '@utils/misc'
import { ExternalLinkIcon } from '@components/icons/ExternalLinkIcon'
import { COLORS } from '@styles/Colors'

import { formatGTMEvent, GTMEvent, IPluginPackageEvent } from '@utils/gtm-event'
import ENV from '@constants/env'
import TagManager from 'react-gtm-module'

export const ModalRetrieveLicenceCode = () => {
    const { modal: modalStore, feed: feedStore, gtmInitialized, scale } = useAppStoreContext()
    const [codeJustCopied, setCodeJustCopied] = useState(false)
    const { data } = modalStore

    const plugin = useMemo(() => {
        return feedStore.plugins.get(data.pluginAppId)
    }, [data.pluginAppId])

    // TODO might cause early return problems
    if (!plugin) return

    const confirmClick = useCallback(async () => {
        if (!plugin) return

        const copyToClipboardCallback = () => {
            setCodeJustCopied(true)

            setTimeout(() => {
                setCodeJustCopied(false)
            }, 3000)
        }

        if (plugin.licenceCode) {
            copyToClipboard(plugin.licenceCode, copyToClipboardCallback)

            return
        }

        const licenceCode = await plugin.fetchLicenceCode()

        if (!licenceCode) return

        if (ENV.VITE_GOOGLE_TAG_MANAGER && gtmInitialized) {
            const gtmRetrieveLicenceCodeArgs = formatGTMEvent(GTMEvent.PLUGIN_RETRIEVE_LICENCE_CODE, {
                pluginAppId: plugin.appId,
                pluginName: plugin.name,
            } as Partial<IPluginPackageEvent>)
            TagManager.dataLayer({ dataLayer: gtmRetrieveLicenceCodeArgs.dataLayer })
        }

        copyToClipboard(licenceCode, copyToClipboardCallback)
    }, [plugin])

    // TODO: Links here are hard-coded, but will need changing if other third parties use the licence code retrieval install method
    const urls = useMemo(() => {
        // TODO: Confirm what these URLs should be...
        return [
            {
                title: 'Open Native Access web page',
                url: 'https://www.native-instruments.com/en/specials/native-access-2/',
                Icon: ExternalLinkIcon,
            },
            {
                title: 'Get more help',
                url: 'https://support.native-instruments.com/hc/en-us/articles/4697682604049-Native-Access-2-FAQ',
                Icon: ExternalLinkIcon,
            },
        ]
    }, [])

    const openURLCallback = useCallback((url: string) => {
        return () => {
            openExternalUrl(url, '')
        }
    }, [])

    return (
        <ModalWrapper>
            <div className="text-center text-flp-text">
                <h1 className="flp-header text-flp-text">Free Native Instruments plugins</h1>
                <div className="flp-title text-flp-text py-4 text-12px">
                    {plugin.name} is installed via Native Access. <u>Copy your serial number to the clipboard using the button below.</u> Add it to
                    your account in Native Access to download and authenticate the plugin
                </div>
            </div>
            <div className="flex gap-10 pt-4 justify-center">
                <div
                    id="button-confirm-retrieve-code"
                    className="flp-button group bg-flp-button-yellow-background border-flp-button-yellow-background-highlight text-flp-button-yellow-text hover:bg-flp-button-yellow-background-highlight hover:border-transparent !w-auto"
                    onClick={confirmClick}>
                    {!codeJustCopied && (plugin.licenceCode ? `S/N ${plugin.licenceCode}` : 'Get licence code')}
                    {codeJustCopied && 'Copied to clipboard!'}
                </div>
            </div>
            <div>
                <ul id="links-list" className="list-disc text-12px mt-15px">
                    {urls.map(({ title, url, Icon }) => {
                        return (
                            <li key={url} className="my-7px last:mb-0">
                                <div
                                    className="inline-flex items-center gap-2 text-flp-text hover:text-white cursor-pointer p-5px"
                                    onClick={openURLCallback(url)}>
                                    <Icon
                                        className="flp-icon"
                                        style={{
                                            '--icon-fill-color': COLORS['flp-grey'] as string,
                                            '--icon-fill-hover-color': COLORS['flp-white'] as string,
                                            width: `${20 * scale}px`,
                                            height: `${20 * scale}px`,
                                        }}
                                    />
                                    <p>{title}</p>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </ModalWrapper>
    )
}

export default observer(ModalRetrieveLicenceCode)
