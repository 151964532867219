import { useCallback, useEffect, useMemo, useState } from 'react'
import FloatingInfo from './FloatingInfo'
import { EditDirectoryOffIcon } from './icons/EditDirectoryOffIcon'
import { COLORS } from '@styles/Colors'
import { EditDirectoryOnIcon } from './icons/EditDirectoryOnIcon'
import { getSoundBanksDir, selectSoundBanksDir, isSoundBanksDirDefault, resetSoundBanksDir, openFullDiskAccess } from '@src/interop'

import { useAppStoreContext } from '@store/RootStore'
import { observer } from 'mobx-react'
import { ModalType } from '@constants/types'
import { formatGTMEvent, GTMEvent, ISoundbankDirectoryEvent } from '@utils/gtm-event'
import TagManager from 'react-gtm-module'

import ENV from '@constants/env'
import { getPlatform } from '@utils/misc'

const SoundsBankSettings = () => {
    const { soundBanksDirectory, setSoundBanksDirectory, isMac, modal: modalStore, gtmInitialized, scale } = useAppStoreContext()
    const [isDefaultPath, setIsDefaultPath] = useState(true)
    const iconClassName = 'flp-icon inline-block -mt-1'
    const iconStyle = {
        '--icon-fill-color': COLORS['flp-grey'],
        '--icon-fill-hover-color': COLORS['flp-white'] as string,
        width: `${20 * scale}px`,
        height: `${20 * scale}px`,
    }

    const togglerOff = useMemo(() => {
        return <EditDirectoryOffIcon className={iconClassName} style={iconStyle} />
    }, [scale])

    const togglerOn = useMemo(() => {
        return <EditDirectoryOnIcon className={iconClassName} style={iconStyle} />
    }, [scale])

    const resetSoundBanksDirectoryHandler = useCallback(async () => {
        await resetSoundBanksDir()

        if (ENV.VITE_GOOGLE_TAG_MANAGER && gtmInitialized) {
            const gtmSoundbankDirectoryArgs = formatGTMEvent(GTMEvent.SOUNDBANKS_DIRECTORY_CHANGED, {
                path: 'Default',
                platform: getPlatform(),
            } as Partial<ISoundbankDirectoryEvent>)
            TagManager.dataLayer({ dataLayer: gtmSoundbankDirectoryArgs.dataLayer })
        }

        await getAndSetSoundBanksDirectory()
        setIsDefaultPath(true)
    }, [])

    const getAndSetSoundBanksDirectory = useCallback(async () => {
        const path = await getSoundBanksDir()
        setSoundBanksDirectory(path)
    }, [])

    useEffect(() => {
        getAndSetSoundBanksDirectory()
    }, [])

    useEffect(() => {
        isSoundBanksDirDefault().then(isDefault => {
            setIsDefaultPath(isDefault)
        })
    }, [soundBanksDirectory])

    const openDirectoryPicker = useCallback(async () => {
        modalStore.showModal(ModalType.UI_OVERLAY, {}, false)
        selectSoundBanksDir()
    }, [])

    const openDiskPreferencesHandler = useCallback(() => {
        openFullDiskAccess()
    }, [])

    const showSoundbanksHelp = useMemo(() => {
        return isMac && !isDefaultPath && soundBanksDirectory?.startsWith('/Volumes/')
    }, [isMac, isDefaultPath, soundBanksDirectory])

    const externalDriveWarning = useMemo(() => {
        return (
            <p className="text-flp-yellow">
                You have selected an external drive and must{' '}
                <span className="hover:underline font-semibold cursor-pointer" onClick={openDiskPreferencesHandler}>
                    Enable Full Disk Access
                </span>{' '}
                for <span className="font-semibold">FL Cloud Plugins</span> to use this space.
            </p>
        )
    }, [openDiskPreferencesHandler])

    return (
        <>
            <h2 className="flp-subtitle">Soundbanks Install Directory</h2>
            <div className="relative flex flex-row items-start gap-3 flex-wrap">
                <p className="whitespace-nowrap overflow-hidden overflow-ellipsis max-w-[80%]">{isDefaultPath ? 'Default' : soundBanksDirectory}</p>
                <FloatingInfo togglerOff={togglerOff} togglerOn={togglerOn} className="text-11px left-0 top-24px w-[12.5rem] bg-flp-primary-black">
                    <p className="font-gilroySemibold text-flp-grey mb-5px">Edit Soundbanks install directory</p>
                    <div className="font-gilroyMedium text-flp-info flex flex-col flex-wrap gap-3px mb-3px">
                        <p className="leading-tight">
                            Changing this location will change where Soundbanks files are stored and could effect plugin functionality.
                        </p>
                        {showSoundbanksHelp && externalDriveWarning}
                        {showSoundbanksHelp && (
                            <a onClick={openDiskPreferencesHandler} className="block underline cursor-pointer hover:text-flp-info-highlight">
                                Enable Full Disk Access
                            </a>
                        )}
                        <a onClick={openDirectoryPicker} className="block underline cursor-pointer hover:text-flp-info-highlight">
                            Browse to set new location
                        </a>
                        <a onClick={resetSoundBanksDirectoryHandler} className="block underline cursor-pointer hover:text-flp-info-highlight">
                            Reset to default
                        </a>
                    </div>
                </FloatingInfo>
            </div>
            {showSoundbanksHelp && externalDriveWarning}
        </>
    )
}

export default observer(SoundsBankSettings)
