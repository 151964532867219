import { IconWrapper, IDerivedIconProps } from '@components/icons/IconWrapper'

export const MagnifyingGlassIcon = (props: IDerivedIconProps) => (
    <IconWrapper {...props} viewBox="0 0 12 12">
        <path
            d="M11.6316 10.3776L8.65034 7.44571C9.018 6.97497 9.27769 6.43141 9.41155 5.85244C9.54541 5.27347 9.55026 4.67283 9.42576 4.09184C9.29314 3.26971 8.91716 2.50434 8.34485 1.8915C7.77255 1.27865 7.02934 0.845542 6.20823 0.646367C5.96466 0.574531 5.71105 0.547592 5.46018 0.5H4.6802C4.47038 0.538612 4.25874 0.569143 4.04892 0.614939C3.18331 0.815102 2.39874 1.26658 1.7969 1.91086C1.19506 2.55514 0.803708 3.36251 0.673564 4.22833C0.661705 4.31812 0.644372 4.39894 0.629776 4.48514V5.2529C0.639301 5.28226 0.646619 5.31227 0.65167 5.34269C0.699295 5.87781 0.858615 6.3975 1.11966 6.86922C1.51727 7.6574 2.15263 8.30535 2.93877 8.72441C3.7249 9.14346 4.62339 9.31314 5.51127 9.21021C6.21943 9.16318 6.90266 8.93405 7.49269 8.54571L10.4958 11.5018L11.6316 10.3776ZM5.06335 8.15331C4.40124 8.15326 3.75411 7.95944 3.20418 7.5965C2.65425 7.23355 2.22637 6.71786 1.97491 6.11496C1.72345 5.51207 1.65977 4.8492 1.79196 4.2106C1.92416 3.57199 2.24625 2.9865 2.71732 2.52852C3.18838 2.07054 3.78714 1.76076 4.4375 1.63855C5.08785 1.51633 5.76044 1.58721 6.36978 1.84217C6.97911 2.09713 7.49768 2.52465 7.85957 3.07041C8.22147 3.61616 8.41035 4.25551 8.40221 4.90718C8.39025 5.77091 8.03406 6.59544 7.41038 7.20313C6.78671 7.81082 5.94546 8.15302 5.06791 8.156L5.06335 8.15331Z"
            fill="#000000"
        />
    </IconWrapper>
)
