const LOCAL_STORAGE_PREFIX = 'FL_PLUGINS'

export const LS_HIDE_INTRO = `${LOCAL_STORAGE_PREFIX}_HIDE_INTRO`

export const LS_SORT_ORDER = `${LOCAL_STORAGE_PREFIX}_SORT_ORDER`

export const LS_SORT_DIRECTION = `${LOCAL_STORAGE_PREFIX}_SORT_DIRECTION`

export const LS_PLUGIN_INSTALL_DATE = `${LOCAL_STORAGE_PREFIX}_PLUGIN_INSTALL_DATE`

export const LS_ZOOM_SETTING = `${LOCAL_STORAGE_PREFIX}_ZOOM_SETTING`

export const LS_APP_LAST_OPENED_DATE = `${LOCAL_STORAGE_PREFIX}_APP_LAST_OPENED_DATE`
