import { useCallback, useContext } from 'react'
import { observer } from 'mobx-react'
import { AppStoreContext } from '@store/RootStore'

export const CategoryTabs = () => {
    const appStore = useContext(AppStoreContext)
    const { feed } = appStore
    const { selectedCategory, isViewingCategoryAll, setActiveCategory, resetSelectedTagIfDoesNotExist, visibleCategories, isCategoryEnabled } = feed

    const onClick = useCallback((categoryId: string | null) => {
        return () => {
            if (categoryId && !isCategoryEnabled(categoryId)) return
            setActiveCategory(categoryId)
            resetSelectedTagIfDoesNotExist()
        }
    }, [])

    if (!visibleCategories || visibleCategories.length === 0) return

    return (
        <div className="flex grow border-b-[0.1875rem] border-flp-button-grey-background">
            <div
                className={`flex items-center flp-tab cursor-pointer
          ${isViewingCategoryAll ? 'flp-tab-active' : ''}
        `}
                onClick={onClick(null)}>
                All
            </div>

            {visibleCategories.map(({ id, name }: any) => {
                return (
                    <div
                        key={id}
                        className={`flp-tab
            ${selectedCategory === id ? 'flp-tab-active' : ''}
            ${!isCategoryEnabled(id) ? 'flp-tab-disabled' : 'cursor-pointer'}
          `}
                        onClick={onClick(id)}>
                        {name}
                    </div>
                )
            })}
        </div>
    )
}

export default observer(CategoryTabs)
