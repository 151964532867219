import { FC } from 'react'

export interface IFLCloudProps {
    className?: string
    fill?: string
    width?: number
    height?: number
}

const FLCloudLogo: FC<IFLCloudProps> = ({ className, fill = '#fff', width = 255, height = 55 }: IFLCloudProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={`0 0 ${width} ${height}`} className={className}>
        <path
            fill={fill}
            d="M31.3,27.2c-3.1-3.9-4.6-5.4-6-6.8l-3.2-7.9c3.4-1.3,8.3,0,10.9,3.6c2.6,3.7,1.9,7.9,1.3,9.6
	C33.9,27,32.7,29,31.3,27.2z"
        />
        <path fill={fill} d="M11.9,14c1.1-4.9,5.8-3.7,8.2-2.4l2.6,1h0.6l2.2,7.8c-0.4,1.2-4.6,7.6-9.7,5.9C11.6,24.9,10.8,19,11.9,14z" />
        <path fill={fill} d="M2.6,15.8c3.9-0.9,7.5-1.4,9.5-1.6l7.6-2.8C17.4,5.2,6.5,2.9,1.5,12.2C0.7,13.8-0.5,16.5,2.6,15.8z" />
        <path fill={fill} d="M29.1,1.1c-5,4.1-7,9.2-7.4,11.2l-1.7-0.7c1.4-3.8,5.5-9.7,8.9-11.6C29.4-0.2,30,0.4,29.1,1.1z" />
        <path
            fill={fill}
            d="M3.7,17.1C-2.1,29.3-1,52,6.3,54.5c5.8,2.3,20.1-12.9,23.5-26.9c-1.8-2.3-3-3.6-4-4.7l0,0
	c-0.6,0.8-1.4,1.8-2.3,2.6c-1.9,1.7-4.8,3.2-8,2.1c-2.7-0.9-4.2-3.3-4.9-5.8c-0.5-1.8-0.6-3.9-0.3-5.9C8.4,16.2,6.2,16.6,3.7,17.1z"
        />
        <path fill={fill} d="M51.7,14.4H67v3H54.7V27H67v3H54.7v15h-3V14.4z" />
        <path fill={fill} d="M73.2,14.4h3.1v27.7h11.8v3H73.2V14.4z" />
        <path
            fill={fill}
            d="M135,20.1l-2.4,1.9c-1.3-1.7-2.9-3-4.8-3.9c-1.9-0.9-3.9-1.4-6.1-1.4c-2.4,0-4.7,0.6-6.8,1.8
	c-2.1,1.2-3.7,2.7-4.9,4.7c-1.1,2-1.7,4.2-1.7,6.7c0,3.7,1.3,6.9,3.8,9.4c2.6,2.5,5.8,3.7,9.7,3.7c4.3,0,7.9-1.7,10.8-5l2.4,1.8
	c-1.5,1.9-3.4,3.5-5.7,4.5c-2.3,1.1-4.8,1.6-7.6,1.6c-5.4,0-9.6-1.8-12.7-5.4c-2.6-3-3.9-6.7-3.9-10.9c0-4.5,1.6-8.3,4.7-11.3
	c3.2-3.1,7.1-4.6,11.8-4.6c2.9,0,5.4,0.6,7.7,1.7C131.7,16.5,133.6,18.1,135,20.1z"
        />
        <path fill={fill} d="M142,14.4h3.1v27.7h11.8v3H142V14.4z" />
        <path
            fill={fill}
            d="M177.2,13.7c4.6,0,8.5,1.5,11.6,4.6c3.1,3.1,4.7,6.9,4.7,11.4c0,4.5-1.6,8.3-4.7,11.4
	c-3.1,3.1-6.9,4.7-11.4,4.7c-4.5,0-8.4-1.6-11.5-4.7c-3.1-3.1-4.7-6.9-4.7-11.3c0-2.9,0.7-5.7,2.1-8.2c1.4-2.5,3.4-4.5,5.8-5.9
	C171.7,14.4,174.3,13.7,177.2,13.7z M177.4,16.7c-2.3,0-4.4,0.6-6.4,1.8c-2,1.2-3.6,2.8-4.7,4.8c-1.1,2-1.7,4.2-1.7,6.7
	c0,3.6,1.3,6.7,3.8,9.2c2.5,2.5,5.6,3.7,9.1,3.7c2.4,0,4.6-0.6,6.6-1.7c2-1.2,3.6-2.7,4.7-4.7c1.1-2,1.7-4.2,1.7-6.7
	c0-2.4-0.6-4.6-1.7-6.6c-1.1-2-2.7-3.5-4.8-4.7C181.8,17.3,179.7,16.7,177.4,16.7z"
        />
        <path
            fill={fill}
            d="M200.6,14.4h3.1v18.5c0,2.2,0,3.6,0.1,4.1c0.2,1.2,0.5,2.2,1.1,3c0.6,0.8,1.4,1.5,2.6,2
	c1.2,0.5,2.3,0.8,3.5,0.8c1,0,2-0.2,2.9-0.6c0.9-0.4,1.7-1,2.3-1.8c0.6-0.8,1.1-1.7,1.4-2.8c0.2-0.8,0.3-2.4,0.3-4.8V14.4h3.1v18.5
	c0,2.7-0.3,5-0.8,6.6c-0.5,1.7-1.6,3.1-3.2,4.4c-1.6,1.2-3.5,1.9-5.8,1.9c-2.5,0-4.6-0.6-6.4-1.8c-1.8-1.2-2.9-2.7-3.5-4.7
	c-0.4-1.2-0.6-3.3-0.6-6.5V14.4z"
        />
        <path
            fill={fill}
            d="M229.3,45.1V14.4h6.4c4.6,0,7.9,0.4,10,1.1c3,1,5.3,2.8,7,5.4c1.7,2.6,2.5,5.6,2.5,9.2c0,3.1-0.7,5.8-2,8.1
	c-1.3,2.3-3,4.1-5.2,5.2c-2.1,1.1-5.1,1.7-8.9,1.7H229.3z M232.3,42.2h3.5c4.2,0,7.2-0.3,8.8-0.8c2.3-0.7,4.1-2.1,5.4-4
	c1.3-2,2-4.3,2-7.2c0-3-0.7-5.5-2.1-7.6c-1.4-2.1-3.4-3.5-6-4.3c-1.9-0.6-5.1-0.9-9.5-0.9h-2.2V42.2z"
        />
    </svg>
)

export default FLCloudLogo
