import { useCallback, useEffect } from 'react'

import { observer } from 'mobx-react'
import { useAppStoreContext } from '@store/RootStore'
import ModalWrapper from '@components/modal/ModalWrapper'
import { UpdateIcon } from '@components/icons/UpdateIcon'
import { COLORS } from '@styles/Colors'
import { ProgressBar } from '@components/progress-bar/ProgressBar'
import { formatBytes } from '@utils/formatting'
import { openExternalUrl } from '@src/interop'

export const ModalUpdateAvailable = () => {
    const {
        modal: modalStore,
        latestVersion,
        updateApp,
        appDownloadLatestInstallerUrl,
        cancelUpdateApp,
        installerDownloadedSize,
        isMac,
        macInstallerSize,
        winInstallerSize,
        isAppUpdating,
        setIsAppUpdating,
        setAppError,
        appError,
        scale,
    } = useAppStoreContext()

    useEffect(() => {
        return () => {
            setIsAppUpdating(false)
        }
    }, [])

    const confirmClick = useCallback(() => {
        setIsAppUpdating(true)
        // Clear app errors when attempting or retrying an update
        setAppError(null)
        updateApp()
    }, [])

    const skipClick = useCallback(() => {
        modalStore.closeModal()
    }, [])

    const cancelClick = useCallback(() => {
        cancelUpdateApp()
    }, [])

    const onClickManualDownload = useCallback(() => {
        openExternalUrl(appDownloadLatestInstallerUrl, '')
    }, [])

    const { forceUpdate } = modalStore.data

    const totalSize = (isMac ? macInstallerSize : winInstallerSize) ?? 0
    const progress = Math.round((installerDownloadedSize / totalSize) * 100)
    const byteString = `${formatBytes(installerDownloadedSize)}/${formatBytes(totalSize)}`
    const downloadProgress = byteString.padStart(18, ' ')

    return (
        <ModalWrapper>
            <div className="w-346px mx-auto text-center text-flp-text select-none">
                <UpdateIcon
                    className="flp-icon mx-auto"
                    style={{
                        '--icon-fill-color': COLORS['flp-text'],
                        '--icon-fill-hover-color': COLORS['flp-text'] as string,
                        width: `${20 * scale}px`,
                        height: `${22 * scale}px`,
                    }}
                />
                <h1 className="flp-header text-flp-text pt-4">Application Update</h1>
                {!isAppUpdating && (
                    <div className="text-16px text-flp-text py-4">
                        A new version of the FL Cloud plugins application (<span className="text-white">v {latestVersion}</span>) is available
                    </div>
                )}

                {appError && !isAppUpdating && (
                    <div className="text-16px text-flp-text py-4">
                        There was a problem with the automatic update. Please manually download and install the latest version{' '}
                        <span id="manual-download-link" className="underline cursor-pointer text-white" onClick={onClickManualDownload}>
                            here
                        </span>{' '}
                        to continue.
                    </div>
                )}
            </div>

            <div className="justify-center pt-8">
                {isAppUpdating && (
                    <div className="flp-flex-centered flex-col gap-8">
                        <div className="w-300px" id="update-progress">
                            <ProgressBar label={`Downloading... ${downloadProgress}`} progressAmount={progress} isDownload />
                        </div>
                        <div
                            id="button-cancel-update"
                            className="flp-button group bg-flp-button-grey-background text-flp-button-grey-text border-flp-button-grey-background"
                            onClick={cancelClick}>
                            Cancel
                        </div>
                    </div>
                )}

                {!isAppUpdating && (
                    <div className="flex gap-10 justify-center">
                        {!forceUpdate && (
                            <div
                                id="button-skip-update"
                                className="flp-button group bg-flp-button-grey-background text-flp-button-grey-text border-flp-button-grey-background"
                                onClick={skipClick}>
                                Skip
                            </div>
                        )}
                        <div
                            id="button-confirm-update"
                            className="flp-button group bg-flp-button-grey-background border-flp-button-grey-background text-flp-primary hover:bg-flp-button-bg-highlight hover:border-transparent"
                            onClick={confirmClick}>
                            {appError ? 'Retry' : 'Update now'}
                        </div>
                    </div>
                )}
            </div>
        </ModalWrapper>
    )
}

export default observer(ModalUpdateAvailable)
