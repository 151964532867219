import { ImageCDN } from '@utils/images-helper'
import React, { FC, useCallback, useState } from 'react'

/* Interface for the ImgWithFallback component */
export interface PictureTagProps {
    /* prop for the image URL */
    src: string | undefined
    /* prop for the alt */
    alt?: string
    /* prop for the classes */
    className?: string
    /* prop for the picture classes */
    style?: React.CSSProperties
    /**
     * @param width the width of the image in pixels, if none or 0 is provided image will be returned in original width size
     * @type number
     */
    width?: number
    /**
     * @param height the height of the image in pixels, if none or 0 is provided image will be returned in original height size
     * @type number
     */
    height?: number
    quality?: number
    crossOrigin?: string
}

/*
 * Component that shows an image with a fallback option
 * */
const ImgWithFallback: FC<PictureTagProps> = (props: PictureTagProps) => {
    const { src, className, alt, style, width, height, quality, crossOrigin } = props

    const [hasWebPFailed, setHasWebPFailed] = useState(false)

    const handleOnError = useCallback(() => {
        if (hasWebPFailed) {
            return
        }
        setHasWebPFailed(true)
    }, [])

    return (
        <img
            crossOrigin={crossOrigin === 'use-credentials' ? 'use-credentials' : 'anonymous'}
            alt={alt}
            src={ImageCDN({
                width,
                height,
                path: src ?? '',
                quality: quality ?? 100,
                format: hasWebPFailed ? 'png' : undefined,
            })}
            loading="lazy"
            onError={handleOnError}
            className={className}
            style={style}
        />
    )
}

export default ImgWithFallback
