import ENV from '@constants/env'

export enum GTMEvent {
    INIT = 'plugins_init_app',
    SET_USER_ID = 'plugins_set_user_id',
    SIGNED_IN = 'plugins_user_signed_in',
    PLUGIN_INSTALL_INITIALIZED = 'plugins_plugin_install_initialized',
    PLUGIN_INSTALL_COMPLETED = 'plugins_plugin_install_completed',
    PLUGIN_INSTALL_CANCELED = 'plugins_plugin_install_canceled',
    PLUGIN_INSTALL_RETRIED = 'plugins_plugin_install_retried',
    PLUGIN_UNINSTALL = 'plugins_plugin_uninstall',
    PLUGIN_RETRIEVE_LICENCE_CODE = 'plugins_plugin_retrieve_licence_code',
    PLUGIN_MARKETING_OPT_IN = 'plugins_plugin_marketing_opt_in',
    SOUNDBANKS_DIRECTORY_CHANGED = 'plugins_soundbank_directory_change',
    ORDER_PLUGINS_BY = 'plugins_order_plugins_by',
    MACHINE_DEAUTHORIZED = 'plugins_machine_deauthorized',
}

type IGenericEvent = {}

type IUserIDEvent = {
    amplitudeUserID: string | null
}

export type IPluginPackageEvent = {
    pluginAppId: string
    pluginName: string
    pluginVersion?: string
    pluginSize?: number
    platform?: string
}

export type IMarketingOptInEvent = {
    pluginAppId: string
    pluginName: string
    labelName: string
}

export type ISoundbankDirectoryEvent = {
    path: string
    platform?: string
}

export type ISortingEvent = {
    sortBy: string
}

export type IDeauthorizeMachineEvent = {
    machineName: string
}

export type IDownloadEvent = {
    downloadSpeed: string
}

export type IInitAppEvent = {
    deviceId: string
    logLevel: number
}

type IEventDataTypes = IGenericEvent | Partial<IPluginPackageEvent> | Partial<IUserIDEvent> | IDownloadEvent | IInitAppEvent

type IEvent = {
    gtmId: string
    auth?: string
    dataLayer: IDataLayer & IGenericEvent & IEventDataTypes
}

type IDataLayer = {
    event: GTMEvent
    environment: string
}

export const formatGTMEvent = (event: GTMEvent, eventData = {} as IEventDataTypes): IEvent => {
    return {
        gtmId: ENV.VITE_GOOGLE_TAG_MANAGER ?? '',
        auth: ENV.VITE_GOOGLE_TAG_MANAGER_AUTH,
        dataLayer: {
            event,
            environment: ENV.VITE_ENVIRONMENT ?? '',
            ...eventData,
        },
    }
}
