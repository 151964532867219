/* eslint-disable prettier/prettier */
import { observer } from 'mobx-react'
import { ErrorIcon } from '@components/icons/ErrorIcon'
import { TickIcon } from '@components/icons/TickIcon'
import { AlertIcon } from '@components/icons/AlertIcon'
import { COLORS } from '@styles/Colors'
import ThreeDotMenu from '@components/ThreeDotMenu'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { PluginProgressBar } from '@components/feed/PluginProgressBar'
import { useAppStoreContext } from '@store/RootStore'
import { NotificationType } from '@constants/types'
import PluginActionButton from '@components/PluginActionButton'
import { IFLPlugin } from '@store/models/FLPlugin'
import ImgWithFallback from '@components/general/img-with-fallback'
import { copyToClipboard } from '@utils/misc'
import { BASE_FONT_SIZE } from '@constants/dimensions'
import { LS_APP_LAST_OPENED_DATE } from '@constants/storage'

type IPluginRowProps = {
    plugin: IFLPlugin
}

export const PluginRow = ({ plugin }: IPluginRowProps) => {
    const { notifications, feed: feedStore, scale } = useAppStoreContext()
    const { selectedPlugin, setSelectedPlugin, filterByNewBadge } = feedStore
    const [isHovered, setIsHovered] = useState(false)
    const [dropDownState, setDropDownState] = useState(false)
    const [rowElement, setRowElement] = useState<null | HTMLDivElement>(null)

    const {
        id,
        appId,
        name,
        label,
        platformPackage,
        pluginVersion,
        pluginSize,
        isActive,
        isInstalled,
        isErrored,
        isErroredOnInstall,
        isBroken,
        isVersionOutdated,
        areDependenciesOutdated,
        error,
        pluginOptions,
        isLicenseRequired,
        showThreeDotMenu,
        isWithinNewReleaseWindow,
        releaseDate,
    } = plugin

    useEffect(() => {
        if (!rowElement) return

        const mouseEnter = () => {
            setIsHovered(true)
        }

        const mouseLeave = () => {
            if (dropDownState) return
            setIsHovered(false)
        }

        rowElement.addEventListener('mouseenter', mouseEnter)
        rowElement.addEventListener('mouseleave', mouseLeave)

        return () => {
            rowElement.removeEventListener('mouseenter', mouseEnter)
            rowElement.removeEventListener('mouseleave', mouseLeave)
        }
    }, [rowElement, dropDownState])

    const ref = useCallback((node: null | HTMLDivElement) => {
        if (node === null) {
            setRowElement(null)
            return
        }

        setRowElement(node)
    }, [])

    const copyToClipboardHandler = () => {
        if (!error) return

        const callback = () => {
            notifications.addNotification(NotificationType.SUCCESS, 'Error report copied to clipboard')
        }

        copyToClipboard(error, callback)
    }

    let statusIcon = null

    if (isErrored) {
        statusIcon = (
            <ErrorIcon
                className="flp-icon mx-auto"
                style={{
                    '--icon-fill-color': COLORS['flp-red'],
                    '--icon-fill-hover-color': COLORS['flp-red'] as string,
                    width: `${20 * scale}px`,
                    height: `${20 * scale}px`,
                }}
            />
        )
    } else if (isBroken) {
        statusIcon = (
            <AlertIcon
                className="flp-icon mx-auto"
                style={{
                    '--icon-fill-color': COLORS['flp-yellow'],
                    '--icon-fill-hover-color': COLORS['flp-yellow'] as string,
                    width: `${20 * scale}px`,
                    height: `${20 * scale}px`,
                }}
            />
        )
    } else if (isInstalled) {
        statusIcon = (
            <TickIcon
                className="flp-icon mx-auto"
                style={{
                    '--icon-fill-color': COLORS['flp-primary'],
                    '--icon-fill-hover-color': COLORS['flp-primary'] as string,
                    width: `${20 * scale}px`,
                    height: `${20 * scale}px`,
                }}
            />
        )
    }

    const togglePanel = useCallback(() => {
        setSelectedPlugin(selectedPlugin && appId === selectedPlugin ? null : appId)
    }, [appId, selectedPlugin])

    const showNewBadge = useMemo(() => {
        if (!isWithinNewReleaseWindow || isInstalled) return false

        const lastOpenedDate = localStorage.getItem(LS_APP_LAST_OPENED_DATE)

        if (!lastOpenedDate || !releaseDate) return false

        const dateAppLastOpened = new Date(lastOpenedDate)
        const pluginReleaseDate = new Date(releaseDate)

        if (!dateAppLastOpened || !pluginReleaseDate) return false

        // The plugin is released within the new release window, released after the last time the app was opened and is not currently installed
        return pluginReleaseDate.getTime() >= dateAppLastOpened.getTime()
    }, [isWithinNewReleaseWindow, releaseDate, isInstalled])

    return (
        <div ref={ref} key={id} className="flp-plugin-row mr-15px pr-8px">
            <div className="flp-plugin-row-hover-area" />
            <div className="col-span-1 flp-plugin-row-clickable">
                <div className="relative rounded-5px overflow-hidden h-60px" onClick={togglePanel}>
                    <ImgWithFallback
                        src={plugin.imageUrl ?? ''}
                        height={7.25 * BASE_FONT_SIZE}
                        width={14.375 * BASE_FONT_SIZE}
                        className="flp-absolute-centered w-full"
                        alt={plugin?.name ?? 'Plugin'}
                    />
                </div>
            </div>
            <div className="col-span-1 text-center">{statusIcon}</div>
            <div className="flp-plugin-row-clickable col-span-1 select-none group pr-2" onClick={togglePanel}>
                <h3 className="plugin-name capitalize text-12px font-gilroyMedium group-hover:text-white">{name}</h3>
                <p className="plugin-author text-10px tracking-wider group-hover:text-white">{label.name}</p>
            </div>
            {/* Plugin is in the download phase of installing, show the progress bar */}
            <PluginProgressBar plugin={plugin} />

            {isErrored && (
                <div
                    onClick={copyToClipboardHandler}
                    className="relative col-span-2 select-none plugin-version text-10px tracking-wider whitespace-nowrap"
                >
                    <span className="text-flp-red py-1 rounded-md hover:text-flp-red-highlight hover:bg-[#14242c] hover:cursor-pointer">
                        {isErroredOnInstall ? 'Installation Error' : 'Uninstallation Error'}
                    </span>
                </div>
            )}
            {isBroken && (
                <div
                    className="relative col-span-2 select-none plugin-version text-10px tracking-wider whitespace-nowrap"
                    onClick={copyToClipboardHandler}
                >
                    <span className="text-flp-yellow py-1 rounded-md hover:text-flp-yellow-highlight hover:bg-[#14242c] hover:cursor-pointer">
                        Installation Error
                    </span>
                </div>
            )}
            {!isActive && !isErrored && !isBroken && (
                <>
                    <div className="col-span-1 select-none plugin-version text-10px tracking-wider whitespace-nowrap z-10">
                        {pluginVersion}
                        {showNewBadge && (
                            <div className="text-flp-new-text cursor-pointer" onClick={filterByNewBadge}>
                                NEW
                            </div>
                        )}
                        {isVersionOutdated && platformPackage && (
                            <div className="text-flp-button-yellow-text">{platformPackage.version} available!</div>
                        )}
                        {!isVersionOutdated && isInstalled && areDependenciesOutdated && platformPackage && (
                            <div className="text-flp-button-yellow-text">Update available!</div>
                        )}
                    </div>
                    <div className="col-span-1 select-none plugin-size text-10px tracking-wider text-center whitespace-nowrap">{pluginSize}</div>
                </>
            )}

            <div className="col-span-1 plugin-install-status -mr-15px">
                <div className="flex justify-end items-center relative z-10">
                    {!platformPackage && !isLicenseRequired && <div className="text-10px tracking-wider">Platform not supported</div>}
                    <PluginActionButton plugin={plugin} isHovered={isHovered} />
                    {/** TODO add types **/}
                    {showThreeDotMenu && isHovered && <ThreeDotMenu options={pluginOptions as any} toggleOpenCallback={setDropDownState} />}
                </div>
            </div>
        </div>
    )
}

export default observer(PluginRow)
