import { IconWrapper, IDerivedIconProps } from '@components/icons/IconWrapper'

export const TriangleIcon = (props: IDerivedIconProps) => (
  <IconWrapper {...props} viewBox="0 0 8 8">
    <path
      d="M4.43301 7.25C4.24056 7.58333 3.75944 7.58333 3.56699 7.25L0.102885 1.25C-0.0895659 0.916668 0.150997 0.500001 0.535897 0.500001L7.4641 0.5C7.849 0.5 8.08956 0.916667 7.89711 1.25L4.43301 7.25Z"
      fill="#000000"
    />
  </IconWrapper>
)
