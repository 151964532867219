import { ModalType, Platform } from '@constants/types'
import { formatBytes } from '@utils/formatting'
import { types, getRoot, flow, Instance } from 'mobx-state-tree'
import { install, cancelInstall, uninstall, verifyInstall } from '@src/interop'
import { getPlatform } from '@utils/misc'
import { observable } from 'mobx'

import TagManager from 'react-gtm-module'
import { formatGTMEvent, GTMEvent, IPluginPackageEvent } from '@utils/gtm-event'

import ENV from '@constants/env'
import { LS_PLUGIN_INSTALL_DATE } from '@constants/storage'
import { Tiers, Tier, ITier } from '@store/models/Tier'
import ApiInstance from '@utils/api'
import { InstallFilter } from '@constants/filters'
import { NEW_PLUGIN_LIMIT_DAYS } from '@constants/plugin'
export enum InstallState {
    AVAILABLE = 'available',
    INSTALLING = 'installing',
    INSTALLING_DEPENDENCIES = 'installing_dependencies',
    INSTALLING_AS_DEPENDENCY = 'installing_as_dependency',
    FINALIZING_INSTALL = 'finalizing_install',
    FINALIZING_INSTALL_AS_DEPENDENCY = 'finalizing_install_as_dependency',
    FIXING_DEPENDENCIES = 'fixing_dependencies',
    UPDATING_DEPENDENCIES = 'updating_dependencies',
    INSTALLED = 'installed',
    REMOVING = 'removing',
    ERRORED_ON_INSTALL = 'errored_on_install',
    ERRORED_ON_UNINSTALL = 'errored_on_uninstall',
    BROKEN = 'broken',
    MISSING_DEPENDENCIES = 'missing_dependencies',
    VERIFYING_INSTALL = 'verifying_install',
    INIT = 'init',
}

export enum InstallMode {
    ONLY_MISSING_DEPENDENCIES = 'only_missing_dependencies',
    FULL_INSTALL = 'full_install',
    UPDATE = 'update',
    UPDATE_DEPENDENCIES = 'update_dependencies',
}

export const PluginDependency = types.model('PluginDependency', {
    appId: types.optional(types.string, ''),
    version: types.optional(types.string, ''),
})

export type IFLPluginDependency = Instance<typeof PluginDependency>

export const PackagePlatform = types.model('Platform', {
    appId: types.optional(types.string, ''),
    developer: types.optional(types.string, ''),
    platform: types.optional(types.string, ''),
    size: types.optional(types.number, 0),
    version: types.optional(types.string, ''),
    dependencies: types.optional(types.array(types.maybeNull(PluginDependency)), []),
})

export type IIdentifier = Instance<typeof Identifier>

export const Identifier = types.model('Identifier', {
    id: types.optional(types.string, ''),
    name: types.optional(types.string, ''),
})

export const Label = types.model('Label', {
    id: types.optional(types.string, ''),
    slug: types.optional(types.string, ''),
    name: types.optional(types.string, ''),
    imageUrl: types.optional(types.string, ''),
    requiredConsents: types.optional(types.array(types.string), []),
    contactUrl: types.maybeNull(types.string),
})

export type IFLPlugin = Instance<typeof FLPlugin>

export const FLPlugin = observable(
    types
        .model('Plugin', {
            id: types.optional(types.string, ''),
            name: types.optional(types.string, ''),
            slug: types.optional(types.string, ''),
            appId: types.optional(types.string, ''),
            imageUrl: types.optional(types.string, ''),
            imageUrlWide: types.optional(types.string, ''),
            descriptionShort: types.optional(types.string, ''),
            descriptionLong: types.optional(types.string, ''),
            releaseDate: types.optional(types.string, ''),
            label: Label,
            macVersion: types.maybeNull(PackagePlatform),
            winVersion: types.maybeNull(PackagePlatform),
            installedVersion: types.maybeNull(types.string),
            tiers: types.optional(types.array(Tier), []),
            tags: types.optional(types.array(types.string), []),
            tagIds: types.optional(types.array(types.string), []),
            categories: types.maybeNull(types.array(Identifier)),
            shouldNotBeIncluded: types.optional(types.boolean, false),
            isHiddenFromCatalog: types.optional(types.boolean, false),
            isSilentInstall: types.optional(types.boolean, false),
            isFixingInstall: types.optional(types.boolean, false),
            installStatus: InstallState.INIT,
            installDate: types.maybeNull(types.string),
            error: types.maybeNull(types.string),
            downloadSize: 0,
            installProgress: 0,
            unpackProgress: 0,
            totalDependenciesToInstall: 0,
            downloadStartedAt: types.maybeNull(types.Date),
            averageDownloadSpeed: types.maybeNull(types.number),
            dependencyQueue: types.optional(types.array(types.string), []),
            assetUrl: types.maybeNull(types.string),
            licenceCode: types.maybeNull(types.string),
            isLicenseRequired: types.optional(types.boolean, false),
        })
        .views((self: any) => {
            return {
                get root(): any {
                    return getRoot(self)
                },
                get platformPackage() {
                    switch (getPlatform()) {
                        case Platform.WIN:
                            return self.winVersion
                        case Platform.MAC:
                            return self.macVersion
                        case Platform.UNKNOWN:
                        default:
                            return null
                    }
                },
                get pluginSize() {
                    if (!self.platformPackage) return null

                    /**
                     * WILD HACK:
                     * set plugin size to 30MB for MeldaProduction plugins
                     */

                    if (self.appId.includes('meldaproduction')) {
                        return formatBytes(30 * 1024 * 1024)
                    }

                    // **************************

                    return formatBytes(self.platformPackage.size)
                },
                get isAvailableForUsersTier() {
                    if (!self.root.auth.user) return false

                    const { upgradeableTiersForState } = self.root.auth.user

                    // No upgrade path - on top tier
                    if (upgradeableTiersForState.length === 0) return true

                    const tierIds: string[] = self.tiers!.map(({ tierId }: ITier) => {
                        return tierId
                    })

                    return (
                        tierIds.filter((tierId: string) => {
                            return upgradeableTiersForState.includes(tierId) ?? true
                        }).length === 0
                    )
                },
                get progressState() {
                    if (!self.platformPackage)
                        return {
                            progress: 0,
                            label: '',
                        }

                    const handleInstalling = () => {
                        let label, progress

                        if (self.unpackProgress > 0) {
                            progress = self.unpackProgress
                            label = `Unpacking ${progress.toString().padStart(4, ' ')}%`
                        } else {
                            const byteString = `${formatBytes(self.downloadSize)}/${self.pluginSize}`
                            progress = Math.round((self.downloadSize / self.platformPackage.size) * 100)
                            label = `Downloading ${byteString.padStart(18, ' ')}`
                        }

                        return { progress, label, isAnimated: false }
                    }

                    const handleInstallingDependencies = () => {
                        let label, progress, isAnimated
                        const dependency = self.root.feed?.plugins.get(self.dependencyQueue[0])
                        if (!dependency) return { progress: 0, label: 'No dependecy found' }

                        switch (dependency.installStatus) {
                            case InstallState.INSTALLING_AS_DEPENDENCY:
                                isAnimated = false
                                if (dependency.unpackProgress > 0) {
                                    progress = dependency.unpackProgress
                                    label = `Unpacking Dependency ${self.dependencyQueue.length}/${self.totalDependenciesToInstall}`
                                } else {
                                    progress = Math.round((dependency.downloadSize / dependency.platformPackage.size) * 100)
                                    label = `Downloading Dependency ${self.dependencyQueue.length}/${self.totalDependenciesToInstall}`
                                }
                                break
                            case InstallState.FINALIZING_INSTALL:
                            case InstallState.FINALIZING_INSTALL_AS_DEPENDENCY:
                            default:
                                isAnimated = true
                                label = `Installing Dependency ${self.dependencyQueue.length}/${self.totalDependenciesToInstall}`
                                break
                        }

                        return { progress, label, isAnimated }
                    }

                    switch (self.installStatus) {
                        case InstallState.INSTALLING:
                        case InstallState.INSTALLING_AS_DEPENDENCY:
                            return handleInstalling()
                        case InstallState.FINALIZING_INSTALL:
                        case InstallState.FINALIZING_INSTALL_AS_DEPENDENCY:
                            return { progress: 0, label: 'Installing...', isAnimated: true }
                        case InstallState.REMOVING:
                            return { progress: 0, label: 'Removing...', isAnimated: true }
                        case InstallState.INSTALLING_DEPENDENCIES:
                        case InstallState.FIXING_DEPENDENCIES:
                        case InstallState.UPDATING_DEPENDENCIES:
                            return handleInstallingDependencies()
                        default:
                            return { progress: 0, label: '' }
                    }
                },
                get pluginVersion() {
                    if (!self.platformPackage) return ''
                    if (self.isInstalled) return `v ${self.installedVersion}`

                    return `v ${self.platformPackage.version}`
                },
                get installerUrl(): null | string {
                    if (!self.appId) return null

                    return `/api/v1/plugin/download?label=${encodeURIComponent(self.label.name)}&name=${encodeURIComponent(
                        self.name,
                    )}&appId=${encodeURIComponent(self.appId)}&version=${self.platformPackage?.version}&platform=${
                        getPlatform() === Platform.MAC ? 'mac' : 'win'
                    }`
                },
                get missingDependencies() {
                    if (!self.platformPackage) return []

                    return self.platformPackage.dependencies.filter((dep: any) => {
                        return (
                            !self.root.feed?.plugins.get(dep.appId)?.installedVersion ||
                            self.root.feed?.plugins.get(dep.appId)?.installedVersion === ''
                        )
                    })
                },
                get outdatedDependencies() {
                    if (!self.platformPackage) return []

                    return self.platformPackage.dependencies.filter((dep: any) => {
                        const depPlugin = self.root.feed?.plugins.get(dep.appId)

                        if (!depPlugin || !depPlugin.installedVersion) return false

                        if (!dep.version) {
                            // check if latest version is installed
                            return depPlugin.installedVersion !== depPlugin.platformPackage?.version
                        }

                        return depPlugin.installedVersion !== dep.version
                    })
                },
                get brokenDependencies() {
                    if (!self.platformPackage) return []

                    return self.platformPackage.dependencies.filter((dep: any) => {
                        const depPlugin = self.root.feed?.plugins.get(dep.appId)
                        return depPlugin?.isBroken || depPlugin?.isErrored
                    })
                },
                // TODO: Plugin options for the three dot menu need defining
                get pluginOptions() {
                    if (self.isErroredOnInstall) {
                        return [
                            {
                                title: 'Cancel',
                                onClick: () => {
                                    self.cancelInstallPlugin()
                                },
                            },
                        ]
                    }

                    if (self.isBroken || self.canBeUpdated || (self.isInstalled && !self.isAvailableForUsersTier)) {
                        return [
                            {
                                title: 'Uninstall',
                                onClick: () => {
                                    self.uninstallPlugin()
                                },
                            },
                        ]
                    }

                    if (self.isAvailable || self.isVerifyingInstall || (!self.isInstalled && !self.isAvailableForUsersTier)) {
                        return []
                    }

                    return [
                        {
                            title: 'Re-install',
                            onClick: () => {
                                self.installPlugin()
                            },
                        },
                    ]
                },
                // Plugins that are dependent on this plugin being installed
                get dependents() {
                    return self.root.feed?.pluginsList.filter((plugin: any) => {
                        if (!plugin.platformPackage?.dependencies || plugin.platformPackage?.dependencies.length === 0) return false

                        const deps = plugin.platformPackage?.dependencies.map((dep: any) => {
                            return dep.appId
                        })

                        if (!deps.includes(self.appId) || !plugin.isInstalled) return false

                        return true
                    })
                },
                // Plugins that this plugin requires to be installed
                get dependencies() {
                    if (!self.platformPackage?.dependencies || self.platformPackage?.dependencies.length === 0) return []

                    // Filter dependencies to ignore "silent" installs e.g. Font packages, and packages that are already installed
                    return self.platformPackage?.dependencies
                        .map(({ appId }: IFLPlugin) => {
                            const dependency = self.root.feed.plugins.get(appId)
                            if (!dependency) return null

                            return dependency
                        })
                        .filter((dependency: IFLPlugin | null) => {
                            if (!dependency) return false
                            return !dependency.isSilentInstall && !dependency.isInstalled
                        })
                },
                isFilteredByInstallStatus(selectedInstallStatus: InstallFilter): boolean {
                    const installedAppIds = Object.keys(self.root.feed.installedPlugins) ?? {}

                    return (
                        (selectedInstallStatus === InstallFilter.AVAILABLE && !installedAppIds.includes(self.appId)) ||
                        (selectedInstallStatus === InstallFilter.ACTIVE_QUEUE &&
                            [
                                InstallState.INSTALLING,
                                InstallState.INSTALLING_DEPENDENCIES,
                                InstallState.FINALIZING_INSTALL,
                                InstallState.REMOVING,
                                InstallState.FIXING_DEPENDENCIES,
                            ].includes(self.installStatus as InstallState)) ||
                        (selectedInstallStatus === InstallFilter.INSTALLED && installedAppIds.includes(self.appId)) ||
                        (selectedInstallStatus === InstallFilter.UPDATES && self.isInstalled && self.isAvailableForUsersTier && self.canBeUpdated)
                    )
                },
                isFilteredByCategory(selectedCategory: string): boolean {
                    return !!self.categories?.some(({ id }: IIdentifier) => {
                        return id === selectedCategory
                    })
                },
                isFilteredByTiers(tierIds: string[]): boolean {
                    return !!self.tiers?.some(({ id }: ITier) => {
                        return tierIds.includes(id)
                    })
                },
                isFilteredByTag(tagId: string): boolean {
                    return (
                        self.tagIds?.some((id: string) => {
                            return tagId === id
                        }) ?? false
                    )
                },
            }
        })
        .views((self: any) => {
            return {
                get isDownloading() {
                    return (
                        self.installStatus === InstallState.INSTALLING ||
                        self.installStatus === InstallState.INSTALLING_DEPENDENCIES ||
                        self.installStatus === InstallState.INSTALLING_AS_DEPENDENCY
                    )
                },
                get isInstalling() {
                    return (
                        self.installStatus === InstallState.INSTALLING ||
                        self.installStatus === InstallState.INSTALLING_DEPENDENCIES ||
                        self.installStatus === InstallState.INSTALLING_AS_DEPENDENCY ||
                        self.installStatus === InstallState.FINALIZING_INSTALL ||
                        self.installStatus === InstallState.FINALIZING_INSTALL_AS_DEPENDENCY ||
                        self.installStatus === InstallState.FIXING_DEPENDENCIES
                    )
                },
                get isInstalled() {
                    return self.installStatus === InstallState.INSTALLED || self.installStatus === InstallState.MISSING_DEPENDENCIES
                },
                get isAvailable() {
                    return self.installStatus === InstallState.AVAILABLE
                },
                get isErroredOnInstall() {
                    return self.installStatus === InstallState.ERRORED_ON_INSTALL
                },
                get isErroredOnUninstall() {
                    return self.installStatus === InstallState.ERRORED_ON_UNINSTALL
                },
                get isErrored() {
                    return self.isErroredOnInstall || self.isErroredOnUninstall
                },
                get isBroken() {
                    return self.installStatus === InstallState.BROKEN || self.installStatus === InstallState.MISSING_DEPENDENCIES
                },
                get isMissingDependencies() {
                    return self.installStatus === InstallState.MISSING_DEPENDENCIES
                },
                get isVerifyingInstall() {
                    return self.installStatus === InstallState.VERIFYING_INSTALL
                },
                get isRemoving() {
                    return self.installStatus === InstallState.REMOVING
                },
                get isActive() {
                    return (
                        self.installStatus === InstallState.INSTALLING ||
                        self.installStatus === InstallState.INSTALLING_DEPENDENCIES ||
                        self.installStatus === InstallState.INSTALLING_AS_DEPENDENCY ||
                        self.installStatus === InstallState.FINALIZING_INSTALL ||
                        self.installStatus === InstallState.FINALIZING_INSTALL_AS_DEPENDENCY ||
                        self.installStatus === InstallState.FIXING_DEPENDENCIES ||
                        self.installStatus === InstallState.UPDATING_DEPENDENCIES ||
                        self.installStatus === InstallState.REMOVING
                    )
                },
                get isVersionOutdated() {
                    if (!self.platformPackage || self.installStatus !== InstallState.INSTALLED || !self.installedVersion) return false

                    return self.installedVersion !== self.platformPackage.version
                },
                get areDependenciesOutdated() {
                    return self.outdatedDependencies.length > 0
                },
                get canBeUpdated() {
                    return self.isVersionOutdated || self.areDependenciesOutdated
                },
                get labelRequiresMarketingConsent() {
                    return self.label?.requiredConsents?.includes('marketing') ?? false
                },
                get requiresMarketingOptIn() {
                    const isFree = self.tiers?.some(({ tierId }: Instance<typeof Tier>) => {
                        return tierId === Tiers.FREE
                    })
                    const hasPreviouslyConsented = self.root.auth?.user?.consentedLabels.includes(self.label?.id) ?? false

                    // Plugin is in FREE Tier, on a label that requires consent and the user has not previously consented
                    return isFree && self.labelRequiresMarketingConsent && !hasPreviouslyConsented
                },
                get requiresDependenciesForInstall() {
                    return self.dependencies.length > 0
                },
                get showThreeDotMenu() {
                    return !self.isActive && self.platformPackage && self.pluginOptions?.length > 0
                },
                get categoryIds() {
                    return self.categories.map(({ id }: any) => {
                        return id
                    })
                },
                get isWithinNewReleaseWindow() {
                    const releaseDate = new Date(self.releaseDate)
                    const newReleaseLimitInMs = NEW_PLUGIN_LIMIT_DAYS * 24 * 60 * 60 * 1000
                    const currentDate = new Date()

                    return currentDate.getTime() >= releaseDate.getTime() && currentDate.getTime() <= releaseDate.getTime() + newReleaseLimitInMs
                },
            }
        })
        // Setters
        .actions((self: any) => {
            return {
                setStatus(status: InstallState) {
                    self.installStatus = status
                },
                setError(error: string | null) {
                    self.error = error
                },
                setInstalledVersion(version: string | null) {
                    self.installedVersion = version
                },
                setDownloadSize(bytes: number) {
                    self.downloadSize = bytes
                },
                setUnpackProgress(percent: number) {
                    // @TODO: perhaps better to not hide this here
                    if (percent === 100) {
                        self.installStatus =
                            self.installStatus === InstallState.INSTALLING
                                ? InstallState.FINALIZING_INSTALL
                                : InstallState.FINALIZING_INSTALL_AS_DEPENDENCY
                    }

                    self.unpackProgress = percent
                },
                setInstallProgress(percent: number) {
                    self.installProgress = percent
                },
                setInstallDate(date: Date | null) {
                    self.installDate = date
                },
                setTotalDependenciesToInstall(count: number) {
                    self.totalDependenciesToInstall = count
                },
                setDependencyQueue(queue: string[]) {
                    self.dependencyQueue = queue
                },
                setDownloadStartedAt(date: Date | null) {
                    self.downloadStartedAt = date
                },
                setIsFixingInstall(isFixing: boolean) {
                    self.isFixingInstall = isFixing
                },
                setAverageDownloadSpeed(speed: number | null) {
                    self.averageDownloadSpeed = speed
                },
                setLicenceCode(key: string | null) {
                    self.licenceCode = key
                },
            }
        })
        .actions((self: any) => {
            return {
                afterAttach() {
                    if (self.root && self.root.feed?.isPluginInstalled(self.appId)) {
                        self.setStatus(InstallState.INSTALLED)
                    }

                    const localInstallDate = localStorage.getItem(`${LS_PLUGIN_INSTALL_DATE}_${self.appId}`)
                    if (localInstallDate) {
                        self.setInstallDate(localInstallDate)
                    }
                },
                checkInstallStatus: flow(function* () {
                    if (self.isInstalling || self.isLicenseRequired) return

                    self.setStatus(InstallState.VERIFYING_INSTALL)

                    // Don't verify the install if it's already in the queue
                    if (self.root.feed?.verifyingPluginsQueue.includes(self.appId)) return

                    self.root.feed.enqueueVerifyingPlugin(self.appId)

                    // Fetch the install state from the engine
                    // the callback activated in "engine.ts" will call the checkDependencies method
                    yield verifyInstall(self.appId)
                }),
                // eslint-disable-next-line require-yield
                checkDependencies: flow(function* () {
                    if (self.missingDependencies.length > 0 || self.brokenDependencies.length > 0) {
                        self.setStatus(InstallState.MISSING_DEPENDENCIES)
                        self.setError('Missing dependencies')
                    }
                }),
                uninstallPlugin: flow(function* () {
                    if (!self.platformPackage) return
                    self.setStatus(InstallState.REMOVING)

                    if (ENV.VITE_GOOGLE_TAG_MANAGER && self.root.gtmInitialized) {
                        const gtmPluginUninstallArgs = formatGTMEvent(GTMEvent.PLUGIN_UNINSTALL, {
                            pluginAppId: self.appId,
                            pluginName: self.name,
                            pluginVersion: self.platformPackage?.version,
                            pluginSize: self.platformPackage?.size,
                            platform: getPlatform(),
                        } as Partial<IPluginPackageEvent>)

                        TagManager.dataLayer({ dataLayer: gtmPluginUninstallArgs.dataLayer })
                    }

                    yield uninstall(self.appId)
                }),
                installPlugin: flow(function* (installMode: InstallMode = InstallMode.FULL_INSTALL, isRetry = false) {
                    if (!self.platformPackage) return

                    self.setError(null)
                    self.resetProgress()

                    if (ENV.VITE_GOOGLE_TAG_MANAGER && self.root.gtmInitialized) {
                        const gtmEventData = {
                            pluginAppId: self.appId,
                            pluginName: self.name,
                            pluginVersion: self.platformPackage?.version,
                            pluginSize: self.platformPackage?.size,
                            platform: getPlatform(),
                        } as Partial<IPluginPackageEvent>
                        const gtmEventType = isRetry ? GTMEvent.PLUGIN_INSTALL_RETRIED : GTMEvent.PLUGIN_INSTALL_INITIALIZED
                        const gtmPluginInstallArgs = formatGTMEvent(gtmEventType, gtmEventData)

                        TagManager.dataLayer({ dataLayer: gtmPluginInstallArgs.dataLayer })
                    }

                    // get and install dependencies
                    const dependencyAppIds: string[] = [...self.outdatedDependencies, ...self.missingDependencies, ...self.brokenDependencies].map(
                        dep => {
                            return dep.appId
                        },
                    )

                    self.setDependencyQueue(dependencyAppIds)
                    self.setTotalDependenciesToInstall(dependencyAppIds.length)

                    // install first dependency and let callbacks do the rest
                    if (self.dependencyQueue.length > 0) {
                        switch (installMode) {
                            case InstallMode.UPDATE_DEPENDENCIES:
                                self.setStatus(InstallState.UPDATING_DEPENDENCIES)
                                break
                            case InstallMode.UPDATE:
                            case InstallMode.FULL_INSTALL:
                                self.setStatus(InstallState.INSTALLING_DEPENDENCIES)
                                break
                            case InstallMode.ONLY_MISSING_DEPENDENCIES:
                            default:
                                self.setStatus(InstallState.FIXING_DEPENDENCIES)
                                break
                        }
                        self.installNextDependency()
                    } else if (installMode === InstallMode.UPDATE || installMode === InstallMode.FULL_INSTALL) {
                        // no more dependencies to install, install the plugin
                        self.setStatus(InstallState.INSTALLING)
                        yield install(self.appId, self.installerUrl, !!self.assetUrl)
                        self.setDownloadStartedAt(new Date())
                    }
                }),
                cancelInstallPlugin: flow(function* () {
                    yield cancelInstall(self.appId)

                    self.setError(null)
                    self.setDownloadSize(0)
                    self.setIsFixingInstall(false)

                    if (ENV.VITE_GOOGLE_TAG_MANAGER && self.root.gtmInitialized) {
                        const gtmPluginInstallCanceledArgs = formatGTMEvent(GTMEvent.PLUGIN_INSTALL_CANCELED, {
                            pluginAppId: self.appId,
                            pluginName: self.name,
                            pluginVersion: self.platformPackage?.version,
                            pluginSize: self.platformPackage?.size,
                            platform: getPlatform(),
                        } as Partial<IPluginPackageEvent>)

                        TagManager.dataLayer({ dataLayer: gtmPluginInstallCanceledArgs.dataLayer })
                    }

                    if (self.root.feed?.isPluginInstalled(self.appId)) {
                        self.checkInstallStatus()
                    } else {
                        self.setStatus(InstallState.AVAILABLE)
                    }
                }),
                installedSuccessfullyCallback: flow(function* () {
                    if (
                        (self.installStatus === InstallState.FIXING_DEPENDENCIES || self.installStatus === InstallState.UPDATING_DEPENDENCIES) &&
                        self.dependencyQueue.length === 0
                    ) {
                        // we are only handling the dependencies, no need to install the plugin
                        self.setStatus(InstallState.INSTALLED)
                        return
                    }

                    if (self.dependencyQueue.length === 0 && self.installStatus === InstallState.INSTALLING_DEPENDENCIES) {
                        // no more dependencies to install, install plugin
                        self.setStatus(InstallState.INSTALLING)
                        yield install(self.appId, self.installerUrl, !!self.assetUrl)
                    } else if (
                        (self.installStatus === InstallState.INSTALLING_DEPENDENCIES || self.installStatus === InstallState.FIXING_DEPENDENCIES) &&
                        self.dependencyQueue.length > 0
                    ) {
                        self.installNextDependency()
                    }
                }),
                installNextDependency: flow(function* () {
                    if (self.dependencyQueue.length === 0) {
                        self.setStatus(InstallState.INSTALLING)
                        yield install(self.appId, self.installerUrl, !!self.assetUrl)
                    } else {
                        const dependencyId = self.dependencyQueue[0]
                        const dependency = self.root.feed?.plugins.get(dependencyId)

                        if (!dependency) {
                            self.setError(`Dependency ${dependencyId} not found`)
                            self.setStatus(InstallState.ERRORED_ON_INSTALL)
                            return
                        }

                        dependency.resetProgress()
                        dependency.setStatus(InstallState.INSTALLING_AS_DEPENDENCY)
                        yield install(dependency.appId, dependency.installerUrl, !!dependency.assetUrl)
                    }
                }),
                resetProgress: () => {
                    self.setDownloadSize(0)
                    self.setInstallProgress(0)
                    self.setUnpackProgress(0)
                },
                fixInstall: () => {
                    if (self.installStatus !== InstallState.BROKEN) return

                    // Set flag that this is an attempt to fix a broken install
                    self.setIsFixingInstall(true)

                    // Verify the plugin's install and check dependencies
                    self.checkInstallStatus()
                },
                completeInstall: () => {
                    const installDate = `${new Date()}`
                    self.setStatus(InstallState.INSTALLED)
                    self.setInstallDate(installDate)

                    // Set the install date and time
                    localStorage.setItem(`${LS_PLUGIN_INSTALL_DATE}_${self.appId}`, installDate)
                },
                completeUninstall: () => {
                    self.setStatus(InstallState.AVAILABLE)
                    self.setInstallDate(null)
                    self.setDownloadSize(0)
                    self.setInstalledVersion(null)

                    // Remove the install date
                    localStorage.removeItem(`${LS_PLUGIN_INSTALL_DATE}_${self.appId}`)
                },
                fetchLicenceCode: async () => {
                    // We sent both the plugin id and the label id, and the endpoint works out which lience code should be returned
                    const res = await ApiInstance.content.getLicenceCodeForPlugin(self.id, self.label.id)

                    if (res.success) {
                        self.setLicenceCode(res.data.key)
                        return res.data.key
                    } else {
                        console.error(`Unable to fetch licence code for ${self.appId}: `, res)
                    }
                },
                consentAndInstall: async () => {
                    const consentRequest = await ApiInstance.session.setLabelConsents(self.label.id, true)

                    if (consentRequest.success) {
                        self.root.auth?.user?.fetchLabelConsents()

                        if (self.requiresDependenciesForInstall) {
                            self.root.modal.showModal(ModalType.REQUIRES_DEPENDENCIES_INSTALL, { pluginAppId: self.appId })
                            return
                        }

                        self.installPlugin()
                    } else {
                        console.error(`Unable to opt in to ${self.label.name}'s marketing terms. Error: `, consentRequest)
                    }
                },
            }
        }),
)
