import { IconWrapper, IDerivedIconProps } from '@components/icons/IconWrapper'
import { FC } from 'react'

const FLCloudFruitLogo: FC<IDerivedIconProps> = (props: IDerivedIconProps) => {
    return (
        <IconWrapper {...props} viewBox="0 0 35 55">
            <path
                fill="#FFFFFF"
                d="M31.3,27.2c-3.1-3.9-4.6-5.4-6-6.8l-3.2-7.9c3.4-1.3,8.3,0,10.9,3.6c2.6,3.7,1.9,7.9,1.3,9.6
C33.9,27,32.7,29,31.3,27.2z"
            />
            <path fill="#FFFFFF" d="M11.9,14c1.1-4.9,5.8-3.7,8.2-2.4l2.6,1h0.6l2.2,7.8c-0.4,1.2-4.6,7.6-9.7,5.9C11.6,24.9,10.8,19,11.9,14z" />
            <path fill="#FFFFFF" d="M2.6,15.8c3.9-0.9,7.5-1.4,9.5-1.6l7.6-2.8C17.4,5.2,6.5,2.9,1.5,12.2C0.7,13.8-0.5,16.5,2.6,15.8z" />
            <path fill="#FFFFFF" d="M29.1,1.1c-5,4.1-7,9.2-7.4,11.2l-1.7-0.7c1.4-3.8,5.5-9.7,8.9-11.6C29.4-0.2,30,0.4,29.1,1.1z" />
            <path
                fill="#FFFFFF"
                d="M3.7,17.1C-2.1,29.3-1,52,6.3,54.5c5.8,2.3,20.1-12.9,23.5-26.9c-1.8-2.3-3-3.6-4-4.7l0,0
c-0.6,0.8-1.4,1.8-2.3,2.6c-1.9,1.7-4.8,3.2-8,2.1c-2.7-0.9-4.2-3.3-4.9-5.8c-0.5-1.8-0.6-3.9-0.3-5.9C8.4,16.2,6.2,16.6,3.7,17.1z"
            />
        </IconWrapper>
    )
}

export default FLCloudFruitLogo
