import { ReactElement } from 'react'

export type IIconProps = {
    style?: Record<string, string>
    className?: string
    viewBox: string
    children: ReactElement | ReactElement[]
}

export type IDerivedIconProps = Pick<IIconProps, 'className' | 'style'>

export const IconWrapper = ({ className = '', style = {}, viewBox, children }: IIconProps) => (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox={viewBox} className={className} style={style}>
        {children}
    </svg>
)
