import { getRoot, types } from 'mobx-state-tree'
import { ITier, Tiers } from '@store/models/Tier'
import ApiInstance from '@utils/api'

export enum UserTier {
    PLUS = 0,
    PRO = 1,
}

export enum SubscriptionState {
    FREE = 0,
    TRIAL = 1,
    SUBSCRIBER = 2,
}

export const User = types
    .model('User', {
        id: types.number,
        name: types.string,
        // Subscription state
        state: types.optional(types.number, SubscriptionState.FREE),
        // User's tier
        tierType: types.maybeNull(types.number),
        consentedLabels: types.optional(types.array(types.string), []),
    })
    .actions((self: any) => {
        return {
            afterCreate: () => {
                self.fetchLabelConsents()
            },
            setLabelConsents(consents: string[]) {
                self.consentedLabels = consents
            },
            setTier(tier: UserTier) {
                self.tierType = tier
            },
            setState(state: SubscriptionState) {
                self.state = state
            },
            fetchLabelConsents: async () => {
                const labelConsents = await ApiInstance.session.getLabelConsents()

                if (!labelConsents.success) {
                    console.warn('Error fetching label consents for the user', labelConsents.statusText)
                    return
                }
                self.setLabelConsents(labelConsents.data)
            },
        }
    })
    .views((self: any) => {
        return {
            get root(): any {
                return getRoot(self)
            },

            // These getters serve as a mapping between how we discern a user tier and subscription state
            // and should be used throughout the app where we have user tier/subscription specific logic
            get isPro() {
                return self.tierType === UserTier.PRO && self.state !== SubscriptionState.FREE
            },
            get isPlus() {
                return self.tierType === UserTier.PLUS && self.state !== SubscriptionState.FREE
            },
            get isFree() {
                return self.state === SubscriptionState.FREE
            },
            get isTrial() {
                return self.state === SubscriptionState.TRIAL && (self.isPro || self.isPlus)
            },
            get isSubscriber() {
                return self.state === SubscriptionState.SUBSCRIBER && (self.isPro || self.isPlus)
            },
        }
    })
    .views((self: any) => {
        return {
            get visibleTiersForState() {
                // Filter the tiers to be shown, based on the user's state
                return self.root.feed.sortedTiers.filter(({ isPlus, isPro }: ITier) => {
                    if (self.isPro) return isPro
                    if (self.isPlus) return isPlus || isPro

                    return true
                })
            },
            get upgradeableTiersForState() {
                if (self.isFree) {
                    return [Tiers.PLUS, Tiers.PRO]
                }

                if (self.isPlus) {
                    return [Tiers.PRO]
                }

                return []
            },
        }
    })
