import { types } from 'mobx-state-tree'
import { getMachineId, getMachineName, updateJwsToken } from '@src/interop'
import { observable } from 'mobx'
import ApiInstance from '@utils/api'

export enum LicenseState {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    EXPIRED = 'expired',
    PENDING = 'pending',
    FAILED = 'failed',
}

const LicenseStore = types
    .model('License', {
        licenses: types.array(types.frozen({})),
        machineId: types.optional(types.string, ''),
        machineName: types.optional(types.string, ''),
        state: types.optional(types.enumeration(Object.values(LicenseState)), LicenseState.PENDING),
    })
    .views(self => {
        return {
            get activeLicense(): any {
                return self.licenses.find((license: any) => {
                    return license.isActive
                })
            },
            get isPending(): boolean {
                return self.state === LicenseState.PENDING
            },
            get failed(): boolean {
                return self.state === LicenseState.FAILED
            },
        }
    })
    .actions(self => {
        return {
            setLicenses(licenses: any) {
                self.licenses = licenses.map((license: any) => {
                    return {
                        ...license,
                        isActive: license.machineId === self.machineId,
                    }
                })
            },
            setMachineId(machineId: string) {
                self.machineId = machineId
            },
            setMachineName(machineName: string) {
                self.machineName = machineName
            },
            setState(state: LicenseState) {
                self.state = state
            },
            async getMachineId() {
                // get machineId and machineName
                this.setMachineId(await getMachineId())
                this.setMachineName(await getMachineName())
            },
            async updateLicenses() {
                if (!self.machineId) {
                    await this.getMachineId()
                }

                try {
                    // get licenses from credit-server
                    const res = await ApiInstance.licencing.getLicenses()

                    if (res.success) {
                        // update licenses
                        this.setLicenses(res.data)
                    } else {
                        this.setState(LicenseState.FAILED)
                        console.error('Unable to update licenses. Error: ', res)
                    }
                } catch (error) {
                    this.setState(LicenseState.FAILED)
                    console.error('Unable to update licenses. Error: ', error)
                }

                this.setState(self.activeLicense && new Date(self.activeLicense.expiredAt) < new Date() ? LicenseState.EXPIRED : LicenseState.ACTIVE)
            },
            async removeLicense(licenseId: number) {
                if (licenseId === undefined) return

                const license = self.licenses.find((license: any) => {
                    return license.id === licenseId
                })

                if (license) {
                    const res = await ApiInstance.licencing.removeLicense(licenseId)

                    if (res.success) {
                        this.updateLicenses()
                    } else {
                        console.error('Unable to remove license. Error: ', res)
                    }
                }
            },
            async updateJWT(): Promise<boolean> {
                if (!self.machineId) {
                    console.warn('Machine ID not set')

                    return false
                }

                const res = await ApiInstance.session.getJWT(self.machineId)
                const token = res?.data?.token

                if (token) {
                    updateJwsToken(token)
                    return true
                }

                return false
            },
        }
    })

export default observable(LicenseStore)
