import { getRoot, Instance, types } from 'mobx-state-tree'
import { IFLPlugin } from '@store/models/FLPlugin'

// Map of tiers to their tierId
export enum Tiers {
    FREE = -1,
    PLUS = 0,
    PRO = 1,
}

export type ITierData = {
    id: string
    name: string
    tierId: number
}

export type ITier = Instance<typeof Tier>

export const Tier = types
    .model('Tier', {
        id: types.string,
        name: types.string,
        tierId: types.number,
    })
    .views((self: any) => {
        return {
            get root() {
                return getRoot(self)
            },
            get hasPlugins() {
                return (
                    self.root.feed.pluginsList.filter((plugin: IFLPlugin) => {
                        return !!plugin.tiers.find(({ id }) => {
                            return id === self.id
                        })
                    }).length > 0
                )
            },
            get isPro() {
                return self.tierId === Tiers.PRO
            },
            get isPlus() {
                return self.tierId === Tiers.PLUS
            },
            get isFree() {
                return self.tierId === Tiers.FREE
            },
        }
    })
