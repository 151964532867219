import { ProgressBar } from '@components/progress-bar/ProgressBar'
import { InstallState } from '@store/models/FLPlugin'
import { observer } from 'mobx-react'

type IPluginProgressBarProps = {
    plugin: any
}

const InstallStatesWithProgress = [
    InstallState.INSTALLING,
    InstallState.INSTALLING_AS_DEPENDENCY,
    InstallState.INSTALLING_DEPENDENCIES,
    InstallState.FIXING_DEPENDENCIES,
    InstallState.UPDATING_DEPENDENCIES,
    InstallState.FINALIZING_INSTALL,
    InstallState.FINALIZING_INSTALL_AS_DEPENDENCY,
    InstallState.REMOVING,
]

export const PluginProgressBar = observer(({ plugin }: IPluginProgressBarProps) => {
    const { progressState, installStatus } = plugin
    const { progress, label, isAnimated } = progressState

    if (!InstallStatesWithProgress.includes(installStatus)) {
        return null
    }

    if (isAnimated) {
        return <ProgressBar label={label} progressAmount={progress} className="col-span-2 mr-30px" isAnimated />
    }

    return <ProgressBar label={label} progressAmount={progress} progressOffset={0} className="col-span-2 mr-30px" isDownload />
})
