// TODO: This is duplicated between the wbesite, fl sounds and fl plugins - it should be moved to a shared repo

const msIn48Hours = 1000 * 60 * 60 * 48

export class DeviceIdCreator {
    baseUrl = 'https://image-line.com/cdn-cgi/tracei'
    cookieName = 'il-device-id'

    getCookie = () => {
        const ca = document.cookie.split(';')

        for (let i = 0; i < ca.length; i++) {
            const pair = ca[i].trim().split('=')
            const key = pair[0]
            let value = pair[1]

            if (key === this.cookieName) {
                if (value.includes('%')) {
                    // Check if value contains the '%' character (which means it is encoded)
                    try {
                        value = decodeURIComponent(value)
                    } catch (e) {
                        console.error('Error decoding cookie:', key, value)
                        // Use the raw value if decoding fails
                    }
                }

                return value // Return the value directly
            }
        }

        return ''
    }

    setCookie = (value: string) => {
        var d = new Date()
        d.setTime(d.getTime() + msIn48Hours) // Cookie expires after 48 hours
        var expires = `expires=${d.toUTCString()}`
        document.cookie = `${this.cookieName}=${encodeURIComponent(value)};${expires};path=/`
    }

    fetchIpAndTimestamp = () => {
        if (!window.fetch) {
            // eslint-disable-next-line no-console
            console.log('Fetch is not supported in this browser.')
            return Promise.resolve(null)
        }
        return fetch(this.baseUrl)
            .then(response => {
                return response.text()
            })
            .then(data => {
                var lines = data.split('\n')
                var ip = ''
                var timestamp = ''

                lines.forEach(line => {
                    if (line.startsWith('ip=')) {
                        ip = line.split('=')[1]
                    } else if (line.startsWith('ts=')) {
                        timestamp = line.split('=')[1]
                    }
                })

                return { ip, timestamp }
            })
            .catch(error => {
                console.error('Error fetching IP and timestamp:', error)
                return null
            })
    }

    roundTimestamp = (timestamp: number) => {
        return Math.round(timestamp / msIn48Hours) * msIn48Hours
    }

    createDeviceId = () => {
        const self = this
        const cookie = self.getCookie()

        if (cookie !== '') {
            return Promise.resolve(cookie)
        }

        return self.fetchIpAndTimestamp().then(function (data: any) {
            if (data === null) return null

            const roundedTimestamp = self.roundTimestamp(data.timestamp)
            const deviceId = `${data.ip}-${roundedTimestamp}`
            self.setCookie(deviceId)
            return deviceId
        })
    }
}
