import { ClosePanelIcon } from '@components/icons/ClosePanelIcon'
import { useAppStoreContext } from '@store/RootStore'
import { COLORS } from '@styles/Colors'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'

import { IFLPlugin } from '@store/models/FLPlugin'
import PluginActionButton from '@components/PluginActionButton'
import { ChevronIcon } from '@components/icons/ChevronIcon'

import ImgWithFallback from '@components/general/img-with-fallback'

import useKeys, { Keys } from '@src/hooks/useKeys'
import ThreeDotMenu from '@components/ThreeDotMenu'

export const PluginSidePanel = () => {
    const { feed: feedStore, scale } = useAppStoreContext()
    const { selectedPlugin, setSelectedPlugin } = feedStore
    const [isButtonHovered, setIsButtonHovered] = useState(false)
    const [sideBarElement, setSideBarElement] = useState<null | HTMLDivElement>(null)
    const [buttonElement, setButtonElement] = useState<null | HTMLDivElement>(null)

    const sideBarRef = useCallback((node: null | HTMLDivElement) => {
        if (node === null) {
            setSideBarElement(null)
            return
        }

        setSideBarElement(node)
    }, [])

    useEffect(() => {
        if (!sideBarElement) return

        const handler = (event: any) => {
            if (!sideBarElement!.contains(event.target)) {
                setSelectedPlugin(null)
            }
        }

        document.addEventListener('mousedown', handler)

        return () => {
            document.removeEventListener('mousedown', handler)
        }
    }, [sideBarElement])

    const buttonRef = useCallback((node: null | HTMLDivElement) => {
        if (node === null) {
            setButtonElement(null)
            return
        }

        setButtonElement(node)
    }, [])

    useEffect(() => {
        if (!buttonElement) return

        const mouseEnter = () => {
            setIsButtonHovered(true)
        }

        const mouseLeave = () => {
            setIsButtonHovered(false)
        }

        buttonElement.addEventListener('mouseenter', mouseEnter)
        buttonElement.addEventListener('mouseleave', mouseLeave)

        return () => {
            buttonElement.removeEventListener('mouseenter', mouseEnter)
            buttonElement.removeEventListener('mouseleave', mouseLeave)
        }
    }, [buttonElement])

    const closePanel = useCallback(() => {
        setSelectedPlugin(null)
    }, [])

    const plugin = useMemo(() => {
        if (!selectedPlugin) return

        return feedStore.plugins.get(selectedPlugin)
    }, [selectedPlugin, feedStore.plugins]) as IFLPlugin

    const previousPlugin = useMemo(() => {
        if (!plugin) return null
        return feedStore.previousPluginInFeed(plugin?.id)
    }, [plugin])

    const nextPlugin = useMemo(() => {
        if (!plugin) return null
        return feedStore.nextPluginInFeed(plugin?.id)
    }, [plugin])

    const onPaginationClick = useCallback((appId: string) => {
        return () => {
            setSelectedPlugin(appId)
        }
    }, [])

    useKeys(Keys.ESC, sideBarElement, !!selectedPlugin, closePanel)

    return (
        <>
            <div ref={sideBarRef} className={`flp-panel group ${selectedPlugin ? 'right-0 duration-300' : '-right-370px duration-500'}`}>
                {plugin && (
                    <>
                        <div id="icon-close-panel" className="flp-close-button--wrapper right-6px top-6px" onClick={closePanel}>
                            <ClosePanelIcon
                                className="flp-close-button"
                                style={{
                                    '--icon-fill-color': COLORS['flp-text'],
                                    '--icon-fill-hover-color': COLORS['flp-white'] as string,
                                    width: `${19 * scale}px`,
                                    height: `${19 * scale}px`,
                                }}
                            />
                        </div>
                        <div className="relative z-0 overflow-hidden h-180px cursor-pointer">
                            <ImgWithFallback
                                src={plugin?.imageUrl}
                                height={312}
                                width={620}
                                quality={100}
                                className="flp-absolute-centered w-full"
                                alt={plugin?.name ?? 'Plugin'}
                            />
                        </div>

                        <div className="flex flex-col justify-between h-[calc(100%-11.25rem)] p-5">
                            <div data-testid="sidebar-headline" className="flex justify-between mb-8 relative group/headline">
                                <div className="pt-0.5">
                                    <h3 id="plugin-name" className="plugin-name capitalize text-13px font-['Gilroy-Medium']">
                                        {plugin?.name}
                                    </h3>
                                    <p id="plugin-author" className="plugin-author text-11px tracking-wider">
                                        {plugin?.label.name}
                                    </p>
                                </div>
                                <div ref={buttonRef} className="w-132px">
                                    <PluginActionButton plugin={plugin} isHovered={isButtonHovered} uninstallCallback={closePanel} />
                                </div>
                                {plugin?.showThreeDotMenu && (
                                    <div
                                        data-testid="sidebar-three-dot-menu"
                                        className="absolute opacity-0 group-hover/headline:opacity-100 duration-200 right-0 top-1">
                                        <ThreeDotMenu options={plugin?.pluginOptions as any} />
                                    </div>
                                )}
                            </div>

                            <OverlayScrollbarsComponent className="grow w-full mb-15px pr-12px" defer>
                                <div className="text-11px tracking-wider">
                                    <div className="mb-7" dangerouslySetInnerHTML={{ __html: plugin?.descriptionLong }} />
                                    {/* TODO: tier information */}
                                    {plugin?.isAvailableForUsersTier ? (
                                        <p className="mb-4">{plugin?.name} is included in your FL Cloud subscription plan.</p>
                                    ) : null}
                                </div>
                            </OverlayScrollbarsComponent>

                            <footer className="flex justify-between items-center text-11px tracking-wider">
                                <div>
                                    {previousPlugin && (
                                        <div
                                            id="pagination-previous-plugin"
                                            className="flex items-center gap-1.5 cursor-pointer group"
                                            onClick={onPaginationClick(previousPlugin.appId)}>
                                            <ChevronIcon
                                                className="flp-icon cursor-pointer opacity-50 group-hover:opacity-100 duration-200"
                                                style={{
                                                    '--icon-fill-color': COLORS['flp-text'],
                                                    '--icon-fill-hover-color': COLORS['flp-text'] as string,
                                                    width: `${18 * scale}px`,
                                                    height: `${18 * scale}px`,
                                                }}
                                            />
                                            <p className="text-white opacity-0 group-hover:opacity-100 duration-200">Previous</p>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    {nextPlugin && (
                                        <div
                                            id="pagination-next-plugin"
                                            className="flex items-center gap-1.5 cursor-pointer group"
                                            onClick={onPaginationClick(nextPlugin.appId)}>
                                            <p className="text-white opacity-0 group-hover:opacity-100 duration-200">Next</p>
                                            <ChevronIcon
                                                className="flp-icon rotate-180 cursor-pointer opacity-50 group-hover:opacity-100 duration-200"
                                                style={{
                                                    '--icon-fill-color': COLORS['flp-text'],
                                                    '--icon-fill-hover-color': COLORS['flp-text'] as string,
                                                    width: `${18 * scale}px`,
                                                    height: `${18 * scale}px`,
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </footer>
                        </div>
                    </>
                )}
            </div>
            <div
                id="sidebar-panel-overlay"
                className={`flp-panel-overlay duration-200 ${selectedPlugin ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}
            />
        </>
    )
}

export default observer(PluginSidePanel)
